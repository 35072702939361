import React, { Component, Fragment } from 'react';
import './AlcoholDispenseHistory.scss';
import { FormGroup, Label, Input, Form, InputGroup, FormControl, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as routes from '../../../../utils/RouteConstant';
import backArrow from '../../../../images/back-arrow.svg';
import { Translate } from 'react-localize-redux';
import * as webConst from '../../../../utils/WebConstant';
import { getAlcoholDispenseHistoryRequest } from '../../../../containers/Medicines/Alcohol/AlcoholPlanContainer/action';
import { ALCOHOL_DISPENSE_HISTORY_SUCCESS } from '../../../../containers/Medicines/Alcohol/AlcoholPlanContainer/Constants';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import daLocale from "date-fns/locale/da";
import moment from "moment";
import ReactDatatable from '@mkikets/react-datatable';


class AlcoholDispenseHistory extends Component {
	constructor(props) {
		super(props);
		this.state = {
			clientId: "",
			clientName: "",
			clientCPR: "",
			clientPhoto: "",
			isLoading: false,
			validated: false,
			dispenseForm: "",
			medicineId: "",
			dispenseDetail: {},
			totalStrength: 0,
			dosageAmount: 0,
			isFormLoading: false,
			isAlcoholLoading: false,
			activeAlcoholTotalRecord: 0,
			activeAlcoholRecords: [],
			activeAlcoholColumns: this.activeAlcoholDatatable()
		};
	}

	componentDidMount() {
		let _this = this;
		let client = webConst.getClient();
		let clientId = client && typeof client !== 'undefined' && typeof client.client_id !== 'undefined' ? client.client_id : 0;
		if (clientId > 0) {
			let clientName = client && typeof client !== 'undefined' && typeof client.client_name !== 'undefined' ? client.client_name : 0;
			let cpr = client && typeof client !== 'undefined' && typeof client.client_socialid !== 'undefined' ? client.client_socialid : 0;
			let photo = client && typeof client !== 'undefined' && typeof client.photo !== 'undefined' ? client.photo : 0;
			this.setState({ clientId: clientId, clientName: clientName, clientCPR: cpr, clientPhoto: photo, isAlcoholLoading: true });
		}
		else {
			webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.select-client-message" />);
			this.props.history.push(routes.HOME_ROUTE);
		}
	}

	componentDidUpdate(prevProps) {
		const { alcoholSuccess, alcoholStatus, alcoholType, alcoholActiveTab } = this.props;

		if (prevProps.alcoholActiveTab !== "" && prevProps.alcoholActiveTab !== "alcohol-dispense-history" && alcoholActiveTab === "alcohol-dispense-history") {
			let params = new URLSearchParams();
			params.append('clinic_id', webConst.getClinicId());
			params.append('client_id', webConst.getClientId());
			params.append('user_id', webConst.getUserId());
			this.setState({ isAlcoholLoading: true });
			this.getActiveAlcoholData(params);
		}

		if (alcoholStatus === 200) {
			if (alcoholType === ALCOHOL_DISPENSE_HISTORY_SUCCESS) {
				if (prevProps.alcoholSuccess !== alcoholSuccess) {
					this.setState({
						isAlcoholLoading: false
					});
					if (alcoholSuccess.records && alcoholSuccess.records.length) {
						this.setState({ activeAlcoholRecords: alcoholSuccess.records, activeAlcoholTotalRecord: alcoholSuccess.total_records });
					}
				}
			}
		}

		if (alcoholStatus === 400) {
			if (this.state.isAlcoholLoading) {
				this.setState({
					isAlcoholLoading: false,
					isFormLoading: false
				});
			}

			if (this.state.activeAlcoholRecords.length > 0) {
				this.setState({ activeAlcoholRecords: [], activeAlcoholTotalRecord: [] });
			}
		}
	}

	getActiveAlcoholData = (params) => {
		this.props.getAlcoholDispenseHistoryRequest(params);
	}
	activeAlcoholDatatable = function () {
		let columns = [
			{
				key: "dispense_datetime",
				text: "Dispense Date",
				align: "left",
				className: "validi-date-text",
				sortable: true,
				cell: record => {
					return (
						<Fragment key={"dispense_date-" + record.id}>
							{moment(record.dispense_datetime).format("DD-MM-YYYY HH:mm:ss")}
						</Fragment>
					);
				}
			},
			{
				key: "start_date",
				text: "Start Date",
				align: "left",
				className: "validi-date-text",
				sortable: true,
				cell: record => {
					return (
						<Fragment key={"dispense_start_date-" + record.id}>
							{moment(record.start_date).format("DD-MM-YYYY")}
						</Fragment>
					);
				}
			},
			{
				key: "medicine_name",
				text: "Medicine name",
				align: "left",
				className: "validi-date-text",
				sortable: true,
				cell: record => {
					return (
						<Fragment key={"dispense_medicine_name-" + record.id}>
							{record.medicine_name}
						</Fragment>
					);
				}
			},
			{
				key: "total_disp_amount",
				text: "Dispense dosage",
				align: "left",
				sortable: true,
				className: 'validi-mg-text',
				cell: record => {
					return (
						<Fragment key={"total_disp_amount-" + record.id}>
							{record.total_disp_amount} {webConst.ucfirst(record.unit.replace(/[^a-zA-Z\/]+/g, ''))}
						</Fragment>
					);
				}
			},
			{
				key: "dispense_by",
				key_column: "dispense_by",
				text: "Dispense by",
				align: "left",
				className: 'text-capitalize',
				sortable: true
			},
			{
				key: "remark",
				key_column: "remark",
				text: "Note",
				align: "left",
				className: 'validi-date-text',
				sortable: true
			}
		];
		return columns;
	}

	tableChangeHandler = (data) => {
		const params = new URLSearchParams();
		params.append('clinic_id', webConst.getClinicId());
		params.append('client_id', webConst.getClientId());
		params.append('user_id', webConst.getUserId());
		Object.keys(data).map((key) => {
			if (key === "sort_order") {
				if (data[key]) {
					params.append("sort_order", data[key].order !== "false" ? data[key].order : "");
					params.append("sort_column", data[key].column);
				}
			} else {
				params.append(key, data[key]);
			}
		});
		this.getActiveAlcoholData(params);
	}

	render() {
		return (
			<div className="">
				<div className="validi-tabbar-inner-header">
					<div className="">
						<h4 className="validi-subtitle"><Translate id="medicine.alcohol-dispense-histories" /></h4>
					</div>
					<div className="validi-table-filter-box">

					</div>
				</div>
				<div className="validi-table-data-wrapper">
					<ReactDatatable
						key="alcohol-dispense"
						className="table"
						dynamic={true}
						config={webConst.dataTableConfig("id")}
						records={this.state.activeAlcoholRecords}
						columns={this.state.activeAlcoholColumns}
						total_record={this.state.activeAlcoholTotalRecord ? this.state.activeAlcoholTotalRecord : 0}
						onChange={data => { this.tableChangeHandler(data) }}
						loading={this.state.isAlcoholLoading}
					/>
				</div>
			</div>
		)
	}
}


AlcoholDispenseHistory.defaultProps = {
	clientId: '',
	alcoholActiveTab: "",
	alcoholType: "",
	alcoholSuccess: "",
	alcoholStatus: ""
};

AlcoholDispenseHistory.propTypes = {
	clientId: PropTypes.any,
	alcoholActiveTab: PropTypes.any,
	alcoholType: PropTypes.any,
	alcoholSuccess: PropTypes.any
};

function mapStateToProps(state) {
	return {
		clientId: state.appLayout.clientId ? state.appLayout.clientId : "",
		alcoholActiveTab: state.alcoholPlan.alcoholActiveTab,
		alcoholSuccess: state.alcoholPlan.success,
		alcoholType: state.alcoholPlan.type,
		alcoholStatus: state.alcoholPlan.status
	};
}

const mapDispatchToProps = {
	getAlcoholDispenseHistoryRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(AlcoholDispenseHistory);

import { AUTH_STATUS, CLIENT_ID } from './constants';
import * as webConst from '../../utils/WebConstant';


/*
    API REQUEST 
 */
export const getAuthStatus = () => {
    return dispatch => {
        let token = webConst.getUserToken();
        if (token.trim() !== "")
            dispatch(setAuthStatus(true));
        else
            dispatch(setAuthStatus(false));
    }
};


export function setAuthStatus(loggedIn) {
    return {
        type: AUTH_STATUS,
        loggedIn
    };
}

export function setClientId(clientId) {
    return {
        type: CLIENT_ID,
        clientId
    };
}
import React, { Component } from 'react';
import './Header.scss';
import { BrowserRouter as Router, Route, Switch, Redirect, Link, NavLink } from "react-router-dom";
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import logo from '../../../images/logo.png';
import * as webConst from '../../../utils/WebConstant';
import defaultAvatar from '../../../images/default_avatar.png';

import Login from '../../Auth/Login';
import Forget from '../../Auth/Forget'
import Home from '../../Home';
import Goal from '../../Goal';
import Logout from '../../Auth/Logout';
import Journal from "../../Journal";
import Medicine from '../../Medicine';
import Message from '../../Message';
import Setting from '../../Setting';

import Detox from '../../Medicine/Detox';
import AddDetox from '../../Medicine/Detox/AddDetox';
import DetoxDispenseHistory from '../../Medicine/Detox/DetoxDispenseHistory';
import DetoxDispense from '../../Medicine/Detox/DetoxDispense';
import DetoxSuspended from '../../Medicine/Detox/DetoxSuspended/DetoxSuspended';
import DetoxDetail from '../../Medicine/Detox/DetoxDetail';

import PN from '../../Medicine/PN';
import AddPN from '../../Medicine/PN/AddPN';
import PNDispenseHistory from '../../Medicine/PN/PNDispenseHistory';
import PNDispense from '../../Medicine/PN/PNDispense';
import SuspendedPN from '../../Medicine/PN/SuspendedPN';

import Alcohol from '../../Medicine/Alcohol';
import AddAlcohol from '../../Medicine/Alcohol/AddAlcohol';
import AlcoholDispenseHistory from '../../Medicine/Alcohol/AlcoholDispenseHistory';
import AlcoholDispense from '../../Medicine/Alcohol/AlcoholDispense';
import SuspendedAlcohol from '../../Medicine/Alcohol/SuspendedAlcohol';

import * as routes from '../../../utils/RouteConstant';
import { Nav, Navbar, NavDropdown, Container, NavItem } from 'react-bootstrap';

// import * as webConst from '../../../utils/WebConstant';
import GoalDetail from '../../GoalDetail/GoalDetail';


export function PublicRoute({ component: Component, authed, ...rest }) {
	// console.log("PublicRoute", authed,routes.HOME_ROUTE);
	return (
		<Route
			{...rest}
			render={(props) => authed === false
				? <Component {...props} />
				: <Redirect to={{ pathname: routes.HOME_ROUTE }} />
			}
		/>
	);
};

export function PrivateRoute({ component: Component, authed, ...rest }) {
	// console.log("PrivateRoute", authed);
	return (
		<Route
			{...rest}
			render={(props) => authed === true
				? <Component {...props} />
				: <Redirect to={{ pathname: routes.LOGIN_ROUTE, state: { from: props.location } }} />}
		/>
	)
}

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loggedIn: webConst.getUserToken() ? true : false,
			user: webConst.getUser() ? webConst.getUser() : {},
		};		
	}
	componentDidMount=()=>{
		let _this = this;
		webConst.emitter.addListener(webConst.LOGIN_EVENT, () => {
			_this.setState({
				loggedIn: webConst.getUserToken() ? true : false,
				user: webConst.getUser() ? webConst.getUser() : {},
			});
		});	
	}
	
	componentDidUpdate = prevProps => {
		const { loggedIn } = this.props
		let _this = this;
		if (loggedIn) {
			if (prevProps.loggedIn !== this.props.loggedIn) {
				this.setState({ 'loggedIn': this.props.loggedIn });
			}
		}
		else{
			webConst.emitter.addListener(webConst.LOGIN_EVENT, () => {
				_this.setState({
					loggedIn: webConst.getUserToken() ? true : false,
					user: webConst.getUser() ? webConst.getUser() : {}
				});
			});	
		}
	}

	render() {
		return (
			<div>
				{this.state.loggedIn ?
					<div className="validi-header-bar-main">
						{/* <Navbar expand="md"> */}
						<Navbar>
							<Container>
								<Link to={routes.HOME_ROUTE} className="navbar-brand">
									<img src={logo} alt="validi logo" />
									<h3 className="main-title">{process.env.REACT_APP_NAME}</h3>
								</Link>
								{/* <Navbar.Toggle aria-controls="navbarScroll" /> */}
								{/* <Navbar.Collapse id="navbarScroll"> */}
								{/* <Navbar.Collapse> */}
									{this.state.loggedIn ?
									    <div className="validi-header-menu-wrapper">
											<div className="validi-header-menu-list">
												<NavLink to={routes.HOME_ROUTE} className="nav-link" activeClassName="menu-active"><Translate id="menu.home" /></NavLink>
												<NavLink to={routes.GOAL_ROUTE} className="nav-link" activeClassName="menu-active"><Translate id="menu.goal" /></NavLink>
												<NavLink to={routes.JOURNAL_ROUTE} className="nav-link" activeClassName="menu-active"><Translate id="menu.journal" /></NavLink>
												<NavLink to={routes.MEDICINE_PLAN_ROUTE} className="nav-link" activeClassName="menu-active"><Translate id="menu.medicine" /></NavLink>
												<NavLink to={routes.MESSAGE_ROUTE} className="nav-link" activeClassName="menu-active"><Translate id="menu.message" /></NavLink>
											</div>
											<div className="validi-avatar-wrapper">
												<div className="validi-avatar-details-wrepper">
													<div className="validi-avatar-img">
														<img src={defaultAvatar} alt="validi logo" />
													</div>
													<div className="validi-avatar-details">
														<h5 className="validi-user-main-title">{webConst.getCurrentUser()}</h5>
														{/* <p className="validi-paragraph">CPR: 131281-4362</p> */}
													</div>
												</div>
												<NavItem to="#" className="validi-user-login-block">
													<NavDropdown title="" id="nav-dropdown">
														<div className="validi-dropdown-menu">
															<NavLink to={routes.SETTING_ROUTE} className="nav-link" activeClassName="menu-active"><Translate id="menu.setting" /></NavLink>
															<NavLink to={routes.LOGOUT_ROUTE} className="nav-link logout" activeClassName="menu-active"><Translate id="menu.logout" /></NavLink>
														</div>
													</NavDropdown>
												</NavItem>
											</div>
										</div>
										:
										<div className="">
											<Link to={routes.LOGIN_ROUTE} className="nav-link"><Translate id="menu.login" /></Link>
										</div>
									}
								{/* </Navbar.Collapse> */}
							</Container>
						</Navbar>
					</div>
					:
					''
				}
				<div>
					<Switch>
						<PrivateRoute authed={this.state.loggedIn} path="/" exact component={Home} />
						<PublicRoute authed={this.state.loggedIn} path={routes.LOGIN_ROUTE} component={Login} />
						<PublicRoute authed={this.state.loggedIn} path={"/forget"} component={Forget} />
						<PrivateRoute authed={this.state.loggedIn} path={routes.HOME_ROUTE} component={Home} />
						{/* <PrivateRoute authed={this.state.loggedIn} path={routes.GOAL_ROUTE+"/:goalId?"} component={Goal} /> */}
						<PrivateRoute authed={this.state.loggedIn} path={routes.GOAL_ROUTE} component={Goal} />
						<PrivateRoute authed={this.state.loggedIn} path={routes.GOAL_DETAIL_ROUTE+"/:goalId?"} component={GoalDetail} />
						<PrivateRoute authed={this.state.loggedIn} path={routes.JOURNAL_ROUTE} component={Journal} />
						<PrivateRoute authed={this.state.loggedIn} path={routes.MEDICINE_PLAN_ROUTE} component={Medicine} />
						<PrivateRoute authed={this.state.loggedIn} path={routes.MESSAGE_ROUTE} component={Message} />
						<PrivateRoute authed={this.state.loggedIn} path={routes.SETTING_ROUTE} component={Setting} />
						
						<PrivateRoute authed={this.state.loggedIn} path={routes.DETOX_PLAN_ROUTE} component={Detox} />
						<PrivateRoute authed={this.state.loggedIn} path={routes.ADD_DETOX_ROUTE+"/:detoxId?"} component={AddDetox} />
						<PrivateRoute authed={this.state.loggedIn} path={routes.DETOX_DISPENSE_ROUTE} component={DetoxDispense} />
						<PrivateRoute authed={this.state.loggedIn} path={routes.DETOX_DISPENSE_HISTORY_ROUTE} component={DetoxDispenseHistory} />
						<PrivateRoute authed={this.state.loggedIn} path={routes.DETOX_SUSPENDED_ROUTE} component={DetoxSuspended} />
						<PrivateRoute authed={this.state.loggedIn} path={routes.DETOX_DETAIL_ROUTE+"/:detoxId"} component={DetoxDetail} />
						<PrivateRoute authed={this.state.loggedIn} path={routes.DETOX_SUSPEND_ROUTE+"/:detoxId"} component={AddDetox} />

						<PrivateRoute authed={this.state.loggedIn} path={routes.PN_PLAN_ROUTE} component={PN} />
						<PrivateRoute authed={this.state.loggedIn} path={routes.ADD_PN_ROUTE} component={AddPN} />
						<PrivateRoute authed={this.state.loggedIn} path={routes.PN_DISPENSE_ROUTE+"/:pnId"} component={PNDispense} />
						<PrivateRoute authed={this.state.loggedIn} path={routes.PN_HISTORY_ROUTE} component={PNDispenseHistory} />
						<PrivateRoute authed={this.state.loggedIn} path={routes.PN_SUSPENDED_ROUTE} component={SuspendedPN} />
						
						<PrivateRoute authed={this.state.loggedIn} path={routes.ALCOHOL_PLAN_ROUTE} component={Alcohol} />
						<PrivateRoute authed={this.state.loggedIn} path={routes.ADD_ALCOHOL_ROUTE} component={AddAlcohol} />
						<PrivateRoute authed={this.state.loggedIn} path={routes.ALCOHOL_DISPENSE_ROUTE+"/:alcoholId"} component={AlcoholDispense} />
						<PrivateRoute authed={this.state.loggedIn} path={routes.ALCOHOL_HISTORY_ROUTE} component={AlcoholDispenseHistory} />
						<PrivateRoute authed={this.state.loggedIn} path={routes.ALCOHOL_SUSPENDED_ROUTE} component={SuspendedAlcohol} />
						
						<PrivateRoute authed={this.state.loggedIn} path={routes.LOGOUT_ROUTE} component={Logout} />
					</Switch>
				</div>
			</div>)
	}
}


const mapStateToProps = (state) => {
	return {
		isLogin: state.login.loginStatus,
		loggedIn: state.appLayout.loggedIn
	};
}

const mapDispatchToProps = (dispatch) => {
	return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);

import {
  ALCOHOL_PLAN_SUCCESS,
  ALCOHOL_PLAN_FAIL,
  ALCOHOL_ACTIVE_TAB,
  ALCOHOL_DISPENSE_DETAIL_SUCCESS,
  ALCOHOL_DISPENSE_DETAIL_FAIL,
  ALCOHOL_DISPENSE_SUCCESS,
  ALCOHOL_DISPENSE_FAIL,
  ALCOHOL_PLAN_SUSPEND_SUCCESS,
  ALCOHOL_PLAN_SUSPEND_FAIL,
  ADD_ALCOHOL_PLAN_SUCCESS,
  ADD_ALCOHOL_PLAN_FAIL,
  ALCOHOL_SUSPENDED_PLAN_SUCCESS,
  ALCOHOL_SUSPENDED_PLAN_FAIL,
  ALCOHOL_DISPENSE_HISTORY_SUCCESS,
  ALCOHOL_DISPENSE_HISTORY_FAIL
  
} from './Constants';

const initialState = {
  isLoading: true,
  alcoholType: ""
};

export default function alcoholMedicineReducer(state = initialState, action) {
  switch (action.type) {
    case ALCOHOL_PLAN_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: '',
        status: action.status,
        type: action.type
      };
    case ALCOHOL_PLAN_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type: action.type
      };
    case ALCOHOL_ACTIVE_TAB:
      return {
        ...state,
        alcoholActiveTab: action.alcoholType,
        type: action.type
      };
    case ALCOHOL_DISPENSE_DETAIL_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: '',
        status: action.status,
        type: action.type
      };
    case ALCOHOL_DISPENSE_DETAIL_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type: action.type
      };
    case ALCOHOL_DISPENSE_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: '',
        status: action.status,
        type: action.type
      };
    case ALCOHOL_DISPENSE_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type: action.type
      };
    case ALCOHOL_PLAN_SUSPEND_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: '',
        status: action.status,
        type: action.type
      };
    case ALCOHOL_PLAN_SUSPEND_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type: action.type
      };
    case ADD_ALCOHOL_PLAN_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: '',
        status: action.status,
        type: action.type
      };
    case ADD_ALCOHOL_PLAN_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type: action.type
      };
    case ALCOHOL_SUSPENDED_PLAN_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: '',
        status: action.status,
        type: action.type
      };
    case ALCOHOL_SUSPENDED_PLAN_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type: action.type
      };
      case ALCOHOL_DISPENSE_HISTORY_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: '',
        status: action.status,
        type: action.type
      };
    case ALCOHOL_DISPENSE_HISTORY_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type: action.type
      };
    default:
      return state;
  }
}
import React, { Component } from 'react';
import './AlcoholDispense.scss';
import { FormGroup, Label, Input, Form, InputGroup, FormControl } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as routes from '../../../../utils/RouteConstant';
import backArrow from '../../../../images/back-arrow.svg';
import { Translate } from 'react-localize-redux';
import * as webConst from '../../../../utils/WebConstant';
import { setAlcoholActiveTab, getAlcoholDispenseDetailRequest, saveDispenseDosageRequest } from '../../../../containers/Medicines/Alcohol/AlcoholPlanContainer/action';
import { ALCOHOL_DISPENSE_DETAIL_SUCCESS, ALCOHOL_DISPENSE_SUCCESS, ALCOHOL_DISPENSE_FAIL } from '../../../../containers/Medicines/Alcohol/AlcoholPlanContainer/Constants';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import daLocale from "date-fns/locale/da";
import moment from "moment";

class AlcoholDispense extends Component {
	constructor(props) {
		super(props);
		this.state = {
			clientId: "",
			clientName: "",
			clientCPR: "",
			clientPhoto: "",
			alcoholId: "",
			isLoading: false,
			validated: false,
			dispenseForm: "",
			medicineId: "",
			dispenseDetail: {},
			totalStrength: 0,
			dosageAmount: 0,
			isFormLoading: false,
			isAlcoholLoading:false
		};
		this.dispenseForm = React.createRef();
	}

	componentDidMount() {
		let _this = this;
		let alcoholId = "";
		if (this.props.match && this.props.match.params && this.props.match.params.alcoholId) {
			alcoholId = parseInt(this.props.match.params.alcoholId);
			let medicineId = this.props.history.location.state.medicine_id ? this.props.history.location.state.medicine_id : "";
			if (alcoholId > 0)
				this.setState({ alcoholId: alcoholId, medicineId: medicineId });
			else
				this.history.push(routes.ALCOHOL_PLAN_ROUTE);
		}
		else {
			this.history.push(routes.ALCOHOL_PLAN_ROUTE);
		}

		let client = webConst.getClient();
		let clientId = client && typeof client !== 'undefined' && typeof client.client_id !== 'undefined' ? client.client_id : 0;
		if (clientId > 0) {
			let clientName = client && typeof client !== 'undefined' && typeof client.client_name !== 'undefined' ? client.client_name : 0;
			let cpr = client && typeof client !== 'undefined' && typeof client.client_socialid !== 'undefined' ? client.client_socialid : 0;
			let photo = client && typeof client !== 'undefined' && typeof client.photo !== 'undefined' ? client.photo : 0;
			this.setState({ clientId: clientId, clientName: clientName, clientCPR: cpr, clientPhoto: photo });
			setTimeout(() => {
				if (_this.state.alcoholId > 0) {
					let params = new URLSearchParams();
					params.append('clinic_id', webConst.getClinicId());
					params.append('client_id', webConst.getClientId());
					params.append('alcohol_id', _this.state.alcoholId);
					params.append('medicine_id', _this.state.medicineId);
					_this.props.getAlcoholDispenseDetailRequest(params);
				}
			}, 100);
		}
		else {
			webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.select-client-message" />);
			this.props.history.push(routes.HOME_ROUTE);
		}
	}
	componentDidUpdate(prevProps) {
		const { alcoholSuccess, alcoholStatus, alcoholType, alcoholFail } = this.props;
		if (alcoholStatus === 200) {
			if (alcoholType === ALCOHOL_DISPENSE_DETAIL_SUCCESS) {
				if (prevProps.alcoholSuccess !== alcoholSuccess) {
					this.setState({
						isAlcoholLoading: false,
					});
					this.setState({ dispenseDetail: alcoholSuccess.data });
				}
			}

			if (alcoholType === ALCOHOL_DISPENSE_SUCCESS) {
				if (prevProps.alcoholSuccess !== alcoholSuccess) {
					this.setState({
						isFormLoading: false,
					});
					webConst.toaster(webConst.TOAST_SUCCESS, <Translate id="medicine.dispense-status-message" />);
					this.props.setAlcoholActiveTab("active-pn");
					this.props.history.push(routes.ALCOHOL_PLAN_ROUTE);
				}
			}
		}
		
		if (alcoholStatus === 400) {
			if (this.state.isAlcoholLoading) {
				this.setState({
					isAlcoholLoading: false,
					isFormLoading: false
				});
			}
			if (alcoholType === ALCOHOL_DISPENSE_FAIL) {
				if (prevProps.alcoholFail !== alcoholFail) {
					webConst.toaster(webConst.TOAST_ERROR, alcoholFail);
				}
			}
		}
	}
	dispenseMedicine = (event) => {
		event.preventDefault();
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			this.setState({ "validated": true });
			event.preventDefault();
			event.stopPropagation();
		}
		else {
			if (this.state.clientId) {
				let givenDosage = this.state.dispenseDetail.given_dosage ? this.state.dispenseDetail.given_dosage : 0;
				let totalAmount = this.state.dispenseDetail.total_amount ? this.state.dispenseDetail.total_amount : 0;
				let medicineType = this.state.dispenseDetail.type ? this.state.dispenseDetail.type : 0;
				let total = parseFloat(givenDosage) + parseFloat(this.state.dosageAmount);
				if (total > totalAmount) {
					let message = props => (
						<Translate>
							{({ translate }) => <span>{translate("medicine.dosage-alert", { "dosage": givenDosage + " " + medicineType })}</span>}
						</Translate>
					);
					webConst.toaster(webConst.TOAST_ERROR, message);
				}
				else {
					const params = new URLSearchParams();
					params.append('client_id', this.state.clientId);
					params.append('user_id', webConst.getUserId());
					params.append('clinic_id', webConst.getClinicId());
					params.append('dosage_amount', event.target.dosageAmount.value);
					params.append('date', moment(this.state.date).format("YYYY-MM-DD"));
					params.append('note', event.target.note.value);
					params.append('medicine_id', this.state.dispenseDetail.medicine_id);
					params.append('package_id', this.state.dispenseDetail.package_id);
					params.append('alcohol_id', this.state.alcoholId);
					this.props.saveDispenseDosageRequest(params);
				}
				this.setState({ 'isFormLoading': true, validated: false });
			} else {
				webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.select-client-message" />);
			}
		}
		this.setState({ "validated": true });
	}

	handleChange = (e) => {
		let name = e.target.name;
		let value = e.target.value;
		this.setState({ [name]: value });
	}

	handleChangeDateRange = (name, value) => {
		this.setState({ [name]: value });
	}

	totalStrength = () => {
		let total = 0;
		let totalDosage = this.state.dispenseDetail.total_amount ? this.state.dispenseDetail.total_amount : 0;
		let medicineStrength = this.state.dispenseDetail.unit_strength ? this.state.dispenseDetail.unit_strength : 0;
		let totalStrength = medicineStrength * totalDosage;
		this.setState({ totalStrength: totalStrength });
	}

	handleClearForm = () => {
		this.setState({ date: "", dosageAmount: "", note: "" });
	}

	render() {
		let totalAmount = 0;
		let total = 0;
		let strength = 0;
		let totalMg = "";
		let unit = "mg";
		let medicineType = "Tablet";
		let dosageAmount = 0;
		let dosageMg = "";
		if (this.state.dispenseDetail && this.state.dispenseDetail.total_amount) {
			totalAmount = parseFloat(this.state.dispenseDetail.total_amount);
			total = parseFloat(this.state.dispenseDetail.total_amount) * parseFloat(this.state.dispenseDetail.unit_strength);
			strength = parseFloat(this.state.dispenseDetail.unit_strength);
			unit = this.state.dispenseDetail.unit.replace(/[^a-zA-Z\/]+/g, '');
			totalMg = (totalAmount + " " + webConst.shortType(webConst.ucfirst(this.state.dispenseDetail.type)) + " * " + strength + " " + unit + " = " + total + " " + unit);
			medicineType = this.state.dispenseDetail.type;
			dosageAmount = (this.state.dosageAmount ? parseFloat(this.state.dosageAmount) : 0) * parseFloat(this.state.dispenseDetail.unit_strength);
			dosageMg = ((this.state.dosageAmount ? parseFloat(this.state.dosageAmount) : 0) + " " + webConst.shortType(webConst.ucfirst(this.state.dispenseDetail.type)) + " * " + strength + " " + unit + " = ");
		}
		return (
			<div className="validi-main-layout validi-pn-plan-layout-main">
				<div>
					<div className="container">
						<div className="validi-back-btn-wrapper">
							<Link to={routes.ALCOHOL_PLAN_ROUTE} onClick={() => { this.props.setAlcoholActiveTab("active-alcohol") }}>
								<i>
									<img src={backArrow} />
								</i>
								<Translate id="medicine.back-to-alcohol-plan" />
							</Link>
						</div>
						<h1 className="main-title"><Translate id="medicine.alcohol-dispense" /></h1>
						<div className="validi-medicine-wrapper">
							<div className="validi-inner-header-bar">
								<div className="validi-avatar-details-wrepper">
									<div className="validi-avatar-img">
										{this.state.clientPhoto ? <img src={this.state.clientPhoto} alt="" /> : ""}
									</div>
									<div className="validi-avatar-details">
										<h5 className="validi-user-main-title">{this.state.clientName ? this.state.clientName : ""}</h5>
										<p className="validi-paragraph"><Translate id="client-goal.cpr-number" /> : {this.state.clientCPR ? this.state.clientCPR : ""}</p>
									</div>
								</div>
								<div className="validi-select-input">

								</div>
							</div>
							<div className="validi-tabbar-wrapper">
								<div className="">
									<div className="validi-tabbar-inner-header">
										<div className="">
											<h4 className="validi-subtitle"><Translate id="medicine.alcohol-dispense" /></h4>
										</div>
									</div>
									<div className="row">
										<div className="col-md-8 col-lg-6">
											<Form className="row" noValidate validated={this.state.validated} onSubmit={this.dispenseMedicine} ref={this.dispenseForm}>
												<div className="col-md-6">
													<div className="form-group">
														<label><Translate id="medicine.doctor-type" /></label>
														{this.state.dispenseDetail.doctor_type === "1" ?
															<input className='form-control' readOnly={true} value={"External"} />
															:
															<input className='form-control' readOnly={true} value={"Internal"} />
														}
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-group">
														<label><Translate id="medicine.doctor" /></label>
														{this.state.dispenseDetail.doctor_type === "1" ?
															<input className='form-control' readOnly={true} value={this.state.dispenseDetail && this.state.dispenseDetail.external_doctor ? webConst.ucfirst(this.state.dispenseDetail.external_doctor) : ""} />
															:
															<input className='form-control' readOnly={true} value={this.state.dispenseDetail && this.state.dispenseDetail.doctor_user ? webConst.ucfirst(this.state.dispenseDetail.doctor_user) : ""} />
														}
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-group">
														<label><Translate id="medicine.medicine" /></label>
														<input className='form-control' readOnly={true} value={this.state.dispenseDetail && this.state.dispenseDetail.medicine_name ? webConst.ucfirst(this.state.dispenseDetail.medicine_name) : ""} />
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-group">
														<label><Translate id="medicine.medicine-type" /></label>
														<input className='form-control' readOnly={true} value={this.state.dispenseDetail && this.state.dispenseDetail.type ? webConst.ucfirst(this.state.dispenseDetail.type) : ""} />
													</div>
												</div>
												<div className="col-md-12">
													<div className="form-group">
														<label><Translate id="medicine.medicine-size" /></label>
														<input className='form-control mb-3' readOnly={true} value={this.state.dispenseDetail && this.state.dispenseDetail.unit ? this.state.dispenseDetail.unit : ""} />
														<div>
															{this.state.dispenseDetail && this.state.dispenseDetail.image ?
																<img src={webConst.getMedicineUrl(this.state.dispenseDetail.image)} height={150} />
																: ""
															}
														</div>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-group">
														<label><Translate id="medicine.total-medicine" /></label>
														<input className='form-control' readOnly={true} value={totalMg} />
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-group">
														<label><Translate id="medicine.dispense-date" /></label>
														<DatePicker
															selected={this.state.date}
															onChange={(date) => { this.handleChangeDateRange('date', date) }}
															selectsStart
															startDate={this.state.date}
															dateFormat="dd-MM-yyyy"
															className="form-control"
															withPortal
															locale={daLocale}
															required
														/>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-group">
														<Form.Group className="form-group" controlId="dosageAmount">
															<Form.Label><Translate id="medicine.dosage-amount" /></Form.Label>
															<InputGroup className="mb-3">
																<Translate>
																	{({ translate }) =>
																		<FormControl
																			type="text"
																			name="dosageAmount"
																			value={this.state.dosageAmount ? this.state.dosageAmount : ""}
																			onChange={(e) => { this.handleChange(e) }}
																			placeholder={translate("medicine.dosage-amount")}
																			required
																		/>
																	}
																</Translate>
																<InputGroup.Text id="basic-addon2">{webConst.shortType(webConst.ucfirst(medicineType))}</InputGroup.Text>
															</InputGroup>
															<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
														</Form.Group>
														<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-group">
														<label></label>
														<input className='form-control' readOnly={true} value={dosageMg + dosageAmount + " " + unit} />
													</div>
												</div>
												<div className="col-md-12">
													<div className="form-group">
														<label><Translate id="medicine.note" /></label>
														<Translate>
															{({ translate }) =>
																<Form.Control
																	type="textarea"
																	name="note"
																	value={this.state.note ? this.state.note : ""}
																	onChange={(e) => { this.handleChange(e) }}
																	placeholder={translate("medicine.enter-note-message")}
																	as="textarea"
																	rows={3}
																	required
																/>
															}
														</Translate>
													</div>
												</div>
												<div className="row">
													<div className="col-md-6">
														<button type="submit" className="validi-theme-btn" onClick={this.handleFilterJournal}>
															{webConst.isLoader(this.state.isFormLoading, <Translate id="general.save" />)}
														</button>
														<button type="reset" className="validi-theme-btn clear-btn" onClick={this.handleClearForm}><Translate id="general.clear" /></button>
													</div>
												</div>
											</Form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}


AlcoholDispense.defaultProps = {
	clientId: '',
	pnActiveTab: "active-pn",
	alcoholType: "",
	alcoholSuccess: "",
	pnFail: "",
};

AlcoholDispense.propTypes = {
	clientId: PropTypes.any,
	pnActiveTab: PropTypes.any,
	alcoholType: PropTypes.any,
	alcoholSuccess: PropTypes.any,
	pnFail: PropTypes.any,
};

function mapStateToProps(state) {
	return {
		clientId: state.appLayout.clientId ? state.appLayout.clientId : "",
		alcoholActiveTab: state.alcoholPlan.pnActiveTab,
		alcoholSuccess: state.alcoholPlan.success,
		alcoholFail: state.alcoholPlan.fail,
		alcoholType: state.alcoholPlan.type,
		alcoholStatus: state.alcoholPlan.status
	};
}

const mapDispatchToProps = {
	setAlcoholActiveTab,
	getAlcoholDispenseDetailRequest,
	saveDispenseDosageRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(AlcoholDispense);

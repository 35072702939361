import { CLIENT_SUCCESS, CLIENT_FAIL,CLIENT_TOOLTIP_SUCCESS,CLIENT_TOOLTIP_FAIL } from './Constants';

const initialState = {
  isLoading: true
};
export default function homeReducer(state = initialState, action) {
  switch (action.type) {
    case CLIENT_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: '',
        status: action.status,
        type: action.type
      };
    case CLIENT_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type: action.type
      };
      case CLIENT_TOOLTIP_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: '',
        status: action.status,
        type: action.type
      };
    case CLIENT_TOOLTIP_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type: action.type
      };
    default:
      return state;
  }
}
import React, { Component } from 'react';
import { withLocalize } from 'react-localize-redux';
import './App.scss';
import { renderToStaticMarkup } from 'react-dom/server';
import AppLayout from './components/AppLayout';
import 'bootstrap/scss/bootstrap.scss';
import englishTranslation from './utils/locale/en.json';
import danishTranslation from './utils/locale/da.json';
import { ToastContainer } from 'react-toastify';


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      "en": true,
      "da": false,
    };

    let languages = [];

    languages = [
      { name: 'English', code: 'en' },
      { name: 'Denish', code: 'da' }
    ];

    this.props.initialize({
      languages: languages,
      translation: {},
      options: { renderToStaticMarkup }
    });

    this.props.addTranslationForLanguage(englishTranslation, 'en');
    this.props.addTranslationForLanguage(danishTranslation, 'da');
  }
  
  render() {
    return (
      <div>
        <AppLayout/>
        <ToastContainer />
      </div>
    );
  }
}


export default withLocalize(App);

import React, { Component, Fragment } from 'react';
// import Select from "react-select";
import { Tabs, Tab, Table, FormGroup, Label, Input, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './PN.scss';
import * as webConst from '../../../utils/WebConstant';
import * as routes from '../../../utils/RouteConstant';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import backArrow from '../../../images/back-arrow.svg';
import ReactDatatable from '@mkikets/react-datatable';
import { getPnMedicinePlanRequest, setPnActiveTab, suspendPnMedicineRequest } from '../../../containers/Medicines/Pn/PnPlanContainer/action';
import { PN_PLAN_SUCCESS, PN_PLAN_SUSPEND_SUCCESS, PN_PLAN_SUSPEND_FAIL } from '../../../containers/Medicines/Pn/PnPlanContainer/Constants';
import moment from "moment";
import AddPN from '../../Medicine/PN/AddPN';
import PNDispense from './PNDispense/PNDispense';
import PNDispenseHistory from './PNDispenseHistory/PNDispenseHistory';
import SuspendedPN from './SuspendedPN/SuspendedPN';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { Cube } from 'react-preloaders2';

class PN extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPnLoading: true,
			clientId: "",
			clientName: "",
			clientCPR: "",
			clientPhoto: "",
			activePnTotalRecord: 0,
			activePnRecords: [],
			activePnColumns: this.activePnDatatable(),
			pnActiveTab: "active-pn",
			isLoadedDatatable: 0
		};
	}

	componentDidMount() {
		let _this = this;
		let client = webConst.getClient();
		let clientId = client && typeof client !== 'undefined' && typeof client.client_id !== 'undefined' ? client.client_id : 0;
		if (clientId > 0) {
			let clientName = client && typeof client !== 'undefined' && typeof client.client_name !== 'undefined' ? client.client_name : 0;
			let cpr = client && typeof client !== 'undefined' && typeof client.client_socialid !== 'undefined' ? client.client_socialid : 0;
			let photo = client && typeof client !== 'undefined' && typeof client.photo !== 'undefined' ? client.photo : 0;
			this.setState({ clientId: clientId, clientName: clientName, clientCPR: cpr, clientPhoto: photo, isPnLoading: true });
			this.getActivePn();
			this.activeTab("active-pn");
		}
		else {
			webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.select-client-message" />);
			this.props.history.push(routes.HOME_ROUTE);
		}
	}

	componentDidUpdate(prevProps) {
		const { pnSuccess, pnStatus, pnType, pnActiveTab } = this.props;
		if (this.state.pnActiveTab !== pnActiveTab && pnActiveTab !== "") {
			if (pnActiveTab !== "") {
				this.setState({ pnActiveTab: pnActiveTab });
			}

			if (prevProps.pnActiveTab !== "" && prevProps.pnActiveTab !== "active-pn" && pnActiveTab === "active-pn") {
				this.getActivePn();
				this.activeTab("active-pn");
				this.setState({ isPnLoading: true });
			}
		}
		if (pnStatus === 200) {
			if (pnType === PN_PLAN_SUCCESS) {
				if (prevProps.pnSuccess !== pnSuccess) {
					this.setState({
						isPnLoading: false,
					});

					if (pnSuccess.records && pnSuccess.records.length) {
						this.setState({ activePnRecords: pnSuccess.records, activePnTotalRecord: pnSuccess.total_records });
					}
				}
			}
			else if (pnType === PN_PLAN_SUSPEND_SUCCESS) {
				if (prevProps.pnSuccess !== pnSuccess) {
					this.setState({
						isPnLoading: false,
					});
					webConst.toaster(webConst.TOAST_SUCCESS, <Translate id="medicine.suspend-message" />);
				}
			}
		}

		if (pnStatus === 400) {
			if (this.state.isPnLoading) {
				this.setState({
					isPnLoading: false,
				});
			}

			if (pnType === PN_PLAN_SUSPEND_SUCCESS) {
				webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.something-wrong" />);
			}

			if (this.state.activePnRecords.length > 0)
				this.setState({ activePnRecords: [], activePnTotalRecord: 0 });
		}
	}

	getActivePnData = (params) => {
		this.props.getPnMedicinePlanRequest(params);
	}

	getActivePn = () => {
		let params = new URLSearchParams();
		params.append('clinic_id', webConst.getClinicId());
		params.append('client_id', webConst.getClientId());
		params.append('user_id', webConst.getUserId());
		this.props.getPnMedicinePlanRequest(params);
	}

	tableChangeHandler = (data) => {
		const params = new URLSearchParams();
		params.append('clinic_id', webConst.getClinicId());
		params.append('client_id', webConst.getClientId());
		params.append('user_id', webConst.getUserId());
		Object.keys(data).map((key) => {
			if (key === "sort_order") {
				if (data[key]) {
					params.append("sort_order", data[key].order !== "false" ? data[key].order : "");
					params.append("sort_column", data[key].column);
				}
			} else {
				params.append(key, data[key]);
			}
		});

		this.getActivePnData(params);
	}

	activePnDatatable = function () {
		let _this = this;
		let columns = [
			{
				key: "medicine_name",
				text: <Translate id="medicine.medicine" />,
				align: "left",
				className: "text-capitalize",
				sortable: true,
				cell: record => {
					return (
						<Fragment>
							{record.medicine_name}
						</Fragment>
					);
				}
			},
			{
				key: "total_dosage",
				text: <Translate id="medicine.total-dosage" />,
				align: "left",
				className: "text-capitalize validi-mg-text",
				sortable: true,
				cell: record => {
					return (
						<Fragment>
							{record.total_amount}  {webConst.ucfirst(record.unit.replace(/[^a-zA-Z\/]+/g, ''))}
						</Fragment>
					);
				}
			},
			{
				key: "start_date",
				text: <Translate id="medicine.start-date" />,
				align: "left",
				className: "text-capitalize validi-date-text",
				sortable: true,
				cell: record => {
					return (
						<Fragment>
							{record.start_date}
						</Fragment>
					);
				}
			},
			{
				key: "total_given_dosage",
				text: <Translate id="medicine.today-give-status" />,
				align: "left",
				className: "text-capitalize validi-mg-text",
				sortable: true,
				cell: record => {
					return (
						<Fragment>
							{record.total_given_dosage > 0 ? record.total_given_dosage : 0}  {webConst.ucfirst(record.unit.replace(/[^a-zA-Z\/]+/g, ''))}
						</Fragment>
					);
				}
			},
			{
				key: "created_by",
				text: <Translate id="medicine.created-by" />,
				align: "left",
				className: "text-capitalize",
				sortable: true,
				cell: record => {
					return (
						<Fragment>
							{record.created_by}
						</Fragment>
					);
				}
			},
			{
				key: "remarks",
				text: <Translate id="medicine.note" />,
				align: "left",
				className: "text-capitalize validi-date-text",
				sortable: true,
				cell: record => {
					return (
						<Fragment>
							{record.remarks}
						</Fragment>
					);
				}
			},
			{
				key: "medicine_effect",
				text: "Effect",
				align: "left",
				className: "text-capitalize validi-date-text",
				sortable: true,
				cell: record => {
					return (
						<Fragment>
							{record.medicine_effect}
						</Fragment>
					);
				}
			},
			{
				key: "detox-action",
				text: "Action",
				className: "action validi-mg-text",
				width: 100,
				align: "left",
				sortable: false,
				cell: record => {
					return (
						<Fragment>
							<div className="validi-mg-text cursor-pointer">
								<span onClick={() => { _this.dispenseMedicine(record) }}>Give</span>&nbsp;|&nbsp;
								<span onClick={() => { _this.suspendMedicine(record) }}>Suspend</span>
							</div>
						</Fragment>
					);
				}
			}
		];
		return columns;
	}

	activeTab = (id) => {
		this.props.setPnActiveTab(id);
	}

	dispenseMedicine = (record) => {
		this.props.setPnActiveTab("pn-dispense");
		this.props.history.push({
			pathname: routes.PN_DISPENSE_ROUTE + "/" + record.id,
			state: record
		});
		// this.history.push(routes.PN_DISPENSE_ROUTE,[{"pn-id":record.id}]);
	}

	suspendMedicine = (record) => {
		let pnId = record.id;
		let medicineId = record.medicine_id;
		let clientId = record.client_id;
		let _this = this;
		let options = {
			closeOnEscape: true,
			closeOnClickOutside: true,
			customUI: ({ onClose }) => {
				return (
					<Card className="pt-3 p-2">
						<Card.Body>
							<Card.Text className='pb-2'>
								<strong>{"Do you want to suspend this pn medicine plan?"}</strong>
							</Card.Text>
							<div className="d-flex flex-row bd-highlight mb-3">
								<div className="p-2 bd-highlight">
									<button className='validi-theme-btn align-baseline'
										onClick={() => {
											if (pnId) {
												const params = new URLSearchParams();
												params.append('pn_id', pnId);
												params.append('medicine_id', medicineId);
												params.append('client_id', clientId);
												_this.props.suspendPnMedicineRequest(params);
												onClose();
												_this.setState({ activePnRecords: [] });
												const rows = _this.state.activePnRecords.filter(item => item.id !== pnId)
												_this.setState({ activePnRecords: rows });
											}
										}}
									> Ja</button>
								</div>
								<div className="p-2 bd-highlight"><button className='validi-theme-btn align-middle'
									onClick={() => {
										onClose();
									}}>Ingen</button></div>
							</div>
						</Card.Body>
					</Card>
				);
			},
			overlayClassName: "overlay-custom"
		};
		confirmAlert(options);
	}

	render() {
		return (
			<div className="validi-main-layout validi-pn-plan-layout-main">
				<div>
					<div className="container">
						<div className="validi-back-btn-wrapper">
							<Link to={routes.MEDICINE_PLAN_ROUTE}>
								<i>
									<img src={backArrow} />
								</i>
								<Translate id="medicine.back-to-medicine-plan" />
							</Link>
						</div>
						<h1 className="main-title"><Translate id="medicine.pn-plan" /></h1>
						<div className="validi-medicine-wrapper">
							<div className="validi-inner-header-bar">
								<div className="validi-avatar-details-wrepper">
									<div className="validi-avatar-img">
										{this.state.clientPhoto ? <img src={this.state.clientPhoto} alt="" /> : ""}
									</div>
									<div className="validi-avatar-details">
										<h5 className="validi-user-main-title">{this.state.clientName ? this.state.clientName : ""}</h5>
										<p className="validi-paragraph"><Translate id="client-goal.cpr-number" /> : {this.state.clientCPR ? this.state.clientCPR : ""}</p>
									</div>
								</div>
								<div className="validi-select-input">

								</div>
							</div>
							<div className="validi-tabbar-wrapper">
								<Tabs id="pn-tabs" defaultActiveKey={this.state.pnActiveTab} activeKey={this.state.pnActiveTab} onSelect={(id) => { this.activeTab(id) }}>
									<Tab eventKey="active-pn" title={<Translate id="medicine.pn-plan" />}>
										<div className="">
											<div className="validi-tabbar-inner-header">
												<div className="">
													<h4 className="validi-subtitle"><Translate id="medicine.pn-plan" /></h4>
												</div>
											</div>
											<div className="validi-table-data-wrapper">
												<div className='table-responsive-md'>
													<ReactDatatable
														key="detox"
														className="table"
														dynamic={true}
														config={webConst.dataTableConfig("id")}
														records={this.state.activePnRecords}
														columns={this.state.activePnColumns}
														total_record={this.state.activePnTotalRecord ? this.state.activePnTotalRecord : 0}
														onChange={data => { this.tableChangeHandler(data) }}
														loading={this.state.isPnLoading}
													/>
												</div>
											</div>
										</div>
									</Tab>
									<Tab eventKey="add-pn" title={<Translate id="medicine.add-pn-plan" />}>
										<AddPN />
									</Tab>
									<Tab eventKey="pn-dispense-history" title="PN Dispense histories">
										<PNDispenseHistory />
									</Tab>
									<Tab eventKey="pn-suspended" title="Suspended PN plan">
										<SuspendedPN />
									</Tab>
								</Tabs>
							</div>
						</div>
						<Cube customLoading={this.state.isPnLoading} color={'#66BB6A'} background="rgba(255,255,255,0.6)" />
					</div>
				</div>
			</div>
		)
	}
}

PN.defaultProps = {
	clientId: '',
	pnActiveTab: "active-pn",
	pnType: "",
	pnSuccess: "",
	pnFail: "",
};

PN.propTypes = {
	clientId: PropTypes.any,
	pnActiveTab: PropTypes.any,
	pnType: PropTypes.any,
	pnSuccess: PropTypes.any,
	pnFail: PropTypes.any,
};

function mapStateToProps(state) {
	return {
		clientId: state.appLayout.clientId ? state.appLayout.clientId : "",
		pnActiveTab: state.pnPlan.pnActiveTab,
		pnSuccess: state.pnPlan.success,
		pnType: state.pnPlan.type,
		pnStatus: state.pnPlan.status
	};
}

const mapDispatchToProps = {
	getPnMedicinePlanRequest,
	setPnActiveTab,
	suspendPnMedicineRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(PN);

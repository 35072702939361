import React, { Component, createRef } from 'react';
import '../Journal/Journal.scss';
import { Tabs, Tab, FormGroup, Label, Input, Form } from 'react-bootstrap';
import * as webConst from '../../utils/WebConstant';
import * as routes from '../../utils/RouteConstant';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import daLocale from "date-fns/locale/da";
import PropTypes from 'prop-types';
import General from '../Setting/General';
import Advanced from '../Setting/Advance';
import Timezone from '../Setting/Timezone';
import Pagination from '../Setting/Pagination';
import StartRating from '../Setting/StartRating';
import Task from '../Setting/Task';
import DischargeReason from "../Setting/DischargeReason";
import ClientExpirationNotification from '../Setting/ClientExpirationNotification';
import AttendeeCalendar from '../Setting/AttendeeCalendar';
import LogoutTime from './LogoutTime/LogoutTime';

class Setting extends Component {

	constructor(props) {
		super(props);
		this.state = {
			'isLoading': true,
			'journals': [],
			'paged': 1,
			'isLoader': true,
			'clients': [],
			'journalTypes': [],
			'clientId': '',
			'JournalTypeId': '',
			'isMoreJournal': true,
			'journalContent': '',
			'startDate': '',
			'endDate': '',
			'form': [],
			'isFormLoading': false,
			'isJournalLoading': false,
			'validated': false,
			'journalForm': '',
			"filterTypeId": ""
		};

		this.journalForm = React.createRef();

	}

	
	render() {
		
		return (
			<div className="validi-main-layout ">
				<div className="container">
					<div>
						<h1 className="main-title"><Translate id="setting.title" /></h1>
						<div className="validi-medicine-wrapper">
							<div className="validi-inner-header-bar">
								<div className="validi-avatar-details-wrepper">
									<div className="validi-avatar-img">
										{this.state.clientPhoto ? <img src={this.state.clientPhoto} alt="" /> : ""}
									</div>
									<div className="validi-avatar-details">
										<h5 className="validi-user-main-title">{this.state.clientName ? this.state.clientName : ""}</h5>
										<p className="validi-paragraph"><Translate id="client-goal.cpr-number" /> : {this.state.clientCPR ? this.state.clientCPR : ""}</p>
									</div>
								</div>
							</div>
							<div className="validi-tabbar-wrapper">
								<Tabs id="controlled-tab-example">
									<Tab eventKey="general" title={<Translate id="setting.general" />}>
										<div className="validi-tabbar-inner-header">
											<h4 className="validi-subtitle"><Translate id="setting.general" /></h4>
										</div>
										<div className="row">
											<div className="col-md-12">
												<General/>
											</div>
										</div>
									</Tab>
									<Tab eventKey="advanced" title={<Translate id="setting.advanced" />}>
										<div className="">
											<div className="validi-tabbar-inner-header">
												<h4 className="validi-subtitle"><Translate id="setting.advanced" /></h4>
											</div>
											<div className="row">
												<div className="col-md-12">
													<Advanced/>
												</div>
											</div>
										</div>
									</Tab>
									<Tab eventKey="timezone" title={<Translate id="setting.timezone" />}>
										<div className="">
											<div className="validi-tabbar-inner-header">
												<h4 className="validi-subtitle"><Translate id="setting.timezone" /></h4>
											</div>
											<div className="row">
												<div className="col-md-12">
													<Timezone/>
												</div>
											</div>
										</div>
									</Tab>
									<Tab eventKey="pagination" title={<Translate id="setting.pagination" />}>
										<div className="">
											<div className="validi-tabbar-inner-header">
												<h4 className="validi-subtitle"><Translate id="setting.pagination" /></h4>
											</div>
											<div className="row">
												<div className="col-md-12">
													<Pagination/>
												</div>
											</div>
										</div>
									</Tab>
									<Tab eventKey="star-rating" title={<Translate id="setting.star-rating" />}>
										<div className="">
											<div className="validi-tabbar-inner-header">
												<h4 className="validi-subtitle"><Translate id="setting.star-rating" /></h4>
											</div>
											<div className="row">
												<div className="col-md-12">
													<StartRating/>
												</div>
											</div>
										</div>
									</Tab>
									<Tab eventKey="task" title={<Translate id="setting.task" />}>
										<div className="">
											<div className="validi-tabbar-inner-header">
												<h4 className="validi-subtitle"><Translate id="setting.task" /></h4>
											</div>
											<div className="row">
												<div className="col-md-12">
													<Task/>
												</div>
											</div>
										</div>
									</Tab>
									<Tab eventKey="discharge-reason" title={<Translate id="setting.discharge-reason" />}>
										<div className="">
											<div className="validi-tabbar-inner-header">
												<h4 className="validi-subtitle"><Translate id="setting.discharge-reason" /></h4>
											</div>
											<div className="row">
												<div className="col-md-12">
													<DischargeReason/>
												</div>
											</div>
										</div>
									</Tab>
									<Tab eventKey="client-expiration-notification" title={<Translate id="setting.client-expiration-notification" />}>
										<div className="">
											<div className="validi-tabbar-inner-header">
												<h4 className="validi-subtitle"><Translate id="setting.client-expiration-notification" /></h4>
											</div>
											<div className="row">
												<div className="col-md-12">
													<ClientExpirationNotification/>
												</div>
											</div>
										</div>
									</Tab>
									<Tab eventKey="clinic-attendee-calendar" title={<Translate id="setting.clinic-attendee-calendar" />}>
										<div className="">
											<div className="validi-tabbar-inner-header">
												<h4 className="validi-subtitle"><Translate id="setting.clinic-attendee-calendar" /></h4>
											</div>
											<div className="row">
												<div className="col-md-12">
													<AttendeeCalendar/>
												</div>
											</div>
										</div>
									</Tab>
									<Tab eventKey="logout-timelimit" title={<Translate id="setting.logout-timelimit" />}>
										<div className="">
											<div className="validi-tabbar-inner-header">
												<h4 className="validi-subtitle"><Translate id="setting.logout-timelimit" /></h4>
											</div>
											<div className="row">
												<div className="col-md-12">
													<LogoutTime/>
												</div>
											</div>
										</div>
									</Tab>
									<Tab eventKey="infoscreen" title={<Translate id="setting.infoscreen" />}>
										<div className="">
											<div className="validi-tabbar-inner-header">
												<h4 className="validi-subtitle"><Translate id="setting.infoscreen" /></h4>
											</div>
											<div className="row">
												<div className="col-md-12">
													infoscreen
												</div>
											</div>
										</div>
									</Tab>
									<Tab eventKey="contacting-reason" title={<Translate id="setting.contacting-reason" />}>
										<div className="">
											<div className="validi-tabbar-inner-header"> 
												<h4 className="validi-subtitle"><Translate id="setting.contacting-reason" /></h4>
											</div>
											<div className="row">
												<div className="col-md-12">
													contacting-reason
												</div>
											</div>
										</div>
									</Tab>
									<Tab eventKey="journal-reminder-day" title={<Translate id="setting.journal-reminder-day" />}>
										<div className="">
											<div className="validi-tabbar-inner-header"> 
												<h4 className="validi-subtitle"><Translate id="setting.journal-reminder-day" /></h4>
											</div>
											<div className="row">
												<div className="col-md-12">
													journal-reminder-day
												</div>
											</div>
										</div>
									</Tab>
									<Tab eventKey="default-view" title={<Translate id="setting.default-view" />}>
										<div className="">
											<div className="validi-tabbar-inner-header"> 
												<h4 className="validi-subtitle"><Translate id="setting.default-view" /></h4>
											</div>
											<div className="row">
												<div className="col-md-12">
													default-view
												</div>
											</div>
										</div>
									</Tab>
									<Tab eventKey="todo" title={<Translate id="setting.todo" />}>
										<div className="">
											<div className="validi-tabbar-inner-header"> 
												<h4 className="validi-subtitle"><Translate id="setting.todo" /></h4>
											</div>
											<div className="row">
												<div className="col-md-12">
													todo
												</div>
											</div>
										</div>
									</Tab>
									<Tab eventKey="plan-expiration-notification" title={<Translate id="setting.plan-expiration-notification" />}>
										<div className="">
											<div className="validi-tabbar-inner-header"> 
												<h4 className="validi-subtitle"><Translate id="setting.plan-expiration-notification" /></h4>
											</div>
											<div className="row">
												<div className="col-md-12">
													plan-expiration-notification
												</div>
											</div>
										</div>
									</Tab>
									<Tab eventKey="client-profile" title={<Translate id="setting.client-profile" />}>
										<div className="">
											<div className="validi-tabbar-inner-header"> 
												<h4 className="validi-subtitle"><Translate id="setting.client-profile" /></h4>
											</div>
											<div className="row">
												<div className="col-md-12">
													client-profile
												</div>
											</div>
										</div>
									</Tab>
									<Tab eventKey="external-client-form-link" title={<Translate id="setting.external-client-form-link" />}>
										<div className="">
											<div className="validi-tabbar-inner-header"> 
												<h4 className="validi-subtitle"><Translate id="setting.external-client-form-link" /></h4>
											</div>
											<div className="row">
												<div className="col-md-12">
													external-client-form-link
												</div>
											</div>
										</div>
									</Tab>
									<Tab eventKey="reminder-client" title={<Translate id="setting.reminder-client" />}>
										<div className="">
											<div className="validi-tabbar-inner-header"> 
												<h4 className="validi-subtitle"><Translate id="setting.reminder-client" /></h4>
											</div>
											<div className="row">
												<div className="col-md-12">
													reminder-client
												</div>
											</div>
										</div>
									</Tab>
									<Tab eventKey="urine-test" title={<Translate id="setting.urine-test" />}>
										<div className="">
											<div className="validi-tabbar-inner-header"> 
												<h4 className="validi-subtitle"><Translate id="setting.urine-test" /></h4>
											</div>
											<div className="row">
												<div className="col-md-12">
													urine-test
												</div>
											</div>
										</div>
									</Tab>
									<Tab eventKey="ip-restriction" title={<Translate id="setting.ip-restriction" />}>
										<div className="">
											<div className="validi-tabbar-inner-header"> 
												<h4 className="validi-subtitle"><Translate id="setting.ip-restriction" /></h4>
											</div>
											<div className="row">
												<div className="col-md-12">
													ip-restriction
												</div>
											</div>
										</div>
									</Tab>
									<Tab eventKey="trusted-device" title={<Translate id="setting.trusted-device" />}>
										<div className="">
											<div className="validi-tabbar-inner-header"> 
												<h4 className="validi-subtitle"><Translate id="setting.trusted-device" /></h4>
											</div>
											<div className="row">
												<div className="col-md-12">
													trusted-device
												</div>
											</div>
										</div>
									</Tab>
									<Tab eventKey="booking-appointment" title={<Translate id="setting.booking-appointment" />}>
										<div className="">
											<div className="validi-tabbar-inner-header"> 
												<h4 className="validi-subtitle"><Translate id="setting.booking-appointmente" /></h4>
											</div>
											<div className="row">
												<div className="col-md-12">
													booking-appointment
												</div>
											</div>
										</div>
									</Tab>
									<Tab eventKey="change-password" title={<Translate id="setting.change-password" />}>
										<div className="">
											<div className="validi-tabbar-inner-header"> 
												<h4 className="validi-subtitle"><Translate id="setting.change-password" /></h4>
											</div>
											<div className="row">
												<div className="col-md-12">
													change-password
												</div>
											</div>
										</div>
									</Tab>
									<Tab eventKey="client-answer-status" title={<Translate id="setting.client-answer-status" />}>
										<div className="">
											<div className="validi-tabbar-inner-header"> 
												<h4 className="validi-subtitle"><Translate id="setting.client-answer-status" /></h4>
											</div>
											<div className="row">
												<div className="col-md-12">
													client-answer-status
												</div>
											</div>
										</div>
									</Tab>
									<Tab eventKey="attendee-status-icon" title={<Translate id="setting.attendee-status-icon" />}>
										<div className="">
											<div className="validi-tabbar-inner-header"> 
												<h4 className="validi-subtitle"><Translate id="setting.attendee-status-icon" /></h4>
											</div>
											<div className="row">
												<div className="col-md-12">
													attendee-status-icon
												</div>
											</div>
										</div>
									</Tab>
									<Tab eventKey="client-feedback-sms" title={<Translate id="setting.client-feedback-sms" />}>
										<div className="">
											<div className="validi-tabbar-inner-header"> 
												<h4 className="validi-subtitle"><Translate id="setting.client-feedback-sms" /></h4>
											</div>
											<div className="row">
												<div className="col-md-12">
													client-feedback-sms
												</div>
											</div>
										</div>
									</Tab>
									<Tab eventKey="purchase-phone-number" title={<Translate id="setting.purchase-phone-number" />}>
										<div className="">
											<div className="validi-tabbar-inner-header"> 
												<h4 className="validi-subtitle"><Translate id="setting.purchase-phone-number" /></h4>
											</div>
											<div className="row">
												<div className="col-md-12">
													purchase-phone-number
												</div>
											</div>
										</div>
									</Tab>
									<Tab eventKey="cpr-validation-permission" title={<Translate id="setting.cpr-validation-permission" />}>
										<div className="">
											<div className="validi-tabbar-inner-header"> 
												<h4 className="validi-subtitle"><Translate id="setting.cpr-validation-permission" /></h4>
											</div>
											<div className="row">
												<div className="col-md-12">
												cpr-validation-permission
												</div>
											</div>
										</div>
									</Tab>
									<Tab eventKey="client-self-attendee" title={<Translate id="setting.client-self-attendee" />}>
										<div className="">
											<div className="validi-tabbar-inner-header"> 
												<h4 className="validi-subtitle"><Translate id="setting.client-self-attendee" /></h4>
											</div>
											<div className="row">
												<div className="col-md-12">
													client-self-attendee
												</div>
											</div>
										</div>
									</Tab>
									<Tab eventKey="goal-topic" title={<Translate id="setting.goal-topic" />}>
										<div className="">
											<div className="validi-tabbar-inner-header"> 
												<h4 className="validi-subtitle"><Translate id="setting.goal-topic" /></h4>
											</div>
											<div className="row">
												<div className="col-md-12">
													goal-topic
												</div>
											</div>
										</div>
									</Tab>
									<Tab eventKey="client-document-folder-structure" title={<Translate id="setting.client-document-folder-structure" />}>
										<div className="">
											<div className="validi-tabbar-inner-header"> 
												<h4 className="validi-subtitle"><Translate id="setting.client-document-folder-structure" /></h4>
											</div>
											<div className="row">
												<div className="col-md-12">
													client-document-folder-structure
												</div>
											</div>
										</div>
									</Tab>
									<Tab eventKey="client-feedback" title={<Translate id="setting.client-feedback" />}>
										<div className="">
											<div className="validi-tabbar-inner-header"> 
												<h4 className="validi-subtitle"><Translate id="setting.client-feedback" /></h4>
											</div>
											<div className="row">
												<div className="col-md-12">
													client-feedback
												</div>
											</div>
										</div>
									</Tab>
									<Tab eventKey="journal-tag" title={<Translate id="setting.journal-tag" />}>
										<div className="">
											<div className="validi-tabbar-inner-header"> 
												<h4 className="validi-subtitle"><Translate id="setting.journal-tag" /></h4>
											</div>
											<div className="row">
												<div className="col-md-12">
													journal-tag
												</div>
											</div>
										</div>
									</Tab>
									<Tab eventKey="logo-and-clinic-title" title={<Translate id="setting.logo-and-clinic-title" />}>
										<div className="">
											<div className="validi-tabbar-inner-header"> 
												<h4 className="validi-subtitle"><Translate id="setting.logo-and-clinic-title" /></h4>
											</div>
											<div className="row">
												<div className="col-md-12">
													logo-and-clinic-title
												</div>
											</div>
										</div>
									</Tab>
									<Tab eventKey="meeting" title={<Translate id="setting.meeting" />}>
										<div className="">
											<div className="validi-tabbar-inner-header"> 
												<h4 className="validi-subtitle"><Translate id="setting.meeting" /></h4>
											</div>
											<div className="row">
												<div className="col-md-12">
													meeting
												</div>
											</div>
										</div>
									</Tab>
								</Tabs>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		personalJournalSuccess: state.personalJournal.success,
		personalJournalFail: state.personalJournal.fail,
		personalJournalStatus: state.personalJournal.status,
		addPersonalJournalSuccess: state.addPersonalJournal.success,
		addPersonalJournalFail: state.addPersonalJournal.fail,
		addPersonalJournalStatus: state.addPersonalJournal.status,
		clientId: state.appLayout.clientId ? state.appLayout.clientId : ""
	};
}

const mapDispatchToProps = {  };

export default connect(mapStateToProps, mapDispatchToProps)(Setting);
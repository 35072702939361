import React, { Component } from 'react';
import homeIcon from "../../../images/home.svg";
import journalIcon from "../../../images/journal.svg";
import medicineIcon from "../../../images/medicine.svg";
import messageIcon from "../../../images/message.svg";
import goalIcon from "../../../images/goal.svg";
import './Footer.scss';
import * as webConst from '../../../utils/WebConstant';
import { Translate } from 'react-localize-redux';
import * as routes from '../../../utils/RouteConstant';
import { BrowserRouter as Router, Link, BrowserRouter, Switch,NavLink } from 'react-router-dom'
import { Redirect } from 'react-router-dom';

class Footer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loggedIn: webConst.getUserToken() ? true : false,
			user: webConst.getUser() ? webConst.getUser() : {},
		};
	}
	setPage = (page) => {
		console.log("page", page);
		<Redirect to={page}></Redirect>
	}
	render() {
		return (
			<div className="validi-footer-bar-main">
				<div className="container">
					{this.state.loggedIn ?
						<div className="validi-footer-iconbar-main">
							<div className="validi-footer-icon">
								<Link to={routes.HOME_ROUTE} className="validi-footer-icon-list">
									<i>
										<img src={homeIcon} />
									</i>
									<Translate id="menu.home" />
								</Link>
							</div>
							<div className="validi-footer-icon">
								<Link to={routes.GOAL_ROUTE} className="validi-footer-icon-list">
									<i>
										<img src={goalIcon} />
									</i>
									<Translate id="menu.goal" />
								</Link>
							</div>
							<div className="validi-footer-icon">
								<Link to={routes.JOURNAL_ROUTE} className="validi-footer-icon-list">
									<i>
										<img src={journalIcon} />
									</i>
									<Translate id="menu.journal" />
								</Link>
							</div>
							<div className="validi-footer-icon">
								<Link to={routes.MEDICINE_PLAN_ROUTE} className="validi-footer-icon-list">
									<i>
										<img src={medicineIcon} />
									</i>
									<Translate id="menu.medicine" />
								</Link>
							</div>
							<div className="validi-footer-icon">
								<Link to={routes.MESSAGE_ROUTE} className="validi-footer-icon-list">
									<i>
										<img src={messageIcon} />
									</i>
									<Translate id="menu.message" />
								</Link>
							</div>
						</div>
						: ""
					}
				</div>
			</div>
		);
	}
}

export default Footer;
import React, { Component, Fragment } from 'react';
import './PNDispenseHistory.scss';
import { FormGroup, Label, Input, Form, InputGroup, FormControl, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as routes from '../../../../utils/RouteConstant';
import backArrow from '../../../../images/back-arrow.svg';
import { Translate } from 'react-localize-redux';
import * as webConst from '../../../../utils/WebConstant';
import { getPnDispenseHistoryRequest } from '../../../../containers/Medicines/Pn/PnPlanContainer/action';
import { PN_DISPENSE_HISTORY_SUCCESS } from '../../../../containers/Medicines/Pn/PnPlanContainer/Constants';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import daLocale from "date-fns/locale/da";
import moment from "moment";
import ReactDatatable from '@mkikets/react-datatable';


class PNDispenseHistory extends Component {
	constructor(props) {
		super(props);
		this.state = {
			clientId: "",
			clientName: "",
			clientCPR: "",
			clientPhoto: "",
			pnId: "",
			isLoading: false,
			validated: false,
			dispenseForm: "",
			medicineId: "",
			dispenseDetail: {},
			totalStrength: 0,
			dosageAmount: 0,
			isFormLoading: false,
			isPnLoading: false,
			activePnTotalRecord: 0,
			activePnRecords: [],
			activePnColumns: this.activePnDatatable(),
			isLoadingDatatable:false
		};
	}

	componentDidMount() {
		let _this = this;
		let client = webConst.getClient();
		let clientId = client && typeof client !== 'undefined' && typeof client.client_id !== 'undefined' ? client.client_id : 0;
		if (clientId > 0) {
			let clientName = client && typeof client !== 'undefined' && typeof client.client_name !== 'undefined' ? client.client_name : 0;
			let cpr = client && typeof client !== 'undefined' && typeof client.client_socialid !== 'undefined' ? client.client_socialid : 0;
			let photo = client && typeof client !== 'undefined' && typeof client.photo !== 'undefined' ? client.photo : 0;
			this.setState({ clientId: clientId, clientName: clientName, clientCPR: cpr, clientPhoto: photo });
		}
		else {
			webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.select-client-message" />);
			this.props.history.push(routes.HOME_ROUTE);
		}
	}

	componentDidUpdate(prevProps) {
		const { pnSuccess, pnStatus, pnType, pnActiveTab } = this.props;
		
		if (prevProps.pnActiveTab !== "" && prevProps.pnActiveTab !== "pn-dispense-history" && pnActiveTab === "pn-dispense-history") {			
			let params = new URLSearchParams();
			params.append('clinic_id', webConst.getClinicId());
			params.append('client_id', webConst.getClientId());
			params.append('user_id', webConst.getUserId());
			this.getActivePnData(params);
		}

		if (pnStatus === 200) {
			if (pnType === PN_DISPENSE_HISTORY_SUCCESS) {
				if (prevProps.pnSuccess !== pnSuccess) {
					this.setState({
						isPnLoading: false
					});
					if (pnSuccess.records && pnSuccess.records.length) {
						this.setState({ activePnRecords: pnSuccess.records, activePnTotalRecord: pnSuccess.total_records });
					}
				}
			}
		}

		if (pnStatus === 400) {
			if (this.state.isPnLoading) {
				this.setState({
					isPnLoading: false,
					isFormLoading: false
				});
			}

			if (this.state.activePnRecords.length > 0) {
				this.setState({ activePnRecords: [], activePnTotalRecord: 0 });
			}
		}
	}

	getActivePnData = (params) => {
		if (this.state.isPnLoading !== true)
			this.setState({ isPnLoading: true });
		this.props.getPnDispenseHistoryRequest(params);
	}
	activePnDatatable = function () {
		let columns = [
			{
				key: "dispense_date",
				text: "Dispense Date",
				align: "left",
				className: "validi-date-text",
				sortable: true,
				cell: record => {
					return (
						<Fragment key={"dispense_date-" + record.id}>
							{moment(record.dispense_date).format("DD-MM-YYYY")}
						</Fragment>
					);
				}
			},
			{
				key: "dispense_datetime",
				text: "Dispense time",
				align: "left",
				className: "validi-date-text",
				sortable: true,
				cell: record => {
					return (
						<Fragment key={"dispense_datetime-" + record.id}>
							{moment(record.dispense_datetime).format("DD-MM-YYYY HH:mm:ss")}
						</Fragment>
					);
				}
			},
			{
				key: "start_date",
				text: "Start Date",
				align: "left",
				className: "validi-date-text",
				sortable: true,
				cell: record => {
					return (
						<Fragment key={"dispense_start_date-" + record.id}>
							{moment(record.start_date).format("DD-MM-YYYY")}
						</Fragment>
					);
				}
			},
			{
				key: "medicine_name",
				text: "Medicine name",
				align: "left",
				className: "validi-date-text",
				sortable: true,
				cell: record => {
					return (
						<Fragment key={"dispense_medicine_name-" + record.id}>
							{record.medicine_name}
						</Fragment>
					);
				}
			},
			{
				key: "total_disp_amount",
				text: "Dispense dosage",
				align: "left",
				sortable: true,
				className: 'validi-mg-text',
				cell: record => {
					return (
						<Fragment key={"total_disp_amount-" + record.id}>
							{record.total_disp_amount} {webConst.ucfirst(record.unit.replace(/[^a-zA-Z\/]+/g, ''))}
						</Fragment>
					);
				}
			},
			{
				key: "dispense_by",
				key_column: "dispense_by",
				text: "Dispense by",
				align: "left",
				className: 'text-capitalize',
				sortable: true
			},
			{
				key: "remark",
				key_column: "remark",
				text: "Note",
				align: "left",
				className: 'validi-date-text',
				sortable: true
			}
		];
		return columns;
	}

	tableChangeHandler = (data) => {
		const params = new URLSearchParams();
		params.append('clinic_id', webConst.getClinicId());
		params.append('client_id', webConst.getClientId());
		params.append('user_id', webConst.getUserId());
		Object.keys(data).map((key) => {
			if (key === "sort_order") {
				if (data[key]) {
					params.append("sort_order", data[key].order !== "false" ? data[key].order : "");
					params.append("sort_column", data[key].column);
				}
			} else {
				params.append(key, data[key]);
			}
		});
		this.getActivePnData(params);
	}

	render() {
		return (
			<div className="">
				<div className="validi-tabbar-inner-header">
					<div className="">
						<h4 className="validi-subtitle">PN Dispense histories</h4>
					</div>
					<div className="validi-table-filter-box">

					</div>
				</div>
				<div className="validi-table-data-wrapper">
					<ReactDatatable
						key="pn-dispense"
						className="table"
						dynamic={true}
						config={webConst.dataTableConfig("pn_dispense_id")}
						records={this.state.activePnRecords}
						columns={this.state.activePnColumns}
						total_record={this.state.activePnTotalRecord ? this.state.activePnTotalRecord : 0}
						onChange={data => { this.tableChangeHandler(data) }}
						loading={this.state.isPnLoading}
					/>
				</div>
			</div>
		)
	}
}


PNDispenseHistory.defaultProps = {
	clientId: '',
	pnActiveTab: "",
	pnType: "",
	pnSuccess: "",
	pnFail: "",
	pnStatus: ""
};

PNDispenseHistory.propTypes = {
	clientId: PropTypes.any,
	pnActiveTab: PropTypes.any,
	pnType: PropTypes.any,
	pnSuccess: PropTypes.any,
	pnFail: PropTypes.any,
};

function mapStateToProps(state) {
	return {
		clientId: state.appLayout.clientId ? state.appLayout.clientId : "",
		pnActiveTab: state.pnPlan.pnActiveTab,
		pnSuccess: state.pnPlan.success,
		pnFail: state.pnPlan.fail,
		pnType: state.pnPlan.type,
		pnStatus: state.pnPlan.status
	};
}

const mapDispatchToProps = {
	getPnDispenseHistoryRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(PNDispenseHistory);

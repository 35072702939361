import { ADD_DETOX_PLAN_SUCCESS, ADD_DETOX_PLAN_FAIL} from './Constants';
import axios from 'axios';
import { withHeader } from '../../../../service/ApiConfig';
import * as webConst from '../../../../utils/WebConstant';
const axiosDefaults = require('axios/lib/defaults');
axiosDefaults.baseURL = withHeader.baseURL;
axiosDefaults.headers = withHeader.headers;
axiosDefaults.timeout = withHeader.timeOut;

export const addDetoxPlanRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.ADD_DETOX_PLAN}`, value)
            .then((response) => {
                if (response.data.responseCode === 200) {
                    dispatch(detoxPlanSuccessResult(response.data.responseBody, response.status));
                }
                else {
                    dispatch(detoxPlanFailResult(response.data.responseMessage, response.data.responseCode));
                }
            })
            .catch((error) => {
                dispatch(detoxPlanFailResult(error.data, error.status));
            });
    }
};

/*
    API RESULT
 */

export const detoxPlanSuccessResult = (success, status) => ({
    type: ADD_DETOX_PLAN_SUCCESS,
    success,
    status
});

export const detoxPlanFailResult = (error, status) => ({
    type: ADD_DETOX_PLAN_FAIL,
    error,
    status
});
import { MEDICINE_STOCK_SUCCESS, MEDICINE_STOCK_FAIL,ADD_MEDICINE_STOCK_SUCCESS,ADD_MEDICINE_STOCK_FAIL } from './Constants';

const initialState = {
  isLoading: true,
  stockSuccess:[],
  stockFail:"",
  status:"",
  addStockSuccess:[],
  addStockFail:"",
  addStockStatus:""
};

export default function stockReducer(state = initialState, action) {
  switch (action.type) {
    case MEDICINE_STOCK_SUCCESS:
      return {
        ...state,
        stockSuccess: action.success,
        stockFail: '',
        status: action.status,
        addStockSuccess:[],
        addStockFail:'',
        addStockStatus:""
      };
    case MEDICINE_STOCK_FAIL:
      return {
        ...state,
        stockSuccess: [],
        stockFail: action.error,
        status: action.status,
        addStockSuccess:[],
        addStockFail:'',
        addStockStatus:""
      };
    case ADD_MEDICINE_STOCK_SUCCESS:
      return {
        ...state,
        stockSuccess:[],
        stockFail:'',
        status:"",
        addStockSuccess: action.success,
        addStockFail: '',
        addStockStatus: action.status
      };
    case ADD_MEDICINE_STOCK_FAIL:
      return {
        ...state,
        stockSuccess:[],
        stockFail:'',
        status:"",
        addStockSuccess: [],
        addStockFail: action.error,
        addStockStatus: action.status
      };
    default:
      return state;
  }
}
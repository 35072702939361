import React, { Component } from 'react';
import './AppLayout.scss';
import Header from './Header';
import Footer from './Footer';
import Home from '../Home';
import { getAuthStatus } from '../../containers/AppLayoutContainer/action';
import { connect } from 'react-redux';
import { getClientListRequest } from '../../containers/ClientContainer/action';
import * as webConst from '../../utils/WebConstant';
import * as routes from '../../utils/RouteConstant';
import { BrowserRouter as Router, Route, Switch, Redirect, Link, NavLink } from "react-router-dom";


class AppLayout extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loggedIn: props.getAuthStatus(),
		};
	}

	componentDidMount() {
		let _this = this;
		// setTimeout(() => {
		// 	if (this.state.loggedIn)
		// 		_this.getClient();
		// 	else {
		// 		window.href = routes.LOGIN_ROUTE;
		// 		// <Redirect to={"login"}/>
		// 	}
		// }, 500);
	}

	componentDidUpdate = prevProps => {
		const { loggedIn } = this.props;
		if (loggedIn) {
			if (prevProps.loggedIn !== this.props.loggedIn) {
				this.setState({ 'loggedIn': this.props.loggedIn });
			}
		}
	}

	async getClient(event) {
		// if(!this.state.loggedIn)
		//   return false;

		let clientType = "mine";
		if (event) {
			clientType = event.target.value;
		}

		this.setState({ 'clientType': clientType });
		const params = new URLSearchParams();
		params.append('user_id', webConst.getUserId());
		params.append('clinic_id', webConst.getClinicId());
		params.append('type', clientType);
		this.props.getClientListRequest(params);
	}

	render() {
		return (
			<div className="app-layout">
				<Router>
					{/* <Switch> */}
						<Header isLoggedIn={this.state.loggedIn} />
						<Footer />
					{/* </Switch> */}
				</Router>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		loggedIn: state.appLayout.loggedIn
	};
}

const mapDispatchToProps = { getAuthStatus, getClientListRequest };

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);
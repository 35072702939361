import { AUTH_STATUS, CLIENT_ID } from './constants';

const initialState = {
  loggedIn: false,
  clientId:""
};

export default function appLayoutReducer(state = initialState, action) {
  switch (action.type) {
    case AUTH_STATUS:
      return Object.assign({}, state, {
        loggedIn: action.loggedIn
      });
    case CLIENT_ID:
        return Object.assign({}, state, {
          clientId: action.clientId
        });
    default:
      return state;
  }
}
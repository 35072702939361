import { LOGOUT_SUCCESS, LOGOUT_FAIL } from './constants';

const initialState = {
    loginStatus: false,
    status:''
};

export default function logoutReducer(state = initialState, action) {
    switch (action.type) {
        case LOGOUT_SUCCESS:
            return {
                ...state,
                loginStatus: action.success,
                status: action.status
            };
        case LOGOUT_FAIL:
            return {
                ...state,
                loginStatus: action.error,
                status: action.status
            };
        default:
            return state;
    }
}

import React, { Component, Fragment } from 'react';
import './Home.scss';
import { Link, NavLink } from "react-router-dom";
import { connect } from 'react-redux';
import { getClientListRequest, getClientTooltipRequest } from '../../containers/ClientContainer/action';
import { setClientId } from '../../containers/AppLayoutContainer/action';
import * as routes from '../../utils/RouteConstant';
import * as webConst from '../../utils/WebConstant';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { Cube } from 'react-preloaders2';
import Table from 'react-bootstrap/Table';
import homeIcon from "../../images/home.svg";
import journalIcon from "../../images/journal.svg";
import medicineIcon from "../../images/medicine.svg";
import messageIcon from "../../images/message.svg";
import goalIcon from "../../images/goal.svg";
// import ReactDatatable from '@mkikets/react-datatable';
import { OverlayTrigger, Overlay, Tooltip, Button, Popover, Modal } from 'react-bootstrap';
import { CLIENT_SUCCESS, CLIENT_TOOLTIP_SUCCESS } from '../../containers/ClientContainer/Constants';
import InfiniteScroll from 'react-infinite-scroll-component';

class Home extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			clientType: 'mine',
			clientId: '',
			photoUrl: '',
			defaultPhoto: '',
			totalRecord: 0,
			columns: this.clientDatatable(),
			isDatatableLoading: true,
			hoverClientId: 0,
			tooltips: {},
			clientDetail: "",
			isModal: false,
			className: "",
			clientPhoto: "",
			pageSize: 14,
			pageNumber: 1,
			isMoreRecord: true,
			clients: []
		};
		this.getClient = this.getClient.bind(this);
		this.setClient = this.setClient.bind(this);
		this.popoverRef = React.createRef();
		this.popoverClientRef = React.createRef();
	}


	componentDidMount() {
		this.getClient();
	}

	componentDidUpdate(prevProps) {
		const { homeSuccess, homeFail, homeStatus, clientId, homeType } = this.props;
		if (this.state.isDatatableLoading) {
			this.setState({
				isLoading: false,
				isDatatableLoading: false
			});
		}
		if (homeStatus === 200) {

			if (prevProps.homeSuccess !== homeSuccess) {
				if (homeType == CLIENT_SUCCESS) {
					if (this.props.homeSuccess && this.props.homeSuccess.client_list) {
						let total = this.props.homeSuccess.total_records;
						this.setState({ clients: [...this.state.clients, ...homeSuccess.client_list] });
						this.setState({ totalRecord: total, defaultPhoto: this.props.homeSuccess.default_photo, photoUrl: this.props.homeSuccess.client_photo_url, pageNumber: this.state.pageNumber + 1, isMoreRecord: true });
					}
				}
				if (homeType == CLIENT_TOOLTIP_SUCCESS) {
					if (this.props.homeSuccess) {
						this.setState({ tooltips: this.props.homeSuccess});
					}
				}
			}
		}
		if (prevProps.homeFail !== homeFail) {
			if (this.state.isDatatableLoading) {
				this.setState({
					isLoading: false,
					isDatatableLoading: false,
					isMoreRecord: false
				});
			}
		}
	}

	getTooltipDetail = (item) => {
		this.setState({ className: webConst.ucfirst(item.client_name) });
		const params = new URLSearchParams();
		params.append('clinic_id', webConst.getClinicId());
		params.append('client_id', item.client_id);
		this.props.getClientTooltipRequest(params);
		this.setState({ tooltips: "" });
		var activeClient = {
			"client_id": item.client_id,
			"client_name": webConst.ucfirst(item.client_name),
			"client_socialid": item.client_socialid,
			"client_type": item.client_type,
			"photo": this.state.defaultPhoto
		};
		if (this.state.photoUrl && item.photo) {
			activeClient.photo = this.state.photoUrl + item.client_id + '/album/' + item.photo;
			this.setState({ clientPhoto: activeClient.photo });
		} else {
			this.setState({ clientPhoto: "" });
		}
		webConst.setClient(activeClient);
	}
	drawTooltip = () => {
		let data = [];
		let _this = this;
		//table data
		const tableData = (data) => {
			return <table className="table"><tbody>{data}</tbody></table>
		}
		// get table row data
		const tdData = (label, data) => {
			return <tr key={"tooltip-" + Math.random(1, 1000)}><th>{label}</th><td key={data}>{data}</td></tr>
		}

		if (this.state.tooltips) {
			for (var x in this.state.tooltips) {
				let item = this.state.tooltips[x];
				let table = [];
				if (!["relatives", "extra_fields", "partners", "photo"].includes(x)) {
					data.push(tdData(<Translate id={"home." + x} />, item));
				} else {
					if (x == "partners") {
						data.push(<tr key={"tooltip" + Math.random(1, 1000)}><td colSpan={2}><h5><Translate id="home.business-partners" /></h5></td></tr>);
						item.map((bs, k) => {
							let table = "";
							var count = 0;
							for (var b in bs) {
								count++;
								if (count == 1 || Object.values(bs).length == count)
									table += webConst.ucfirst(bs[b]);
								else if (bs[b])
									table += " - " + webConst.ucfirst(bs[b]);
							}
							data.push(<tr key={"tooltip" + Math.random(1, 1000)}><td colSpan={2}>{table}</td></tr>);
						});
					}
					if (x == "relatives") {
						data.push(<tr key={"tooltip" + Math.random(1, 1000)}><td colSpan={2}><h5><Translate id="home.relatives" /></h5></td></tr>);
						let table = "";
						var count = 0;
						Object.keys(item).map((bs, k) => {
							count++;
							if (count == 1 || Object.values(item).length == count)
								table += webConst.ucfirst(item[bs][0]);
							else if (item[bs][0])
								table += " - " + webConst.ucfirst(item[bs][0]);
						});
						data.push(<tr key={"tooltip" + Math.random(1, 1000)}><td colSpan={2}>{table}</td></tr>);
					}

					if (x == "extra_fields") {
						data.push(<tr key={"tooltip" + Math.random(1, 1000)}><td colSpan={2}><h5><Translate id="home.extra_fields" /></h5></td></tr>);
						Object.keys(item).map((bs, k) => {
							data.push(<tr key={"tooltip" + Math.random(1, 1000)}><th>{item[bs]["label"]}</th><td>{item[bs]["value"]}</td></tr>);
						});
					}
				}
			}
		}
		return data;
	}
	clientDatatable = () => {
		let _this = this;
		let columns = [
			{
				key: "client_name",
				text: "",
				align: "left",
				className: "p-0 m-0 text-capitalize",
				sortable: true,
				cell: item => {
					return this.clientRow(item);
				}
			}
		];
		return columns;
	}

	setClient = (client, type = routes.GOAL_ROUTE) => {
		if (this.state.clientId !== client.client_id) {
			this.setState({ 'clientId': client.client_id, isDatatableLoading: true, isLoading: true });
			this.props.setClientId(client.client_id);
			var activeClient = {
				"client_id": client.client_id,
				"client_name": webConst.ucfirst(client.client_name),
				"client_socialid": client.client_socialid,
				"client_type": client.client_type,
				"photo": this.state.defaultPhoto
			};
			if (this.state.photoUrl && client.photo) {
				activeClient.photo = this.state.photoUrl + client.client_id + '/album/' + client.photo;
			}
			webConst.setClient(activeClient);
			if (client.client_id > 0) {
				let _this = this;
				setTimeout(() => {
					_this.props.history.push(type);
				}, 100);
			}
		}
	}

	async getClient(event) {
		let clientType = "mine";
		// if (event) {
		// 	clientType = event.target.value;
		// 	this.setState({ clientType: clientType });
		// }
		if (!this.state.isDatatableLoading) {
			this.setState({ 'isDatatableLoading': true, 'isLoading': true });
			this.setState({ 'clientType': this.state.clientType });
		}
		const params = new URLSearchParams();
		params.append('user_id', webConst.getUserId());
		params.append('clinic_id', webConst.getClinicId());
		params.append('page_number', this.state.pageNumber);
		params.append('page_size', this.state.pageSize);
		console.log("get client");
		this.props.getClientListRequest(params);
	}

	closeModal = () => {
		this.setState({ isModal: false });
	}

	clientRow = (item) => {
		let _this = this;
		let data = "";
		if (this.state.tooltips && typeof this.state.tooltips != "undefined") {
			data = this.drawTooltip();
		}
				
		const clientDetailPopover = (
			<Popover id="popover-trigger-hover-focus">
				<Popover.Header as="h3">{webConst.ucfirst(item.client_name)}</Popover.Header>
				<Popover.Body>
					{(_this.state.photoUrl && item.photo) ? <img src={_this.state.photoUrl + item.client_id + '/album/' + item.photo} className='rounded' height={"100px"} /> : <img src={_this.state.defaultPhoto} className='rounded' height={"100px"} />}
					<Table className='table'>
						<tbody>
							{data}
						</tbody>
					</Table>
				</Popover.Body>
			</Popover>
		);
		return (
			<div className='row mt-2'>
				<div className='col-md-6 pl-2'>
					<div className="d-lg-none">
						<Link to="#" className='text-capitalize' onClick={e => { this.getTooltipDetail(item); this.setState({ isModal: true }) }} >{item.client_name}</Link>
					</div>
					<div className="d-none d-lg-block">
						<OverlayTrigger trigger={["hover", "focus"]} placement="auto" overlay={clientDetailPopover} delay={500} onEntered={() => { this.getTooltipDetail(item) }}>
							<Link to="#" className='text-capitalize' onClick={e => { this.setClient(item, routes.GOAL_ROUTE) }} >{item.client_name}</Link>
						</OverlayTrigger>
					</div>
					<div className='cpr-number'>
						<i className="glyphicon glyphicon-tag"></i>
						<Translate id="home.cpr-number" /> : {item.client_socialid}
					</div>
				</div>
				<div className='col-md-6  d-flex justify-content-sm-start justify-content-md-end d-flex justify-content-lg-end'>
					<div className="d-flex justify-content-end">
						<span className='p-1'>
							<Link to="#" onClick={e => { this.setClient(item, routes.GOAL_ROUTE) }} className='btn btn-sm btn-outline-secondary'>
								<img src={goalIcon} height={"20"} alt="goal" />
							</Link>
						</span>
						<span className='p-1'>
							<Link to="#" onClick={e => { this.setClient(item, routes.JOURNAL_ROUTE) }} className='btn btn-sm btn-outline-secondary'>
								<img src={journalIcon} height={"20"} alt="journal" />
							</Link>
						</span>
						<span className='p-1'>
							<Link to="#" onClick={e => { this.setClient(item, routes.MEDICINE_PLAN_ROUTE) }} className='btn btn-sm btn-outline-secondary'>
								<img src={medicineIcon} height={"20"} alt="medicine" />
							</Link>
						</span>
						<span className='p-1'>
							<Link to="#" onClick={e => { this.setClient(item, routes.MESSAGE_ROUTE) }} className='btn btn-sm btn-outline-secondary'>
								<img src={messageIcon} height={"20"} alt="message" />
							</Link>
						</span>
					</div>
				</div>
			</div>
		);
	}

	render() {
		// let _this = this;
		const { homeSuccess } = this.props;
		let _this = this;

		return (
			<div className="validi-main-layout validi-clinets-layout">
				<div className="container">
					<div className="validi-our-clinets-details">
						{/* <h1 className="main-title"><Translate id="home.title" /></h1> */}
						<div className="validi-table-data-wrapper1">
							<div>
								<InfiniteScroll
									dataLength={this.state.clients && this.state.clients.length ? this.state.clients.length : 0}
									next={this.getClient}
									hasMore={true}
									loader={this.state.isDatatableLoading ? <h4>Loading...</h4> : ""}
								>
									{this.state.clients.map((client, index) => (
										<div key={index} className='border-bottom'>
											{this.clientRow(client)}
										</div>
									))}
								</InfiniteScroll>

								<Modal show={this.state.isModal} fullscreen={"sm-down"} onHide={() => { this.closeModal() }}>
									<Modal.Header closeButton>
										<Modal.Title>{this.state.className}</Modal.Title>
									</Modal.Header>
									<Modal.Body>
										<Table striped>
											<tbody>
												<tr key={"tooltip-" + Math.random(1, 1000)}>
													<td colSpan={2}>
														{<img src={this.state.clientPhoto && this.state.clientPhoto != "" ? this.state.clientPhoto : this.state.defaultPhoto} height={"100px"} />}
													</td>
												</tr>
												{typeof this.state.tooltips != "undefined" ? this.drawTooltip() : ""}
											</tbody>
										</Table>
									</Modal.Body>
								</Modal>
							</div>
							<Cube customLoading={this.state.isLoading} color={'#66BB6A'} background="rgba(255,255,255,0.6)" />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

Home.defaultProps = {
	getClientListRequest: () => { },
	setClientId: () => { },

	homeStatus: '',
	homeSuccess: '',
	homeFail: ''
};

Home.propTypes = {
	getClientListRequest: PropTypes.func,
	setClientId: PropTypes.func,

	homeStatus: PropTypes.any,
	homeSuccess: PropTypes.any,
	homeFail: PropTypes.any,
};

function mapStateToProps(state) {
	return {
		homeSuccess: state.client.success,
		homeFail: state.client.fail,
		homeStatus: state.client.status,
		homeType: state.client.type,
		clientId: state.appLayout.clientId ? state.appLayout.clientId : ""
	};
}

const mapDispatchToProps = { getClientListRequest, setClientId, getClientTooltipRequest };


export default connect(mapStateToProps, mapDispatchToProps)(Home);

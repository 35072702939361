import React, { Component } from 'react';
import { Tabs, Tab, Table, FormGroup, Label, Form, InputGroup, FormControl } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './AddAlcohol.scss';
import * as routes from '../../../../utils/RouteConstant';
import backArrow from '../../../../images/back-arrow.svg';
import { Translate } from 'react-localize-redux';
import * as webConst from '../../../../utils/WebConstant';
import { getMedicinePlanDetailRequest, getMedicineTypeRequest, getMedicineStrengthRequest } from '../../../../containers/Medicines/MedicinePlanDetailContainer/action';
import { GET_MEDICINE_PLAN_DETAIL_SUCCESS } from '../../../../containers/Medicines/MedicinePlanDetailContainer/Constants';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import daLocale from "date-fns/locale/da";
import moment from 'moment';
import { addAlcoholMedicinePlanRequest } from '../../../../containers/Medicines/Alcohol/AlcoholPlanContainer/action';
import { ADD_ALCOHOL_PLAN_SUCCESS } from '../../../../containers/Medicines/Alcohol/AlcoholPlanContainer/Constants';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import 'react-bootstrap-typeahead/css/Typeahead.css';

class AddAlcohol extends Component {
	constructor(props) {
		super(props);
		this.state = {
			clientId: "",
			isLoading: false,
			validated: false,
			addAlcoholForm: "",
			medicineId: "",
			totalStrength: 0,
			dosageAmount: 0,
			isFormLoading: false,
			medicines: [],
			medicineTypes: [],
			medicineStrengths: [],
			startDate: "",
			medicineType: "Tablet",
			packageId: "",
			medicineTypeId: "",
			doctorType: "internal",
			internalDoctorId: "",
			externalDoctorId: "",
			internalDoctors: [],
			externalDoctors: [],
			isMedicineDetailLoaded: 0,
			medicineOptions: [],
			optionMedicineStrengthes: [],
			optionMedicineTypes: [],
			isOpenMedicineTypehead: false,
			isOpenTypehead: false,
			isOpenStrengthTypehead: false,
			isMedicineLoading: true,
			isMedicineTypeLoading: false,
			isMedicineStrengthLoading: false,
			selectedMedicine: {},
			selectedMedicineType: {},
			selectedMedicineStrength: {},
			medicineError: "",
			medicineTypeError: "",
			medicineStrengthError: ""
		};
		this.addAlcoholForm = React.createRef();
	}

	componentDidMount() {
		let _this = this;
		let clientId = webConst.getClientId();
		if (clientId > 0) {
			this.setState({ clientId: clientId, isMedicineDetailLoaded: 0 });
		}
		else {
			webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.select-client-message" />);
			this.props.history.push(routes.HOME_ROUTE);
		}
	}

	componentDidUpdate(prevProps) {
		let _this = this;
		const { medicinePlanDetailSuccess, medicinePlanDetailStatus, alcoholPlanStatus, alcoholPlanType, alcoholPlanSuccess, alcoholActiveTab, medicinePlanDetailType } = this.props;
		if (alcoholActiveTab === "add-alcohol" && this.state.isMedicineDetailLoaded === 0) {
			let params = new URLSearchParams();
			params.append('clinic_id', webConst.getClinicId());
			params.append('client_id', this.state.clientId);
			params.append('alcohol_id', _this.state.alcoholId);
			params.append('medicine_id', _this.state.medicineId);
			_this.props.getMedicinePlanDetailRequest(params);
			_this.setState({ isMedicineDetailLoaded: 1 });
		}

		if (medicinePlanDetailStatus === 200) {
			if (prevProps.medicinePlanDetailSuccess !== medicinePlanDetailSuccess) {
				this.setState({
					isLoading: false,
					isFormLoading: false,
					isUpdateMedicineType: false,
					isMedicineDetailLoaded: true
				});
				if (medicinePlanDetailSuccess.medicines && medicinePlanDetailSuccess.medicines.length) {
					let medicines = [];
					for (var index in medicinePlanDetailSuccess.medicines) {
						medicines.push({ value: medicinePlanDetailSuccess.medicines[index].id, name: webConst.ucfirst(medicinePlanDetailSuccess.medicines[index].name) });
					}
					this.setState({ medicines: medicines, isMedicineLoading: false, medicineError: "", medicineTypeError: "", medicineStrengthError: "" });
				}

				if (medicinePlanDetailSuccess.slot_timings && medicinePlanDetailSuccess.slot_timings.length) {
					this.setState({ slotTimes: medicinePlanDetailSuccess.slot_timings });
					let selectedSlots = {};
					let slotDosages = {};
					medicinePlanDetailSuccess.slot_timings.forEach(item => {
						if (!selectedSlots[item.slot_id])
							selectedSlots[item.slot_id] = false;

						if (!slotDosages[item.slot_id])
							slotDosages[item.slot_id] = 0;
					});

					let _this = this;
					setTimeout(() => {
						_this.setState({ selectedSlots: selectedSlots, slotDosages: slotDosages });
					}, 100);
				}

				if (medicinePlanDetailSuccess.internal_doctors && medicinePlanDetailSuccess.internal_doctors.length) {
					this.setState({ internalDoctors: medicinePlanDetailSuccess.internal_doctors });
				}
				if (medicinePlanDetailSuccess.external_doctors && medicinePlanDetailSuccess.external_doctors.length) {
					this.setState({ externalDoctors: medicinePlanDetailSuccess.external_doctors });
				}

				if (medicinePlanDetailSuccess.medicine_types && medicinePlanDetailSuccess.medicine_types.length) {
					let medicine_types = [];
					for (var index in medicinePlanDetailSuccess.medicine_types) {
						medicine_types.push({ value: medicinePlanDetailSuccess.medicine_types[index].id, name: webConst.ucfirst(medicinePlanDetailSuccess.medicine_types[index].medicine_type) });
					}
					this.setState({ medicineTypes: medicinePlanDetailSuccess.medicine_types, optionMedicineTypes: medicine_types, isMedicineTypeLoading: false, medicineTypeError: "", medicineStrengthError: "" });
				}
				if (medicinePlanDetailSuccess.medicine_strengths && medicinePlanDetailSuccess.medicine_strengths.length) {
					let medicineStrengthes = [];
					for (var index in medicinePlanDetailSuccess.medicine_strengths) {
						medicineStrengthes.push({ value: medicinePlanDetailSuccess.medicine_strengths[index].id, name: webConst.ucfirst(medicinePlanDetailSuccess.medicine_strengths[index].unit) });
					}
					this.setState({ medicineStrengthes: medicinePlanDetailSuccess.medicine_strengths, optionMedicineStrengthes: medicineStrengthes, isMedicineStrengthLoading: false, medicineStrengthError: "" });
				}
			}
		}

		if (medicinePlanDetailStatus === 400) {
			if (this.state.isLoading) {
				this.setState({
					isLoading: false,
					isFormLoading: false
				});
			}
		}

		if (alcoholPlanStatus === 200) {
			if (alcoholPlanType === ADD_ALCOHOL_PLAN_SUCCESS) {
				if (prevProps.alcoholPlanSuccess !== alcoholPlanSuccess) {
					if (this.state.isFormLoading) {
						this.setState({
							isFormLoading: false
						});
						this.resetForm();
						webConst.toaster(webConst.TOAST_SUCCESS, <Translate id="medicine.pn-save-message" />);
					}
				}
			}
		}

	}

	handleChange = (event) => {
		let _this = this;
		let name = event.target.name;
		let value = event.target.value;
		if (name == 'medicineId') {
			this.getMedicineType(value);
			_this.setState({ [name]: value, medicineType: "Tabletter" });
		}
		else if (name == 'medicineTypeId') {
			this.getMedicineStrength(value);
			_this.setState({ medicinePhoto: "", medicineUnit: "mg", medicineStrength: 0 });
			let medicineType = this.state.medicineTypes.filter((data) => {
				if (data.id == value) {
					_this.setState({ medicineType: webConst.ucfirst(data.medicine_type) });
				}
			});
			this.setState({ [name]: value });
		}
		else if (name == 'packageId') {
			let strengthUnit = this.state.medicineStrengths.filter((data) => {
				if (data.id == value) {
					_this.setState({ medicinePhoto: data.image, medicineUnit: data.unit, medicineStrength: data.unit_strength });
				}
			});
			this.totalStrength();
			this.setState({ [name]: value });
		} else {
			this.setState({ [name]: value });
		}
	}

	handleChangeMedicine = (medicine) => {
		if (medicine.length) {
			let isOpenMedicineTypehead = (this.state.isOpenMedicineTypehead === true ? false : true);
			this.getMedicineType(medicine[0].value);
			this.setState({ medicineType: "Tabletter", isMedicineTypeLoading: true, isOpenMedicineTypehead: isOpenMedicineTypehead, medicineError: false });
			if (this.state.medicineId !== medicine[0].value)
				this.setState({ medicineId: medicine[0].value, medicineTypeId: "", packageId: "", selectedMedicine: medicine, selectedMedicineType: [], selectedMedicineStrength: [], medicineTypeError: "", medicineStrengthError: "" });
		} else {
			this.setState({ medicineId: "", medicineTypeId: "", packageId: "", selectedMedicine: [], selectedMedicineType: [], selectedMedicineStrength: [], medicineError: true, medicineTypeError: "", medicineStrengthError: "" });
		}
	}

	handleChangeMedicineType = (type) => {
		let _this = this;
		let isOpenTypehead = this.state.isOpenTypehead === true ? false : true;
		if (type.length) {
			this.getMedicineStrength(type[0].value);
			this.state.medicineTypes.filter((data) => {
				if (data.id == type[0].value) {
					_this.setState({ medicineType: webConst.ucfirst(data.medicine_type) });
				}
			});

			this.setState({
				medicineTypeId: type[0].value,
				isOpenTypehead: isOpenTypehead,
				medicinePhoto: "",
				medicineUnit: "mg",
				medicineStrength: 0,
				selectedMedicineType: type,
				isMedicineStrengthLoading: true,
				selectedMedicineStrength: [],
				packageId: "",
				medicineTypeError: false,
				medicineStrengthError: ""
			});
		} else {
			this.setState({ medicineTypeId: "", packageId: "", selectedMedicineType: [], selectedMedicineStrength: [], medicineTypeError: true, medicineStrengthError: "" });
		}
	}

	handleChangeMedicineStrength = (strength) => {
		let _this = this;
		if (strength.length) {
			let value = strength[0].value;
			this.state.medicineStrengthes.filter((data) => {
				if (data.id == value) {
					_this.setState({ medicinePhoto: data.image, medicineUnit: data.unit, medicineStrength: data.unit_strength });
				}
			});
			let packageId = strength[0].value;
			let isOpenStrengthTypehead = this.state.isOpenStrengthTypehead === true ? false : true;
			this.setState({ isOpenStrengthTypehead: isOpenStrengthTypehead, packageId: packageId, selectedMedicineStrength: strength, medicineStrengthError: false });
			this.totalStrength();
		} else {
			this.setState({ packageId: "", selectedMedicineStrength: [], medicineStrengthError: false });
		}
	}

	getMedicineType = (medicineId) => {
		this.setState({ 'isLoader': true });
		const params = new URLSearchParams();
		params.append('medicine_id', medicineId);
		this.props.getMedicineTypeRequest(params);
	}

	getMedicineStrength = (medicineTypeId) => {
		let medicineId = this.state.medicineId;
		if (medicineId > 0 && medicineTypeId > 0) {
			this.setState({ 'isLoader': true });
			const params = new URLSearchParams();
			params.append('medicine_id', medicineId);
			params.append('medicine_type_id', medicineTypeId);
			this.props.getMedicineStrengthRequest(params);
		}
	}
	
	medicineFilter = (query) => {
		var medicines = [];
		let _this = this;
		setTimeout(() => {
			medicines = _this.state.medicines.filter((val) => {
				if (query == "") {
					return val
				}
				else if (val.name.toLowerCase().includes(query.toLowerCase())) {
					return val;
				}
			}).map((val, key) => {
				return val;
			});
		}, 100);
		return medicines;
	}

	totalStrength = () => {
		let total = 0;
		let initialDosage = this.state.initialDosage ? this.state.initialDosage : 0;
		let medicineStrength = this.state.medicineStrength ? this.state.medicineStrength : 0;
		let totalStrength = medicineStrength * initialDosage;
		this.setState({ totalStrength: totalStrength });
	}

	setDate = (name, value) => {
		this.setState({ [name]: value });
	}

	addAlcoholPlan = (event) => {
		event.preventDefault();
		const form = event.currentTarget;
		this.setState({ "validated": true });
		if (form.checkValidity() === false) {
			this.setState({ "validated": false });
			event.preventDefault();
			event.stopPropagation();
			this.addAlcoholForm.current.classList.add("was-validated");
		}
		else {
			const params = new URLSearchParams();
			params.append('user_id', webConst.getUserId());
			params.append('clinic_id', webConst.getClinicId());
			params.append('client_id', webConst.getClientId());
			params.append('medicine_id', this.state.medicineId);
			params.append('initial_dosage', this.state.initialDosage);
			params.append('start_date', moment(this.state.startDate).format("YYYY-MM-DD HH:mm:ss"));
			params.append('note', this.state.note ? this.state.note : "");
			params.append('medicine_type_id', this.state.medicineTypeId);
			params.append('package_id', this.state.medicineTypeId);
			params.append('doctor_type', this.state.doctorType === "external" ? 1 : 0);
			params.append('doctor_id', this.state.internalDoctorId);
			params.append('external_doctor_id', this.state.externalDoctorId);
			this.props.addAlcoholMedicinePlanRequest(params);
			this.setState({ isFormLoading: true });
		}
		this.setState({ "validated": true });
		event.preventDefault();
	}

	resetForm = () => {
		this.addAlcoholForm.current.reset();
		this.setState({ "medicineId": "", "initialDosage": "", "startDate": "", "note": "", "medicineTypeId": "", "doctorType": "internal", "internalDoctorId": "", "externalDoctorId": "", "validated": true, "packageId": "" });
		this.setState({ "validated": false });
		this.addAlcoholForm.current.classList.remove("was-validated");
	}

	render() {
		let total = 0;
		let initialDosage = this.state.initialDosage ? this.state.initialDosage : 0;
		let medicineStrength = this.state.medicineStrength ? this.state.medicineStrength : 0;
		let totalStrength = medicineStrength * initialDosage;
		let medicineUnit = this.state.medicineUnit ? this.state.medicineUnit : "";
		let unit = medicineUnit.replace(/[^a-zA-Z\/]+/g, '');
		unit = unit ? unit : "mg";
		if (this.state.totalStrength && totalStrength != this.state.totalStrength)
			this.setState({ totalStrength: totalStrength });
		let totalMg = initialDosage + " " + webConst.shortType(webConst.ucfirst(this.state.medicineType)) + " * " + medicineStrength + " " + unit + " = " + totalStrength + " " + unit;
		return (
			<div className="">
				<div className="validi-tabbar-inner-header">
					<div className="">
						<h4 className="validi-subtitle"><Translate id="medicine.add-alcohol-plan" /></h4>
					</div>
				</div>
				<div className="row">
					<div className="col-md-8 col-lg-6">
						<Form className="row" noValidate validated={this.state.validated} onSubmit={this.addAlcoholPlan} ref={this.addAlcoholForm}>
							<div className="col-md-6">
								<Form.Group className="form-group" controlId="interalDoctorId">
									<div className="validi-radio-btnbox">
										<Form.Check type={"radio"} id={`internal-doctor`} className="radio-btn">
											<Form.Check.Input type="radio" name="doctorType" value="internal" onChange={(e) => { this.handleChange(e) }} checked={this.state.doctorType === "internal"} />
											<span className="checkmark"></span>
											<Form.Check.Label><Translate id="general.select-internal-doctor" /></Form.Check.Label>
										</Form.Check>
									</div>
									{this.state.doctorType && this.state.doctorType == "internal" ?
										<div>
											<Form.Select
												type="select"
												name="internalDoctorId"
												value={this.state.internalDoctorId ? this.state.internalDoctorId : ""}
												onChange={(e) => { this.handleChange(e) }}
												className="mt-2"
												required
												placeholder="Select doctor"
											>
												<Translate>
													{({ translate }) =>
														<option key="internal-doctor-0" value="">{translate("general.select-internal-doctor")}</option>
													}
												</Translate>
												{this.state.internalDoctors.map((option) => (
													<option key={"internal-doctor-" + option.ID} value={option.ID}>
														{option.display_name}
													</option>
												))}
											</Form.Select>
											<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
										</div>
										: ""
									}
								</Form.Group>
							</div>
							<div className="col-md-6">
								<Form.Group className="form-group" controlId="externalDoctorId">
									<div className="validi-radio-btnbox mb-2">
										<Form.Check type={"radio"} id={`external-doctor`} className="radio-btn">
											<Form.Check.Input type="radio" name="doctorType" value="external" onChange={(e) => { this.handleChange(e) }} checked={this.state.doctorType === "external"} />
											<span className="checkmark"></span>
											<Form.Check.Label><Translate id="general.select-external-doctor" /></Form.Check.Label>
										</Form.Check>
									</div>
									{this.state.doctorType && this.state.doctorType == "external" ?
										<div>
											<Form.Select
												type="select"
												name="externalDoctorId"
												value={this.state.externalDoctorId ? this.state.externalDoctorId : ""}
												onChange={(e) => { this.handleChange(e) }}
												className="mt-2"
												placeholder="Select doctor"
												required
											>
												<Translate>
													{({ translate }) =>
														<option key="external-doctor-0" value="">{translate("general.select-external-doctor")}</option>
													}
												</Translate>
												{this.state.externalDoctors.map((option) => (
													<option key={"external-doctor-" + option.id} value={option.id}>
														{option.name}
													</option>
												))}
											</Form.Select>
											<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
										</div>
										: ""
									}
								</Form.Group>
							</div>
							<div className="col-md-12">
								<div className="form-group">
									<Form.Group className="form-group" controlId="medicineId">
										<Form.Label><Translate id="medicine.medicine" /></Form.Label>
										<AsyncTypeahead
											id="medicineId"
											labelKey="name"
											className={this.state.medicineError ? "is-invalid" : "is-valid"}
											onSearch={this.medicineFilter}
											options={this.state.medicines}
											isLoading={this.state.isMedicineLoading}
											placeholder={"Select medicine"}
											onChange={(medicine) => { this.handleChangeMedicine(medicine); }}
											required={true}
											isInvalid={this.state.medicineError ? true : false}
											isValid={this.state.medicineError ? false : true}
											minLength={1}
											onFocus={() => { this.setState({ isOpenMedicineTypehead: true }) }}
											onBlur={(e) => this.setState({ isOpenMedicineTypehead: false })}
											open={this.state.isOpenMedicineTypehead}
											selected={this.state.selectedMedicine && this.state.selectedMedicine.length > 0 ? this.state.selectedMedicine : []}
											clearButton
										/>
								
										<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
									</Form.Group>
								</div>
							</div>
							<div className="col-md-6">
								<Form.Group className="form-group" controlId="medicineTypeId">
									<Form.Label><Translate id="medicine.medicine-type" /></Form.Label>
									<Typeahead
										id="medicineTypeId"
										labelKey="name"
										className={this.state.medicineTypeError ? "is-invalid" : "is-valid"}
										options={this.state.optionMedicineTypes}
										isLoading={this.state.isMedicineTypeLoading}
										placeholder={"Select type"}
										onChange={(type) => { this.handleChangeMedicineType(type); }}
										required={true}
										minLength={1}
										onFocus={() => { this.setState({ isOpenTypehead: true }) }}
										onBlur={(e) => this.setState({ isOpenTypehead: false })}
										open={this.state.isOpenTypehead}
										filterBy={() => true}
										selected={this.state.selectedMedicineType && this.state.selectedMedicineType.length > 0 ? this.state.selectedMedicineType : []}
										clearButton
										isValid={this.state.medicineTypeError && this.state.medicineTypeError === false ? true : false}
										isInvalid={this.state.medicineTypeError ? true : false}
									/>
									<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
								</Form.Group>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<Form.Group className="form-group" controlId="initialDosage">
										<Form.Label><Translate id="medicine.initial-dosage" /></Form.Label>
										<InputGroup className="mb-3">
											<Translate>
												{({ translate }) =>
													<FormControl
														type="text"
														name="initialDosage"
														value={this.state.initialDosage ? this.state.initialDosage : ""}
														onChange={(e) => { this.handleChange(e) }}
														placeholder={translate("medicine.initial-dosage")}
														required
													/>
												}
											</Translate>
											<InputGroup.Text id="basic-addon2">{webConst.shortType(webConst.ucfirst(this.state.medicineType))}</InputGroup.Text>
										</InputGroup>
										<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
									</Form.Group>
								</div>
							</div>
							<div className="col-md-6">
								<Form.Group className="form-group" controlId="packageId">
									<Form.Label><Translate id="medicine.strength" /></Form.Label>
									<Typeahead
										id="packageId"
										labelKey="name"
										className="is-valid"
										options={this.state.optionMedicineStrengthes}
										isLoading={this.state.isMedicineStrengthLoading}
										placeholder={"Select strength"}
										onChange={(strength) => {
											this.handleChangeMedicineStrength(strength);
										}}
										required={true}
										isValid={this.state.packageId > 0 ? true : false}
										minLength={1}
										onFocus={(e) => this.setState({ isOpenStrengthTypehead: true })}
										onBlur={(e) => this.setState({ isOpenStrengthTypehead: false })}
										open={this.state.isOpenStrengthTypehead}
										filterBy={() => true}
										selected={this.state.selectedMedicineStrength && this.state.selectedMedicineStrength.length > 0 ? this.state.selectedMedicineStrength : []}
										clearButton
									/>
									<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
								</Form.Group>
							</div>
							<div className="col-md-6">
								<Form.Group className="form-group" controlId="totalMg">
									<Form.Label><Translate id="medicine.total-mg" /></Form.Label>
									<InputGroup className="mb-3">
										<Translate>
											{({ translate }) =>
												<FormControl
													type="text"
													name="totalMg"
													value={totalMg}
													onChange={(e) => { this.handleChange(e) }}
													placeholder={translate("medicine.total-mg")}
													readOnly={true}
												/>
											}
										</Translate>
									</InputGroup>
								</Form.Group>
							</div>
							<div className="col-md-12">
								<Form.Group className="form-group" controlId="startDate">
									<Form.Label><Translate id="medicine.start-date" /></Form.Label>
									<Translate>
										{({ translate }) =>
											<DatePicker
												selected={this.state.startDate}
												name="startDate"
												onChange={(date) => {
													this.setDate("startDate", date)
												}}
												dateFormat="dd-MM-yyyy"
												className="form-control"
												withPortal
												locale={daLocale}
												value={this.state.startDate ? this.state.startDate : ""}
												placeholderText={translate("medicine.start-date")}
												autoComplete='off'
												required
											/>
										}
									</Translate>
									<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
								</Form.Group>
							</div>
							<div className="col-md-12">
								<Form.Group className="form-group" controlId="note">
									<Form.Label><Translate id="medicine.note" /></Form.Label>
									<Translate>
										{({ translate }) =>
											<Form.Control
												type="textarea"
												name="note"
												value={this.state.note ? this.state.note : ""}
												onChange={(e) => { this.handleChange(e) }}
												placeholder={translate("medicine.note")}
												as="textarea"
												rows="2"
												required
											/>
										}
									</Translate>
									<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
								</Form.Group>
							</div>
							<div className="row">
								<div className="col-md-6">
									<button type="submit" className="validi-theme-btn" name="create-reduction-plan">{webConst.isLoader(this.state.isFormLoading, <Translate id="general.save" />)}</button>
									<button type="reset" className="validi-theme-btn clear-btn" onClick={this.resetForm}><Translate id="general.clear" /></button>
								</div>
							</div>
						</Form>
					</div>
				</div>
			</div>
		)
	}
}


AddAlcohol.defaultProps = {
	medicinePlanDetailSuccess: "",
	medicinePlanDetailStatus: "",
	medicinePlanDetailType: "",
	alcoholPlanSuccess: "",
	alcoholPlanStatus: "",
	alcoholPlanType: ""
};

AddAlcohol.propTypes = {
	medicinePlanDetailSuccess: PropTypes.any,
	medicinePlanDetailStatus: PropTypes.any,
	medicinePlanDetailType: PropTypes.any,
	alcoholPlanSuccess: PropTypes.any,
	alcoholPlanStatus: PropTypes.any,
	alcoholPlanType: PropTypes.any
};


function mapStateToProps(state) {
	return {
		medicinePlanDetailSuccess: state.medicinePlanDetail.success,
		medicinePlanDetailStatus: state.medicinePlanDetail.status,
		medicinePlanDetailType: state.medicinePlanDetail.type,
		alcoholPlanSuccess: state.alcoholPlan.success,
		alcoholPlanStatus: state.alcoholPlan.status,
		alcoholPlanType: state.alcoholPlan.type,
		alcoholActiveTab: state.alcoholPlan.alcoholActiveTab
	};
}

const mapDispatchToProps = {
	getMedicinePlanDetailRequest,
	getMedicineTypeRequest,
	getMedicineStrengthRequest,
	addAlcoholMedicinePlanRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAlcohol);

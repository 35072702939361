import React, { Component, Fragment } from 'react';
// import Select from "react-select";
import { Tabs, Tab, Table, FormGroup, Label, Input, Popover, OverlayTrigger } from 'react-bootstrap';
import './DetoxSuspended.scss';
import { Link } from 'react-router-dom';
import * as webConst from '../../../../utils/WebConstant';
import * as routes from '../../../../utils/RouteConstant';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import backArrow from '../../../../images/back-arrow.svg';
import ReactDatatable from '@mkikets/react-datatable';
import { detoxSuspendedHistoryRequest } from '../../../../containers/Medicines/Detoxes/DetoxPlanContainer/action';
import moment from "moment";
import { DETOX_SUSPENDED_HISTORY_SUCCESS } from '../../../../containers/Medicines/Detoxes/DetoxPlanContainer/Constants';

class DetoxSuspended extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isDetoxLoading: true,
			clientId: '',
			detoxTotalRecord: 0,
			detoxRecords: [],
			activeDetoxColumns: this.activeDetoxDatatable(),
			detoxActiveTab: ""
		};
	}

	componentDidMount() {
		let _this = this;
		let client = webConst.getClient();
		let clientId = client && typeof client !== 'undefined' && typeof client.client_id !== 'undefined' ? client.client_id : 0;
		if (clientId > 0) {
			let clientName = client && typeof client !== 'undefined' && typeof client.client_name !== 'undefined' ? client.client_name : 0;
			let cpr = client && typeof client !== 'undefined' && typeof client.client_socialid !== 'undefined' ? client.client_socialid : 0;
			let photo = client && typeof client !== 'undefined' && typeof client.photo !== 'undefined' ? client.photo : 0;
			this.setState({ clientId: clientId, clientName: clientName, clientCPR: cpr, clientPhoto: photo });
		}
		else {
			webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.select-client-message" />);
			this.props.history.push(routes.HOME_ROUTE);
		}
	}

	componentDidUpdate(prevProps) {
		const { detoxSuccess, detoxStatus, detoxType, detoxActiveTab } = this.props;

		if (this.state.detoxActiveTab != detoxActiveTab) {
			this.setState({ detoxActiveTab: detoxActiveTab });
			if (detoxActiveTab === "detox-suspended") {
				let params = new URLSearchParams();
				params.append('clinic_id', webConst.getClinicId());
				params.append('client_id', webConst.getClientId());
				params.append('user_id', webConst.getUserId());
				this.getActiveDetoxData(params);
				this.setState({ detoxActiveTab: detoxActiveTab, isDetoxLoading: true });
			}
		}

		if (detoxStatus === 200) {
			if (detoxType == DETOX_SUSPENDED_HISTORY_SUCCESS) {
				if (prevProps.detoxSuccess !== detoxSuccess) {
					this.setState({
						isDetoxLoading: false,
					});

					if (detoxSuccess.records && detoxSuccess.records.length) {
						this.setState({ detoxRecords: detoxSuccess.records, detoxTotalRecord: detoxSuccess.total_records });
					}
				}
			}
		}

		if (detoxStatus === 400) {
			if (this.state.isDetoxLoading) {
				this.setState({
					isDetoxLoading: false,
				});
			}
		}
	}

	getActiveDetoxData = (params = "") => {
		this.props.detoxSuspendedHistoryRequest(params);
	}

	tableChangeHandler = (data) => {
		const params = new URLSearchParams();
		params.append('clinic_id', webConst.getClinicId());
		params.append('client_id', webConst.getClientId());
		params.append('user_id', webConst.getUserId());
		Object.keys(data).map((key) => {
			if (key === "sort_order") {
				if (data[key]) {
					params.append("sort_order", data[key].order !== "false" ? data[key].order : "");
					params.append("sort_column", data[key].column);
				}
			} else {
				params.append(key, data[key]);
			}
		});
		this.getActiveDetoxData(params);
		this.setState({ isDetoxLoading: true });
	}

	activeDetoxDatatable = function () {
		let columns = [
			{
				key: "doctor_id",
				text: "Doctor",
				className: "text-capitalize red-line",
				width: 100,
				align: "left",
				sortable: true,
				cell: record => {
					return (
						<Fragment>
							{record.external_doctor ? record.external_doctor : record.doctor}
						</Fragment>
					);
				}
			},
			{
				key: "medicine_name",
				text: "Medicine",
				align: "left",
				className: "text-capitalize red-line",
				sortable: true,
				cell: record => {
					return (
						<Fragment>
							{record.new_medicine_name ? record.new_medicine_name : record.medicine_name}
						</Fragment>
					);
				}
			},
			{
				key: "start_date",
				text: "Start Date",
				align: "left",
				className: "validi-date-text red-line",
				sortable: true,
				cell: record => {
					return (
						<Fragment key={"start_date-" + record.detox_id}>
							{moment(record.start_date).format("DD-MM-YYYY")}
						</Fragment>
					);
				}
			},
			{
				key: "finished_date",
				text: "End Date",
				align: "left",
				className: "validi-date-text red-line",
				sortable: true,
				cell: record => {
					return (
						<Fragment key={"finished_date-" + record.detox_id}>
							{moment(record.finished_date).format("DD-MM-YYYY")}
						</Fragment>
					);
				}
			},
			{
				key: "created_by",
				text: "Created by",
				align: "left",
				sortable: true,
				className: 'text-capitalize red-line',
				cell: record => {
					return (
						<Fragment key={"staff_name-" + record.detox_id}>
							{record.staff_name}
						</Fragment>
					);
				}
			},
			{
				key: "total_dosage",
				key_column: "total_dosage",
				text: "Total",
				align: "left",
				className: 'validi-mg-text red-line',
				sortable: true,
				cell: record => {
					return (
						<Fragment key={"total_dosage-" + record.detox_id}>
							{record.total_dosage} {webConst.ucfirst(record.unit.replace(/[^a-zA-Z\/]+/g, ''))}
						</Fragment>
					);
				}
			},
			{
				key: "reason",
				key_column: "reason",
				text: "Note",
				align: "left",
				className: 'validi-date-text red-line ',
				sortable: true,
				cell: record => {
					return (
						<Fragment key={"reason-" + record.detox_id}>
							<div className='short-note'>{record.reason}</div>
						</Fragment>
					);
				}
			},
			{
				key: "action",
				text: "Action",
				className: "action red-line",
				width: 100,
				align: "left",
				sortable: false,
				cell: record => {
					return (
						<Fragment>
							<div className="validi-active-icons-wrapper">
								<Link to={"detox-detail/" + record.detox_id} ><img src="/images/view-icon.svg" alt="" /></Link>
							</div>
						</Fragment>
					);
				}
			}
		];
		return columns;
	}


	render() {
		return (
			<div>
				<div>
					{this.state.isDetoxLoading ?
						<ReactDatatable
							className="table"
							dynamic={true}
							config={webConst.dataTableConfig("detox_id")}
							records={this.state.detoxRecords}
							columns={this.state.activeDetoxColumns}
							total_record={this.state.detoxTotalRecord ? this.state.detoxTotalRecord : 0}
							onChange={data => { this.tableChangeHandler(data) }}
							loading={this.state.isDetoxLoading}
						/>
						: ""
					}
				</div>
			</div>
		)
	}
}

DetoxSuspended.defaultProps = {
	clientId: '',
	detoxSuccess: "",
	detoxFail: "",
	detoxStatus: "",
	detoxActiveTab: ""
};

DetoxSuspended.propTypes = {
	clientId: PropTypes.any,
	detoxSuccess: PropTypes.any,
	detoxFail: PropTypes.any,
	detoxStatus: PropTypes.any,
	detoxType: PropTypes.any,
	detoxActiveTab: PropTypes.any
};

function mapStateToProps(state) {
	return {
		detoxSuccess: state.detoxPlan.success,
		detoxFail: state.detoxPlan.fail,
		detoxStatus: state.detoxPlan.status,
		detoxType: state.detoxPlan.type,
		detoxActiveTab: state.detoxPlan.detoxActiveTab,
		clientId: state.appLayout.clientId ? state.appLayout.clientId : ""
	};
}

const mapDispatchToProps = {
	detoxSuspendedHistoryRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(DetoxSuspended);

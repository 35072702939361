import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Translate } from 'react-localize-redux';
import * as routes from '../../../utils/RouteConstant';
import * as webConst from '../../../utils/WebConstant';
import { logoutRequest } from '../../../containers/Auth/LogoutContainer/action';
import { withHeader } from '../../../service/ApiConfig';


class Logout extends Component {

  constructor(props) {
    super(props);
    this.state = {};
    console.log("Test");
  }
  componentDidMount() {
    console.log("Test");
    this.props.logoutRequest();
  }

  componentDidUpdate(prevProps) {
    console.log("logout componentDidUpdate");
    const { logoutSuccess, logoutFail, logoutStatus } = this.props;
    console.log("logoutStatus",logoutStatus);
    
    if (logoutStatus === 200) {
      // window.location.reload(false);
      // if (prevProps.logoutSuccess !== logoutSuccess) {
        this.setState({
          isLoading: false
        });
        console.log("logout");
        webConst.removeUserToken();
        withHeader.headers.Authorization = "";
        webConst.emitter.emit(webConst.LOGIN_EVENT,"Log out");
        webConst.removeUserToken();
        withHeader.headers.Authorization = "";
        // window.location.reload(false);
        this.forceUpdate();
      // }
    }

    if (logoutFail) {
      if (prevProps.logoutFail !== logoutFail) {
        this.setState({ 'isLoading': false });
        webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.something-wrong" />);
      }
    }

  }

  render() {
    console.log("logout");
    return (
      <Redirect
        to={{
          pathname: routes.LOGIN_ROUTE,
          state: {}
        }}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    logoutSuccess: state.logout.success,
    logoutFail: state.logout.fail,
    logoutStatus:state.logout.status
  };
}

const mapDispatchToProps = {logoutRequest};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);

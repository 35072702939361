export const ALCOHOL_PLAN_SUCCESS = 'ALCOHOL_PLAN_SUCCESS';
export const ALCOHOL_PLAN_FAIL = 'ALCOHOL_PLAN_FAIL';
export const ALCOHOL_ACTIVE_TAB = 'ALCOHOL_ACTIVE_TAB';

export const ALCOHOL_DISPENSE_DETAIL_SUCCESS = 'ALCOHOL_DISPENSE_DETAIL_SUCCESS';
export const ALCOHOL_DISPENSE_DETAIL_FAIL = 'ALCOHOL_DISPENSE_DETAIL_FAIL';

export const ALCOHOL_DISPENSE_SUCCESS = 'ALCOHOL_DISPENSE_SUCCESS';
export const ALCOHOL_DISPENSE_FAIL = 'ALCOHOL_DISPENSE_FAIL';

export const ALCOHOL_PLAN_SUSPEND_SUCCESS = 'ALCOHOL_PLAN_SUSPEND_SUCCESS';
export const ALCOHOL_PLAN_SUSPEND_FAIL = 'ALCOHOL_PLAN_SUSPEND_FAIL';

export const ADD_ALCOHOL_PLAN_SUCCESS = 'ADD_ALCOHOL_PLAN_SUCCESS';
export const ADD_ALCOHOL_PLAN_FAIL = 'ADD_ALCOHOL_PLAN_FAIL';


export const ALCOHOL_SUSPENDED_PLAN_SUCCESS = 'ALCOHOL_SUSPENDED_PLAN_SUCCESS';
export const ALCOHOL_SUSPENDED_PLAN_FAIL = 'ALCOHOL_SUSPENDED_PLAN_FAIL';

export const ALCOHOL_DISPENSE_HISTORY_SUCCESS = 'ALCOHOL_DISPENSE_HISTORY_SUCCESS';
export const ALCOHOL_DISPENSE_HISTORY_FAIL = 'ALCOHOL_DISPENSE_HISTORY_FAIL';
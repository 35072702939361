import React, { Component, createRef } from 'react';
import './Task.scss';
import { Form, Tabs, Tab, FormGroup, Label, Input } from 'react-bootstrap';
import * as routes from '../../../utils/RouteConstant';
import { connect } from 'react-redux';
import * as webConst from '../../../utils/WebConstant';
import { Translate } from 'react-localize-redux';

class Task extends Component {

	constructor(props) {
		super(props);
		this.state = {
			
		};

		this.journalForm = React.createRef();
	}

	componentDidMount() {
	}

	componentDidUpdate(prevProps) {

	}

	render() {
		return (
				<div>
					<Form noValidate validated={this.state.validated} onSubmit={this.handleSendMessage} ref={this.messageForm}>
						<Form.Group className="form-group" controlId="journalContent">
							<Form.Label><Translate id="message.message" /></Form.Label>
							<Translate>
								{({ translate }) =>
									<Form.Control
										type="textarea"
										name="message"
										value={this.state.message ? this.state.message : ""}
										onChange={(e) => { this.setState({message:e.target.value}) }}
										placeholder={translate("message.enter-message")}
										as="textarea"
										rows={3}
										required
									/>
								}
							</Translate>
						</Form.Group>
						<div className="">
							<button className="validi-theme-btn">
								{webConst.isLoader(this.state.isFormLoading, <Translate id="general.save" />)}
							</button>
							<button className="validi-theme-btn clear-btn" onClick={this.clearJournalForm}><Translate id="general.clear" /></button>
						</div>
					</Form>
				</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		messageSuccess: state.message.success,
		messageFail: state.message.fail,
		messageStatus: state.message.status,
		homeSuccess: state.home.success,
		homeStatus: state.home.status,
		sendMessageSuccess: state.sendMessage.success,
		sendMessageFail: state.sendMessage.fail,
		sendMessageStatus: state.sendMessage.status,
	};
}

const mapDispatchToProps = { };

export default connect(mapStateToProps, mapDispatchToProps)(Task);

import React, { Component } from 'react';
import './Medicine.scss';
import * as webConst from '../../utils/WebConstant';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import * as routes from '../../utils/RouteConstant';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import backArrow from '../../images/back-arrow.svg';
import { Cube } from 'react-preloaders2';

class Medicine extends Component {

	constructor(props) {
		super(props);
		this.state = {
			'isLoading': true,
			'clientId': '',
		};
	}

	componentDidMount() {
		let _this = this;
		let client = webConst.getClient();
		let clientId = client && typeof client !== 'undefined' && typeof client.client_id !== 'undefined' ? client.client_id : 0;
		if (clientId > 0) {
			let clientName = client && typeof client !== 'undefined' && typeof client.client_name !== 'undefined' ? client.client_name : 0;
			let cpr = client && typeof client !== 'undefined' && typeof client.client_socialid !== 'undefined' ? client.client_socialid : 0;
			let photo = client && typeof client !== 'undefined' && typeof client.photo !== 'undefined' ? client.photo : 0;
			this.setState({ clientId: clientId, clientName: clientName, clientCPR: cpr, clientPhoto: photo });
		}
		else {
			webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.select-client-message" />);
			this.props.history.push(routes.HOME_ROUTE);
		}
	}
	componentDidUpdate(prevProps) {
		if(this.state.isLoading)
			this.setState({ isLoading: false });
	}
	
	render() {
		return (
			<div className="validi-main-layout">
				<div className="container">
					<div className="validi-back-btn-wrapper">
						<Link to={routes.HOME_ROUTE}>
							<i>
								<img src={backArrow} />
							</i>
							<Translate id="back" />
						</Link>
					</div>
					<div>
						<h1 className="main-title">{<Translate id="medicine.title" />}</h1>
						<div className="validi-medicine-wrapper">
							<div className="validi-inner-header-bar">
								<div className="validi-avatar-details-wrepper">
									<div className="validi-avatar-img">
										{this.state.clientPhoto ? <img src={this.state.clientPhoto} alt="" /> : ""}
									</div>
									<div className="validi-avatar-details">
										<h5 className="validi-user-main-title">{this.state.clientName ? this.state.clientName : ""}</h5>
										<p className="validi-paragraph"><Translate id="client-goal.cpr-number" /> : {this.state.clientCPR ? this.state.clientCPR : ""}</p>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-4">
									<Link to={routes.DETOX_PLAN_ROUTE} className="validi-inner-page-link"><Translate id="medicine.detox-plan" /></Link>
								</div>
								<div className="col-md-4">
									<Link to={routes.PN_PLAN_ROUTE} className="validi-inner-page-link active"><Translate id="medicine.pn-plan" /></Link>
								</div>
								<div className="col-md-4">
									<Link to={routes.ALCOHOL_PLAN_ROUTE} className="validi-inner-page-link"><Translate id="medicine.alcohol-plan" /></Link>
								</div>
							</div>
						</div>
						<Cube customLoading={this.state.isLoading} color={'#66BB6A'} background="rgba(255,255,255,0.6)" />
					</div>
				</div>
			</div>
		);
	}
}


Medicine.defaultProps = {
	clientId: ''
};

Medicine.propTypes = {
	clientId: PropTypes.any
};

function mapStateToProps(state) {
	return {
		clientId: state.appLayout.clientId ? state.appLayout.clientId : ""
	};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Medicine);
import React, { Component, createRef } from 'react';
import './Journal.scss';
import { Tabs, Tab, FormGroup, Label, Input, Form } from 'react-bootstrap';
// import Select from "react-select";
import * as webConst from '../../utils/WebConstant';
import * as routes from '../../utils/RouteConstant';
import { getPersonalJournalRequest } from '../../containers/Journals/PersonalJournalContainer/action';
import { saveJournalRequest } from '../../containers/Journals/AddPersonalJournalContainer/action';
import { setClientId } from '../../containers/AppLayoutContainer/action';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import daLocale from "date-fns/locale/da";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import backArrow from '../../images/back-arrow.svg';
import { Cube } from 'react-preloaders2';

class Journal extends Component {

	constructor(props) {
		super(props);
		this.state = {
			'isLoading': true,
			'journals': [],
			'paged': 1,
			'isLoader': true,
			'clients': [],
			'journalTypes': [],
			'clientId': '',
			'JournalTypeId': '',
			'isMoreJournal': true,
			'journalContent': '',
			'startDate': '',
			'endDate': '',
			'form': [],
			'isFormLoading': false,
			'isJournalLoading': false,
			'validated': false,
			'journalForm': '',
			"filterTypeId": ""
		};

		this.journalForm = React.createRef();


		this.getPersonalJournal = this.getPersonalJournal.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleFilterJournal = this.handleFilterJournal.bind(this);
		this.handleClearFilterJournal = this.handleClearFilterJournal.bind(this);
		this.handleClientJournal = this.handleClientJournal.bind(this);
		this.getLoadMore = this.getLoadMore.bind(this);
		this.addJournal = this.addJournal.bind(this);
	}

	componentDidMount() {
		let _this = this;
		let client = webConst.getClient();
		let clientId = client && typeof client !== 'undefined' && typeof client.client_id !== 'undefined' ? client.client_id : 0;
		let clientName = client && typeof client !== 'undefined' && typeof client.client_name !== 'undefined' ? client.client_name : 0;
		let cpr = client && typeof client !== 'undefined' && typeof client.client_socialid !== 'undefined' ? client.client_socialid : 0;
		let photo = client && typeof client !== 'undefined' && typeof client.photo !== 'undefined' ? client.photo : 0;

		if (clientId > 0) {
			let _this = this;
			this.setState({ clientId: clientId, clientName: clientName, clientCPR: cpr, clientPhoto: photo });
			setTimeout(() => {
				_this.getPersonalJournal();
			}, 500);
		}
		else {
			webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.select-client-message" />);
			this.props.history.push(routes.HOME_ROUTE);
		}
	}

	componentDidUpdate(prevProps) {
		const { personalJournalSuccess, personalJournalStatus, addPersonalJournalSuccess, addPersonalJournalFail, addPersonalJournalStatus } = this.props;
		
		if (personalJournalStatus === 200) {
			if (prevProps.personalJournalSuccess !== personalJournalSuccess) {
				this.setState({
					isJournalLoading: false,
					isLoading:false,
					paged: parseInt(this.state.paged) + 1
				});

				if (personalJournalSuccess.journals && personalJournalSuccess.journals.length) {
					if (this.state.isMoreJournal)
						this.setState({ journals: [...this.state.journals, ...personalJournalSuccess.journals] });
					else
						this.setState({ journals: personalJournalSuccess.journals });

					this.setState({ "isMoreJournal": true });
				}

				if (personalJournalSuccess.clients && personalJournalSuccess.clients.length) {
					this.setState({ clients: personalJournalSuccess.clients });
				}
				if (personalJournalSuccess.journalTypes && personalJournalSuccess.journalTypes.length) {
					let types = [];
					let _this = this;
					types = personalJournalSuccess.journalTypes.map((item, index) => {
						return <option key={"type-" + item.type_id} value={item.type_id}>{webConst.ucfirst(item.journal_category)}</option>
					});
					setTimeout(() => {
						_this.setState({ journalTypes: types });
					}, 500);

				}
			}
		}

		if (personalJournalStatus === 400) {
			if (this.state.isJournalLoading) {
				this.setState({
					isJournalLoading: false,
					isMoreJournal: false,
					isLoading:false
				});
			}
		}

		if (addPersonalJournalStatus === 200) {
			if (prevProps.addPersonalJournalSuccess !== addPersonalJournalSuccess) {
				if (this.state.isFormLoading) {
					this.setState({
						isFormLoading: false,
						journalContent: '',
						journalTypeId: '',
						validated: false
					});
					let journals = this.state.journals;
					journals.unshift(addPersonalJournalSuccess);
					this.setState({ "journals": journals });
					webConst.toaster(webConst.TOAST_SUCCESS, <Translate id="alert-message.journal-save-message-alert" />);
				}
			}
		}

		if (addPersonalJournalStatus === 400 || prevProps.addPersonalJournalFail !== addPersonalJournalFail) {
			if (this.state.isFormLoading) {
				this.setState({
					isFormLoading: false
				});
			}
		}
	}

	async getPersonalJournal() {
		this.setState({ 'isJournalLoading': true });
		const params = new URLSearchParams();
		params.append('user_id', webConst.getUserId());
		params.append('clinic_id', webConst.getClinicId());
		params.append('client_id', this.state.clientId ? this.state.clientId : "");
		params.append('journal_type_id', this.state.filterTypeId ? this.state.filterTypeId : "");
		params.append('from_date', this.state.startDate ? moment(this.state.startDate).format("YYYY-MM-DD") : "");
		params.append('to_date', this.state.endDate ? moment(this.state.endDate).format("YYYY-MM-DD") : "");
		params.append("paged", this.state.paged);
		this.props.getPersonalJournalRequest(params);
	}

	addJournal = (event) => {
		event.preventDefault();
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			this.setState({ "validated": true });
			event.preventDefault();
			event.stopPropagation();
		}
		else {
			if (this.state.clientId) {
				const params = new URLSearchParams();
				params.append('user_id', webConst.getUserId());
				params.append('clinic_id', webConst.getClinicId());
				params.append('client_id', this.state.clientId);
				params.append('journalContent', event.target.journalContent.value);
				params.append('journal_type_id', event.target.journalTypeId.value);
				this.props.saveJournalRequest(params);
				this.setState({ 'isFormLoading': true, validated: false });
			} else {
				webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.select-client-message" />);
			}
		}
		this.setState({ "validated": true });
	}

	handleChange = (e) => {
		let name = e.target.name;
		let value = e.target.value;
		this.setState({ [name]: value });
	}

	handleChangeDateRange = (name, value) => {
		this.setState({ [name]: value });
	}

	handleFilterJournal = (event) => {
		event.preventDefault();
		this.setState({
			'journals': [],
			'paged': 1
		});
		let _this = this;
		setTimeout(() => {
			_this.getPersonalJournal();
		}, 100);
	}

	handleClearFilterJournal = () => {
		this.setState({
			'clientId': '',
			'filterTypeId': '',
			'journals': [],
			'paged': 1,
			'startDate': '',
			'endDate': ''
		});
		let _this = this;
		setTimeout(() => {
			_this.getPersonalJournal();
		}, 100);
	}

	handleClientJournal = (e) => {
		let id = e.target.value;
		this.setState({
			'journals': [],
			'paged': 1,
			'clientId': id
		});
		let _this = this;
		_this.props.setClientId(id);
		// webConst.setClientId(id);
		setTimeout(() => {
			_this.getPersonalJournal();
		}, 100);
	}

	getLoadMore() {
		if (this.state.isMoreJournal && this.state.isMoreJournal === true)
			this.getPersonalJournal();
		else if (this.state.isLoaded)
			this.setState({ 'isLoaded': false });
	}
	clearJournalForm = (event) => {
		this.journalForm.current.reset();
		this.setState({ "journalTypeId": "", "journalContent": "", "validated": true });
		this.journalForm.current.classList.remove("was-validated");
	}

	render() {
		let journals = [];
		journals = this.state.journals.map((journalItem, key) => {
			return <div className="col-md-6" key={"journal-" + key + "-" + journalItem.journalId}>
				<div className="validi-user-journal-msg">
					<p className="validi-sub-paragraph" dangerouslySetInnerHTML={{ __html: journalItem.note }}></p>
					<div className="">
						<h4 className="validi-subtitle"><Translate id="journal.added-by" />  : {journalItem.postedBy}</h4>
						<p className="validi-paragraph"><Translate id="journal.added-date-time" /> : {journalItem.postedDate}</p>
					</div>
				</div>
			</div>
		});
		return (
			<div className="validi-main-layout ">
				<div className="container">
					<div>
						<h1 className="main-title"><Translate id="journal.title" /></h1>
						<div className="validi-medicine-wrapper">
							<div className="validi-inner-header-bar">
								<div className="validi-avatar-details-wrepper">
									<div className="validi-avatar-img">
										{this.state.clientPhoto ? <img src={this.state.clientPhoto} alt="" /> : ""}
									</div>
									<div className="validi-avatar-details">
										<h5 className="validi-user-main-title">{this.state.clientName ? this.state.clientName : ""}</h5>
										<p className="validi-paragraph"><Translate id="client-goal.cpr-number" /> : {this.state.clientCPR ? this.state.clientCPR : ""}</p>
									</div>
								</div>
							</div>
							<div className="validi-tabbar-wrapper">
								<Tabs id="controlled-tab-example">
									<Tab eventKey="view-journal" title={<Translate id="journal.view-journal" />}>
										<div className="validi-tabbar-inner-header">
											<h4 className="validi-subtitle"><Translate id="journal.view-journal" /></h4>
										</div>
										<div className="validi-journal-msg-wrapper">
											<form>
												<div className="form-group">
													<label><Translate id="journal.journal-type" /></label>
													<Form.Select
														type="select"
														name="filterTypeId"
														value={this.state.filterTypeId ? this.state.filterTypeId : ""}
														onChange={(e) => { this.handleChange(e) }}
														required
													>
														<Translate>
															{({ translate }) =>
																<option key="type-0" value="">{translate("journal.journal-type")}</option>
															}
														</Translate>
														{this.state.journalTypes}
													</Form.Select>
												</div>
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label><Translate id="general.start-date" /></label>
															<DatePicker
																selected={this.state.startDate}
																onChange={(date) => { this.handleChangeDateRange('startDate', date) }}
																selectsStart
																startDate={this.state.startDate}
																endDate={this.state.endDate}
																dateFormat="dd-MM-yyyy"
																className="form-control"
																withPortal
																locale={daLocale}
															/>
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label><Translate id="general.end-date" /></label>
															<DatePicker
																selected={this.state.endDate}
																onChange={(date) => { this.handleChangeDateRange('endDate', date) }}
																selectsEnd
																startDate={this.state.startDate}
																endDate={this.state.endDate}
																minDate={this.state.startDate}
																dateFormat="dd-MM-yyyy"
																className="form-control"
																withPortal
																locale={daLocale}
															/>
														</div>
													</div>
												</div>
												<button type="submit" className="validi-theme-btn" onClick={this.handleFilterJournal}><Translate id="general.search" /></button>
												<button type="button" className="validi-theme-btn clear-btn" onClick={this.handleClearFilterJournal}><Translate id="general.clear" /></button>
											</form>
											{journals.length ?
												<div>
													<div className="row mt-5">
														{journals}
													</div>
													<div className="">
														<button className="validi-theme-btn" onClick={this.getLoadMore}>
															{webConst.isLoader(this.state.isJournalLoading, <Translate id="journal.see-more" />)}
														</button>
													</div>
												</div>
												: ""
											}
										</div>
										{journals.length == 0 && this.state.isJournalLoading == false ?
											<div className="validi-nodata-found">
												<h2><Translate id="alert-message.no-data" /></h2>
											</div>
											: ""
										}
									</Tab>
									<Tab eventKey="AddGoal" title={<Translate id="journal.add-journal" />}>
										<div className="">
											<div className="validi-tabbar-inner-header">
												<h4 className="validi-subtitle"><Translate id="journal.add-journal" /></h4>
											</div>
											<div className="row">
												<div className="col-md-6">
													<Form noValidate validated={this.state.validated} onSubmit={this.addJournal} ref={this.journalForm}>
														<Form.Group className="form-group" controlId="journalTypeId">
															<Form.Label><Translate id="journal.journal-type" /></Form.Label>
															<Form.Select
																type="select"
																name="journalTypeId"
																value={this.state.journalTypeId ? this.state.journalTypeId : ""}
																onChange={(e) => { this.handleChange(e) }}
																required
															>
																<Translate>
																	{({ translate }) =>
																		<option key="type-0" value="">{translate("journal.journal-type")}</option>
																	}
																</Translate>
																{this.state.journalTypes}
															</Form.Select>
															<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
														</Form.Group>
														<Form.Group className="form-group" controlId="journalContent">
															<Form.Label><Translate id="journal.enter-journal" /></Form.Label>
															<Translate>
																{({ translate }) =>
																	<Form.Control
																		type="textarea"
																		name="journalContent"
																		value={this.state.journalContent ? this.state.journalContent : ""}
																		onChange={(e) => { this.handleChange(e) }}
																		placeholder={translate("journal.enter-journal")}
																		as="textarea"
																		rows={3}
																		required
																	/>
																}
															</Translate>
														</Form.Group>
														<div className="">
															<button className="validi-theme-btn">
																{webConst.isLoader(this.state.isFormLoading, <Translate id="general.save" />)}
															</button>
															<button className="validi-theme-btn clear-btn" onClick={this.clearJournalForm}><Translate id="general.clear" /></button>
														</div>
													</Form>
												</div>
												<div className="col-md-6">

												</div>
											</div>
										</div>
									</Tab>
								</Tabs>
							</div>
						</div>
						<Cube customLoading={this.state.isLoading} color={'#66BB6A'} background="rgba(255,255,255,0.6)" />
					</div>
				</div>
			</div>
		);
	}
}


Journal.defaultProps = {
	getPersonalJournalRequest: () => { },
	saveJournalRequest: () => { },
	setClientId: () => { },

	personalJournalStatus: '',
	personalJournalSuccess: '',
	personalJournalFail: '',

	addPersonalJournalStatus: '',
	addPersonalJournalSuccess: '',
	addPersonalJournalFail: '',
	clientId: ''
};

Journal.propTypes = {
	getPersonalJournalRequest: PropTypes.func,
	saveJournalRequest: PropTypes.func,
	setClientId: PropTypes.func,

	personalJournalStatus: PropTypes.any,
	personalJournalSuccess: PropTypes.any,
	personalJournalFail: PropTypes.any,

	addPersonalJournalStatus: PropTypes.any,
	addPersonalJournalSuccess: PropTypes.any,
	addPersonalJournalFail: PropTypes.any,
	clientId: PropTypes.any,
};


function mapStateToProps(state) {
	return {
		personalJournalSuccess: state.personalJournal.success,
		personalJournalFail: state.personalJournal.fail,
		personalJournalStatus: state.personalJournal.status,
		addPersonalJournalSuccess: state.addPersonalJournal.success,
		addPersonalJournalFail: state.addPersonalJournal.fail,
		addPersonalJournalStatus: state.addPersonalJournal.status,
		clientId: state.appLayout.clientId ? state.appLayout.clientId : ""
	};
}

const mapDispatchToProps = { getPersonalJournalRequest, saveJournalRequest, setClientId };

export default connect(mapStateToProps, mapDispatchToProps)(Journal);


export const PN_PLAN_SUCCESS = 'PN_PLAN_SUCCESS';
export const PN_PLAN_FAIL = 'PN_PLAN_FAIL';
export const PN_ACTIVE_TAB = 'PN_ACTIVE_TAB';

export const PN_DISPENSE_DETAIL_SUCCESS = 'PN_DISPENSE_DETAIL_SUCCESS';
export const PN_DISPENSE_DETAIL_FAIL = 'PN_DISPENSE_DETAIL_FAIL';

export const PN_DISPENSE_SUCCESS = 'PN_DISPENSE_SUCCESS';
export const PN_DISPENSE_FAIL = 'PN_DISPENSE_FAIL';

export const PN_PLAN_SUSPEND_SUCCESS = 'PN_PLAN_SUSPEND_SUCCESS';
export const PN_PLAN_SUSPEND_FAIL = 'PN_PLAN_SUSPEND_FAIL';

export const ADD_PN_PLAN_SUCCESS = 'ADD_PN_PLAN_SUCCESS';
export const ADD_PN_PLAN_FAIL = 'ADD_PN_PLAN_FAIL';


export const PN_SUSPENDED_PLAN_SUCCESS = 'PN_SUSPENDED_PLAN_SUCCESS';
export const PN_SUSPENDED_PLAN_FAIL = 'PN_SUSPENDED_PLAN_FAIL';

export const PN_DISPENSE_HISTORY_SUCCESS = 'PN_DISPENSE_HISTORY_SUCCESS';
export const PN_DISPENSE_HISTORY_FAIL = 'PN_DISPENSE_HISTORY_FAIL';
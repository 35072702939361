import { COMPLETED_GOAL_SUCCESS, COMPLETED_GOAL_FAIL } from './Constants';
import axios from 'axios';
import { withHeader } from '../../../service/ApiConfig';
import * as webConst from '../../../utils/WebConstant';
const axiosDefaults = require('axios/lib/defaults');
axiosDefaults.baseURL = withHeader.baseURL;
axiosDefaults.headers = withHeader.headers;
axiosDefaults.timeout = withHeader.timeOut;

export const getCompletedGoalRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.GET_COMPLETED_GOAL}`, value)
            .then((response) => {
                if (response.data.responseCode === 200) {
                    dispatch(goalSuccessResult(response.data.responseBody, response.status));
                }
                else {
                    dispatch(goalFailResult(response.data.responseMessage, response.data.responseCode));
                }
            })
            .catch((error) => {
                dispatch(goalFailResult(error.data, error.status));
            });
    }
};

/*
    API RESULT
 */


export const goalSuccessResult = (success, status) => ({
    type: COMPLETED_GOAL_SUCCESS,
    success,
    status
});

export const goalFailResult = (error, status) => ({
    type: COMPLETED_GOAL_FAIL,
    error,
    status
});

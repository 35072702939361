import { 
        ALCOHOL_PLAN_SUCCESS,
        ALCOHOL_PLAN_FAIL,
        ALCOHOL_ACTIVE_TAB,
        ALCOHOL_DISPENSE_DETAIL_SUCCESS,
        ALCOHOL_DISPENSE_DETAIL_FAIL,
        ALCOHOL_DISPENSE_SUCCESS,
        ALCOHOL_DISPENSE_FAIL,
        ALCOHOL_PLAN_SUSPEND_SUCCESS,
        ALCOHOL_PLAN_SUSPEND_FAIL,
        ADD_ALCOHOL_PLAN_SUCCESS,
        ADD_ALCOHOL_PLAN_FAIL,
        ALCOHOL_SUSPENDED_PLAN_SUCCESS,
        ALCOHOL_SUSPENDED_PLAN_FAIL,
        ALCOHOL_DISPENSE_HISTORY_SUCCESS,
        ALCOHOL_DISPENSE_HISTORY_FAIL
    } from './Constants';
import axios from 'axios';
import { withHeader } from '../../../../service/ApiConfig';
import * as webConst from '../../../../utils/WebConstant';
const axiosDefaults = require('axios/lib/defaults');
axiosDefaults.baseURL = withHeader.baseURL;
axiosDefaults.headers = withHeader.headers;
axiosDefaults.timeout = withHeader.timeOut;

export const getAlcoholMedicinePlanRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.GET_ALCOHOL_PLAN}`, value)
            .then((response) => {
                if (response.data.responseCode === 200) {
                    dispatch(alcoholPlanSuccessResult(response.data.responseBody, response.status));
                }
                else {
                    dispatch(alcoholPlanFailResult(response.data.responseMessage, response.data.responseCode));
                }
            })
            .catch((error) => {
                dispatch(alcoholPlanFailResult(error.data, error.status));
            });
    }
};


export const getAlcoholDispenseDetailRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.GET_ALCOHOL_DISPENSE_DETAIL_PLAN}`, value)
            .then((response) => {
                if (response.data.responseCode === 200) {
                    dispatch(alcoholDispenseDetailSuccessResult(response.data.responseBody, response.status));
                }
                else {
                    dispatch(alcoholDispenseDetailFailResult(response.data.responseMessage, response.data.responseCode));
                }
            })
            .catch((error) => {
                dispatch(alcoholDispenseDetailFailResult(error.data, error.status));
            });
    }
};

export const saveDispenseDosageRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.ALCOHOL_DISPENSE_DOSAGE}`, value)
            .then((response) => {
                if (response.data.responseCode === 200) {
                    dispatch(alcoholDispenseSuccessResult(response.data.responseBody, response.status));
                }
                else {
                    dispatch(alcoholDispenseFailResult(response.data.responseMessage, response.data.responseCode));
                }
            })
            .catch((error) => {
                dispatch(alcoholDispenseFailResult(error.data, error.status));
            });
    }
};

export const suspendAlcoholMedicineRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.ALCOHOL_SUSPEND}`, value)
            .then((response) => {
                if (response.data.responseCode === 200) {
                    dispatch(suspendAlcoholMedicineSuccessResult(response.data.responseBody, response.status));
                }
                else {
                    dispatch(suspendAlcoholMedicineFailResult(response.data.responseMessage, response.data.responseCode));
                }
            })
            .catch((error) => {
                dispatch(suspendAlcoholMedicineFailResult(error.data, error.status));
            });
    }
};

export const addAlcoholMedicinePlanRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.ADD_ALCOHOL_PLAN}`, value)
            .then((response) => {
                if (response.data.responseCode === 200) {
                    dispatch(addAlcoholPlanSuccessResult(response.data.responseBody, response.status));
                }
                else {
                    dispatch(addAlcoholPlanFailResult(response.data.responseMessage, response.data.responseCode));
                }
            })
            .catch((error) => {
                dispatch(addAlcoholPlanFailResult(error.data, error.status));
            });
    }
};

export const getSuspendedAlcoholMedicinePlanHistoryRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.ALCOHOL_SUSPENDED_PLAN_HISTORY}`, value)
            .then((response) => {
                if (response.data.responseCode === 200) {
                    dispatch(suspendedAlcoholPlanSuccessResult(response.data.responseBody, response.status));
                }
                else {
                    dispatch(suspendedAlcoholPlanFailResult(response.data.responseMessage, response.data.responseCode));
                }
            })
            .catch((error) => {
                dispatch(suspendedAlcoholPlanFailResult(error.data, error.status));
            });
    }
};


export const getAlcoholDispenseHistoryRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.ALCOHOL_DISPENSE_HISTORY}`, value)
            .then((response) => {
                if (response.data.responseCode === 200) {
                    dispatch(alcoholDispenseHistorySuccessResult(response.data.responseBody, response.status));
                }
                else {
                    dispatch(alcoholDispenseHistoryFailResult(response.data.responseMessage, response.data.responseCode));
                }
            })
            .catch((error) => {
                dispatch(alcoholDispenseHistoryFailResult(error.data, error.status));
            });
    }
};

/*
    API RESULT
 */

export const alcoholPlanSuccessResult = (success, status) => ({
    type: ALCOHOL_PLAN_SUCCESS,
    success,
    status
});

export const alcoholPlanFailResult = (error, status) => ({
    type: ALCOHOL_PLAN_FAIL,
    error,
    status
});

export const setAlcoholActiveTab = (alcoholType) => ({
    type: ALCOHOL_ACTIVE_TAB,
    alcoholType
});

export const alcoholDispenseDetailSuccessResult = (success, status) => ({
    type: ALCOHOL_DISPENSE_DETAIL_SUCCESS,
    success,
    status
});

export const alcoholDispenseDetailFailResult = (error, status) => ({
    type: ALCOHOL_DISPENSE_DETAIL_FAIL,
    error,
    status
});

export const alcoholDispenseSuccessResult = (success, status) => ({
    type: ALCOHOL_DISPENSE_SUCCESS,
    success,
    status
});

export const alcoholDispenseFailResult = (error, status) => ({
    type: ALCOHOL_DISPENSE_FAIL,
    error,
    status
});

export const suspendAlcoholMedicineSuccessResult = (success, status) => ({
    type: ALCOHOL_PLAN_SUSPEND_SUCCESS,
    success,
    status
});

export const suspendAlcoholMedicineFailResult = (error, status) => ({
    type: ALCOHOL_PLAN_SUSPEND_FAIL,
    error,
    status
});

export const addAlcoholPlanSuccessResult = (success, status) => ({
    type: ADD_ALCOHOL_PLAN_SUCCESS,
    success,
    status
});

export const addAlcoholPlanFailResult = (error, status) => ({
    type: ADD_ALCOHOL_PLAN_FAIL,
    error,
    status
});

export const suspendedAlcoholPlanSuccessResult = (success, status) => ({
    type: ALCOHOL_SUSPENDED_PLAN_SUCCESS,
    success,
    status
});

export const suspendedAlcoholPlanFailResult = (error, status) => ({
    type: ALCOHOL_SUSPENDED_PLAN_FAIL,
    error,
    status
});

export const alcoholDispenseHistorySuccessResult = (success, status) => ({
    type: ALCOHOL_DISPENSE_HISTORY_SUCCESS,
    success,
    status
});

export const alcoholDispenseHistoryFailResult = (error, status) => ({
    type: ALCOHOL_DISPENSE_HISTORY_FAIL,
    error,
    status
});
import React, { Component, Fragment } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import './Detox.scss';
import { Link } from 'react-router-dom';
import * as webConst from '../../../utils/WebConstant';
import * as routes from '../../../utils/RouteConstant';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import backArrow from '../../../images/back-arrow.svg';
import ReactDatatable from '@mkikets/react-datatable';
import { getDetoxPlanRequest, getDispenseDetoxDosageRequest, dispenseDosageStatusRequest, setDetoxActiveTab } from '../../../containers/Medicines/Detoxes/DetoxPlanContainer/action';
import moment from "moment";
import DetoxDispenseHistory from './DetoxDispenseHistory';
import DetoxSuspended from './DetoxSuspended/DetoxSuspended';
import { DETOX_PLAN_SUCCESS } from '../../../containers/Medicines/Detoxes/DetoxPlanContainer/Constants';
import { Cube } from 'react-preloaders2';


class Detox extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isDetoxLoading: true,
			clientId: "",
			clientName: "",
			clientCPR: "",
			clientPhoto: "",
			activeDetoxTotalRecord: 0,
			activeDetoxRecords: [],
			activeDetoxColumns: this.activeDetoxDatatable(),
			detoxActiveTab: "active-detox",
			isLoading:true
		};
	}

	componentDidMount() {
		let _this = this;
		let client = webConst.getClient();
		let clientId = client && typeof client !== 'undefined' && typeof client.client_id !== 'undefined' ? client.client_id : 0;
		if (clientId > 0) {
			let clientName = client && typeof client !== 'undefined' && typeof client.client_name !== 'undefined' ? client.client_name : 0;
			let cpr = client && typeof client !== 'undefined' && typeof client.client_socialid !== 'undefined' ? client.client_socialid : 0;
			let photo = client && typeof client !== 'undefined' && typeof client.photo !== 'undefined' ? client.photo : 0;
			this.setState({ clientId: clientId, clientName: clientName, clientCPR: cpr, clientPhoto: photo,isDetoxLoading:true });
			let params = new URLSearchParams();
			params.append('clinic_id', webConst.getClinicId());
			params.append('client_id', webConst.getClientId());
			params.append('user_id', webConst.getUserId());
			this.getActiveDetoxData(params);
		}
		else {
			webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.select-client-message" />);
			this.props.history.push(routes.HOME_ROUTE);
		}
	}

	componentDidUpdate(prevProps) {
		const { detoxSuccess, detoxStatus, detoxType, detoxActiveTab } = this.props;
		if (this.state.detoxActiveTab !== detoxActiveTab && detoxActiveTab !== "") {
			if (detoxActiveTab !== "")
				this.setState({ detoxActiveTab: detoxActiveTab });
		}
		if (detoxStatus === 200) {
			if (detoxType == DETOX_PLAN_SUCCESS) {
				if (prevProps.detoxSuccess !== detoxSuccess) {
					this.setState({
						isDetoxLoading: false,
						isLoading:false
					});

					if (detoxSuccess.records && detoxSuccess.records.length) {
						this.setState({ activeDetoxRecords: detoxSuccess.records, activeDetoxTotalRecord: detoxSuccess.total_records });
					}
				}
			}
		}

		if (detoxStatus === 400) {
			if (this.state.isDetoxLoading) {
				this.setState({
					isDetoxLoading: false,
					isLoading:false
				});
			}
			if(this.state.activeDetoxRecords.length>0)
				this.setState({ activeDetoxRecords: [], activeDetoxTotalRecord: 0 });
		}
	}

	editRecord(record) {
		this.props.history.push(routes.ADD_DETOX_ROUTE + "/" + record.detox_id);
	}

	getActiveDetoxData = (params = "") => {
		this.props.getDetoxPlanRequest(params);
	}

	tableChangeHandler = (data, type) => {
		const params = new URLSearchParams();
		params.append('clinic_id', webConst.getClinicId());
		params.append('client_id', webConst.getClientId());
		params.append('user_id', webConst.getUserId());
		Object.keys(data).map((key) => {
			if (key === "sort_order") {
				if (data[key]) {
					params.append("sort_order", data[key].order !== "false" ? data[key].order : "");
					params.append("sort_column", data[key].column);
				}
			} else {
				params.append(key, data[key]);
			}
		});
		this.getActiveDetoxData(params);
	}

	activeDetoxDatatable = function () {
		let columns = [
			{
				key: "doctor_id",
				text: "Doctor",
				className: "text-capitalize",
				width: 100,
				align: "left",
				sortable: true,
				cell: record => {
					return (
						<Fragment>
							{record.external_doctor ? record.external_doctor : record.doctor}
						</Fragment>
					);
				}
			},
			{
				key: "medicine_name",
				text: "Medicine",
				align: "left",
				className: "text-capitalize",
				sortable: true,
				cell: record => {
					return (
						<Fragment>
							{record.new_medicine_name ? record.new_medicine_name : record.medicine_name}
						</Fragment>
					);
				}
			},
			{
				key: "start_date",
				text: "Start Date",
				align: "left",
				className: "validi-date-text",
				sortable: true,
				cell: record => {
					return (
						<Fragment key={"start_date-" + record.detox_id}>
							{moment(record.start_date).format("DD-MM-YYYY")}
						</Fragment>
					);
				}
			},
			{
				key: "finished_date",
				text: "End Date",
				align: "left",
				className: "validi-date-text",
				sortable: true,
				cell: record => {
					return (
						<Fragment key={"finished_date-" + record.detox_id}>
							{moment(record.finished_date).format("DD-MM-YYYY")}
						</Fragment>
					);
				}
			},
			{
				key: "created_by",
				text: "Created by",
				align: "left",
				sortable: true,
				className: 'text-capitalize',
				cell: record => {
					return (
						<Fragment key={"staff_name-" + record.detox_id}>
							{record.staff_name}
						</Fragment>
					);
				}
			},
			{
				key: "total_dosage",
				key_column: "total_dosage",
				text: "Total",
				align: "left",
				className: 'validi-mg-text',
				sortable: true,
				cell: record => {
					return (
						<Fragment key={"total_dosage-" + record.detox_id}>
							{record.total_dosage} {record.unit ? webConst.ucfirst(record.unit.replace(/[^a-zA-Z\/]+/g, '')) : "Mg"}
						</Fragment>
					);
				}
			},
			{
				key: "reason",
				key_column: "reason",
				text: "Note",
				align: "left",
				className: 'validi-date-text',
				sortable: true
			},
			{
				key: "action",
				text: "Action",
				className: "action",
				width: 100,
				align: "left",
				sortable: false,
				cell: record => {
					return (
						<Fragment>
							<div className="validi-active-icons-wrapper">
								<i onClick={() => this.editRecord(record)}><img src="/images/edit-icon.svg" alt="" /></i>
								<Link to={"detox-detail/" + record.detox_id} ><img src="/images/view-icon.svg" alt="" /></Link>
							</div>
						</Fragment>
					);
				}
			}
		];
		return columns;
	}

	activeTab = (id) => {
		if (id == 'add-detox') {
			this.props.history.push(routes.ADD_DETOX_ROUTE);
			this.props.setDetoxActiveTab("active-detox");
		}
		else if (id == 'detox-dispense') {
			this.props.history.push(routes.DETOX_DISPENSE_ROUTE);
			this.props.setDetoxActiveTab("detox-dispense");
		}
		else {
			this.props.history.push(routes.DETOX_PLAN_ROUTE);
			this.props.setDetoxActiveTab(id);
		}
	}

	render() {
		return (
			<div className="validi-main-layout validi-detox-medicine-layout">
				<div>
					<div className="container">
						<div className="validi-back-btn-wrapper">
							<Link to={routes.MEDICINE_PLAN_ROUTE}>
								<i>
									<img src={backArrow} />
								</i>
								<Translate id="medicine.back-to-medicine-plan" />
							</Link>
						</div>
						<h1 className="main-title"><Translate id="medicine.detox-plan" /></h1>
						<div className="validi-medicine-wrapper">
							<div className="validi-inner-header-bar">
								<div className="validi-avatar-details-wrepper">
									<div className="validi-avatar-img">
										{this.state.clientPhoto ? <img src={this.state.clientPhoto} alt="" /> : ""}
									</div>
									<div className="validi-avatar-details">
										<h5 className="validi-user-main-title">{this.state.clientName ? this.state.clientName : ""}</h5>
										<p className="validi-paragraph"><Translate id="client-goal.cpr-number" /> : {this.state.clientCPR ? this.state.clientCPR : ""}</p>
									</div>
								</div>
								<div className="validi-select-input">
								</div>
							</div>
							<div className="validi-tabbar-wrapper">
								<Tabs id="controlled-tab-example" defaultActiveKey={this.state.detoxActiveTab} activeKey={this.state.detoxActiveTab} onSelect={(id) => { this.activeTab(id) }}>
									<Tab eventKey="active-detox" title="Detox plans">
										<div className="">
											<div className="validi-tabbar-inner-header">
												<div className="">
													<h4 className="validi-subtitle"><Translate id="medicine.detox-plan" /></h4>
												</div>
											</div>
											<div className="validi-table-data-wrapper">
												<div className='table-responsive-md'>
													<ReactDatatable
														key="detox1"
														className="table"
														dynamic={true}
														config={webConst.dataTableConfig("detox_id")}
														records={this.state.activeDetoxRecords}
														columns={this.state.activeDetoxColumns}
														total_record={this.state.activeDetoxTotalRecord ? this.state.activeDetoxTotalRecord : 0}
														onChange={data => { this.tableChangeHandler(data, "activeDetox") }}
														loading={this.state.isDetoxLoading}
													/>
												</div>
											</div>
										</div>
									</Tab>
									<Tab eventKey="add-detox" title="Add detox plan">
										Add detox plan
									</Tab>
									<Tab eventKey="detox-dispense" title="Detox dispense">
										Detox dispense
									</Tab>
									<Tab eventKey="detox-dispense-history" title="Detox Dispense histories">
										<div className="">
											<div className="validi-tabbar-inner-header">
												<div className="">
													<h4 className="validi-subtitle">Detox dispense histories</h4>
												</div>
											</div>
											<div className="validi-table-data-wrapper">
												<DetoxDispenseHistory />
											</div>
										</div>
									</Tab>
									<Tab eventKey="detox-suspended" title="Suspended Detox">
										<div className="">
											<div className="validi-tabbar-inner-header">
												<div className="">
													<h4 className="validi-subtitle">Suspended Detox</h4>
												</div>
											</div>
											<div className="validi-table-data-wrapper">
												<DetoxSuspended />
											</div>
										</div>
									</Tab>
								</Tabs>
							</div>
						</div>
						<Cube customLoading={this.state.isLoading} color={'#66BB6A'} background="rgba(255,255,255,0.6)" />
					</div>
				</div>
			</div>
		)
	}
}

Detox.defaultProps = {
	clientId: '',
	detoxSuccess: "",
	detoxFail: "",
	detoxStatus: "",
	detoxActiveTab: "active-detox"
};

Detox.propTypes = {
	clientId: PropTypes.any,
	detoxSuccess: PropTypes.any,
	detoxFail: PropTypes.any,
	detoxStatus: PropTypes.any,
	detoxType: PropTypes.any,
	detoxActiveTab: PropTypes.any
};

function mapStateToProps(state) {
	return {
		detoxSuccess: state.detoxPlan.success,
		detoxFail: state.detoxPlan.fail,
		detoxStatus: state.detoxPlan.status,
		detoxType: state.detoxPlan.type,
		clientId: state.appLayout.clientId ? state.appLayout.clientId : "",
		detoxActiveTab: state.detoxPlan.detoxActiveTab
	};
}

const mapDispatchToProps = {
	getDetoxPlanRequest,
	getDispenseDetoxDosageRequest,
	dispenseDosageStatusRequest,
	setDetoxActiveTab
};

export default connect(mapStateToProps, mapDispatchToProps)(Detox);

import {
  PN_PLAN_SUCCESS,
  PN_PLAN_FAIL,
  PN_ACTIVE_TAB,
  PN_DISPENSE_DETAIL_SUCCESS,
  PN_DISPENSE_DETAIL_FAIL,
  PN_DISPENSE_SUCCESS,
  PN_DISPENSE_FAIL,
  PN_PLAN_SUSPEND_SUCCESS,
  PN_PLAN_SUSPEND_FAIL,
  ADD_PN_PLAN_SUCCESS,
  ADD_PN_PLAN_FAIL,
  PN_SUSPENDED_PLAN_SUCCESS,
  PN_SUSPENDED_PLAN_FAIL,
  PN_DISPENSE_HISTORY_SUCCESS,
  PN_DISPENSE_HISTORY_FAIL
  
} from './Constants';

const initialState = {
  isLoading: true,
  pnType: ""
};

export default function pnMedicineReducer(state = initialState, action) {
  switch (action.type) {
    case PN_PLAN_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: '',
        status: action.status,
        type: action.type
      };
    case PN_PLAN_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type: action.type
      };
    case PN_ACTIVE_TAB:
      return {
        ...state,
        pnActiveTab: action.pnType,
        type: action.type
      };
    case PN_DISPENSE_DETAIL_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: '',
        status: action.status,
        type: action.type
      };
    case PN_DISPENSE_DETAIL_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type: action.type
      };
    case PN_DISPENSE_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: '',
        status: action.status,
        type: action.type
      };
    case PN_DISPENSE_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type: action.type
      };
    case PN_PLAN_SUSPEND_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: '',
        status: action.status,
        type: action.type
      };
    case PN_PLAN_SUSPEND_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type: action.type
      };
    case ADD_PN_PLAN_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: '',
        status: action.status,
        type: action.type
      };
    case ADD_PN_PLAN_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type: action.type
      };
    case PN_SUSPENDED_PLAN_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: '',
        status: action.status,
        type: action.type
      };
    case PN_SUSPENDED_PLAN_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type: action.type
      };
      case PN_DISPENSE_HISTORY_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: '',
        status: action.status,
        type: action.type
      };
    case PN_DISPENSE_HISTORY_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type: action.type
      };
    default:
      return state;
  }
}
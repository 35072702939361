import { SET_HOME_LOADING, HOME_SUCCESS, HOME_FAIL } from './Constants';

const initialState = {
  isLoading: true
};
export default function homeReducer(state = initialState, action) {
  switch (action.type) {
    case HOME_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: '',
        status: action.status
      };
    case HOME_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status
      };
    case SET_HOME_LOADING:
      return Object.assign({}, state, {
        isLoading: action.isLoading
      });
    default:
      return state;
  }
}
import { GET_MEDICINE_PLAN_DETAIL_SUCCESS, GET_MEDICINE_PLAN_DETAIL_FAIL, MEDICINE_TYPE_SUCCESS, MEDICINE_TYPE_FAIL, MEDICINE_STRENGTH_SUCCESS, MEDICINE_STRENGTH_FAIL } from './Constants';

const initialState = {
  isLoading: true
};
export default function medicinePlanDetailReducer(state = initialState, action) {
  switch (action.type) {
    case GET_MEDICINE_PLAN_DETAIL_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: '',
        status: action.status,
        type:action.type
      };
    case GET_MEDICINE_PLAN_DETAIL_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type:action.type
      };
    case MEDICINE_TYPE_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: '',
        status: action.status,
        type:action.type
      };
    case MEDICINE_TYPE_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type:action.type
      };
    case MEDICINE_STRENGTH_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: '',
        status: action.status,
        type:action.type
      };
    case MEDICINE_STRENGTH_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type:action.type
      };
    default:
      return state;
  }
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { getGoalJournalRequest } from '../../containers/Goals/GoalContainer/action';
import { getCompletedGoalRequest } from '../../containers/Goals/CompletedGoalContainer/action';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { Tabs, Tab, FormGroup, Label, Input, Spinner, Button, Form, Col, Row, InputGroup, Table } from 'react-bootstrap';
import { Link } from "react-router-dom";
import * as routes from '../../utils/RouteConstant';
import './GoalDetail.scss';
import * as webConst from '../../utils/WebConstant';

class GoalDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'goal': [],
      'clientId': '',
      'goalName': '',
      'activeGoalTabId': '',
      'journal': '',
      'isPartGoalJournal': false,
      'isLoading': false,
      'isExpandGoal': "",
      'isExpandPartGoal': "",
      'completedGoals': [],
      'clients': [],
      'clientName': '',
      'clientCPR': '',
      'clientPhoto': '',
      'goalId': '0',
      'partGoalId': '0',
      'isJournalLoading': {},
      'expandGoal': 'active',
      'expanJournalGoalId': 0,
      'partJournal': '',
      'isShowAllAccodian': false,
      'mainJournal': '',
      'showAllAccodian': [],
      'isCompletedGoal': false
    };
  }

  componentDidMount() {
    let client = webConst.getClient();
    let clientId = client && typeof client !== 'undefined' && typeof client.client_id !== 'undefined' ? client.client_id : 0;
    let clientName = client && typeof client !== 'undefined' && typeof client.client_name !== 'undefined' ? client.client_name : 0;
    let cpr = client && typeof client !== 'undefined' && typeof client.client_socialid !== 'undefined' ? client.client_socialid : 0;
    let photo = client && typeof client !== 'undefined' && typeof client.photo !== 'undefined' ? client.photo : 0;

    if (clientId > 0) {
      let _this = this;
      this.setState({ clientId: clientId, clientName: clientName, clientCPR: cpr, clientPhoto: photo });
      let goalId = 0;
      if (this.props.match && this.props.match.params && this.props.match.params.goalId) {
        goalId = this.props.match.params.goalId;
      }
      if (goalId > 0) {
        const params = new URLSearchParams();
        params.append('goal_id', goalId);
        this.props.getCompletedGoalRequest(params);
      }
    }
  }
  componentDidUpdate(prevProps) {
    const { goalSuccess, goalFail, goalStatus, journalSuccess, journalFail, journalStatus, completedGoalSuccess, completedGoalStatus } = this.props;
    if (goalStatus === 200) {
      if (prevProps.goalSuccess !== goalSuccess) {
        if (this.state.isLoading) {
          this.setState({
            isLoading: false,
          });
        }
        this.setState({ 'goal': goalSuccess.client_goals });
      }
    }
    if (prevProps.goalFail !== goalFail) {
      this.setState({
        isLoading: false,
      });
    }
  }


  createGoalJournal(journals) {
    if (Object.keys(journals).length) {
      return journals.map((journalItem, journalKey) => {
        return <div className="validi-journal-msg" key={"journal-" + journalKey}>
          <p className="validi-sub-paragraph" dangerouslySetInnerHTML={{ __html: journalItem.journal_description }}></p>
          <div className="validi-journal-user-detail">
            <h4 className="validi-subtitle text-capitalize"><Translate id="journal.added-by" /> : {journalItem.username}</h4>
            <p className="validi-paragraph text-capitalize"><Translate id="journal.added-date-time" /> : {journalItem.journal_datetime}</p>
          </div>
        </div>
      });
    } else {
      return <div className="validi-nodata-found">
        <h2><Translate id="alert-message.no-data" /></h2>
      </div>
    }
  }

  handleActiveSubTab = (goalId, partGoalId) => {
    let showAllAccodian = [];
    if (this.state.showAllAccodian.includes(partGoalId)) {
      showAllAccodian.push(goalId);
      this.setState({ showAllAccodian: showAllAccodian });
    }
    else {
      showAllAccodian.push(goalId);
      showAllAccodian.push(partGoalId);
      this.setState({ showAllAccodian: showAllAccodian });
    }
  }

  handleExplandJournal = (goal_id, clinic_id, client_id) => {
    this.setState({ 'expanJournalGoalId': goal_id });
  }

  handleShowAllJournal() {
    let isShowAllAccodian = !this.state.isShowAllAccodian;
    let goals = [];
    if (this.state.goal) {
      this.state.goal.part_goals.map((partItem, index) => {
        goals.push(partItem.id);
      });
      goals.push(this.state.goal.id);
    }
    if (isShowAllAccodian == true)
      this.setState({ isShowAllAccodian: isShowAllAccodian, showAllAccodian: goals });
    else
      this.setState({ isShowAllAccodian: isShowAllAccodian, showAllAccodian: [] });
  }

  render() {
    let goal = {};
    let goalName = "";
    if (this.state.goal && Object.keys(this.state.goal).length) {
      goal = this.state.goal;
      goalName = goal.name;
    }
    return (
      <div className="validi-main-layout ">
        <div className="container">
          <div>
            <h1 className="main-title"><Translate id="client-goal.title" /></h1>
            <div className="validi-medicine-wrapper">
              <div className="validi-inner-header-bar">
                <div className="validi-avatar-details-wrepper">
                  <div className="validi-avatar-img">
                    {this.state.clientPhoto ? <img src={this.state.clientPhoto} alt="" /> : ""}
                  </div>
                  <div className="validi-avatar-details">
                    <h5 className="validi-user-main-title">{this.state.clientName ? this.state.clientName : ""}</h5>
                    <p className="validi-paragraph"><Translate id="client-goal.cpr-number" /> : {this.state.clientCPR ? this.state.clientCPR : ""}</p>
                  </div>
                </div>
              </div>
              <div className="validi-tabbar-wrapper">
                <Card className="mb-2 border-success" variant={"success"}>
                  <Card.Header className={"validi-header-goal"}>
                    <div className='row'>
                      <div className='col-md-6'>
                        <a className="btn text-white"><strong><Translate id="client-goal.title" /></strong></a>
                      </div>
                      <div className='col-md-6'>
                        <div className='d-flex justify-content-lg-end justify-content-md-end justify-content-sm-start'>
                          <button type="button" className="btn btn-sm btn-outline-light m-1" onClick={() => { this.handleShowAllJournal() }}>
                            {!this.state.isShowAllAccodian ? <Translate id="show-all-journal" /> : <Translate id="hide-all-journal" />}
                          </button>
                        </div>
                      </div>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    {goal.topic_name ?
                      <div>
                        <div className="validi-tabbar-inner-header">
                          <div className="">
                            <h4 className="validi-subtitle"><Translate id="client-goal.goal" />  : {goalName} </h4>
                            <p className="validi-paragraph"><Translate id="client-goal.topic-name" /> : {webConst.ucfirst(goal.topic_name)}</p>
                          </div>
                          <div className="">
                            <button type="button" className="validi-theme-btn" onClick={(e) => { this.handleExplandJournal(goal.id, goal.clinic_id, goal.client_id, 0) }}><Translate id="client-goal.expand-journal" /></button>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="validi-journals-block">
                              <h3><Translate id="client-goal.journals" /></h3>
                              {this.createGoalJournal(goal.journals)}
                            </div>
                          </div>
                        </div>
                        {Object.values(goal.part_goals).length ?
                          <div className="validi-part-goals-wrapper" key={"goal-item-" + goal.id}>
                            <h5 className="validi-user-main-title"><Translate id="client-goal.part-goal" /></h5>
                            {
                              goal.part_goals.map((partItem, index) => {
                                return <Accordion className='pb-1' activeKey={this.state.showAllAccodian && this.state.showAllAccodian.includes(partItem.id) ? partItem.id : 0} key={"part-goal-" + partItem.id} onClick={() => { this.handleActiveSubTab(goal.id, partItem.id) }}>
                                  <Accordion.Item eventKey={partItem.id} key={partItem.id}>
                                    <Accordion.Header className='header-part-goal' onClick={() => { this.setState({ partGoalId: partItem.id }) }}>{webConst.ucfirst(partItem.name)}</Accordion.Header>
                                    <Accordion.Body>
                                      <div className="">
                                        <div className="validi-tabbar-inner-header">
                                          <div className="">
                                            <h4 className="validi-subtitle text-capitalize"> <Translate id="client-goal.goal" /> : {partItem.name}</h4>
                                            <p className="validi-paragraph text-capitalize"> <Translate id="client-goal.topic-name" /> : {partItem.topic_name}</p>
                                          </div>
                                          <div className="d-none">
                                            <button className="validi-theme-btn" onClick={e => { this.handleExplandJournal(partItem.id, partItem.clinic_id, partItem.client_id, partItem.id); }}><Translate id="client-goal.expand-journal" /></button>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-md-12">
                                            <div className="validi-journals-block">
                                              <h3><Translate id="client-goal.journals" /></h3>
                                              {this.createGoalJournal(partItem.journals)}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              })
                            }
                          </div>
                          : ""
                        }
                      </div>
                      : ""}
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    goalSuccess: state.completedGoal.success,
    goalFail: state.completedGoal.fail,
    goalStatus: state.completedGoal.status,
    goalType: state.goal.type,
    journalSuccess: state.goal.journalSuccess,
    journalFail: state.goal.journalFail,
    journalStatus: state.goal.journalStatus,
    journalType: state.goal.type
  };
}

const mapDispatchToProps = {
  getCompletedGoalRequest,
  getGoalJournalRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(GoalDetail);

export const LOGIN_ROUTE = '/login';
export const LOGOUT_ROUTE = '/logout';
export const HOME_ROUTE = '/home';
export const CLIENT_ROUTE='/client';
export const TEST_ROUTE='/test';
export const COMPLETED_GOAL_ROUTE='completed-goals';
export const GOAL_ROUTE='/goals';
export const GOAL_DETAIL_ROUTE='/goal-detail';
export const AUTHORIZE_ROUTE='/authorize';
export const JOURNAL_ROUTE='/journals';
export const MEDICINE_PLAN_ROUTE='/medicine-plan';
export const MESSAGE_ROUTE='/messages';
export const MYPROFILE_ROUTE='/myprofile';
export const SETTING_ROUTE='/setting';
export const STOCK_ROUTE='/stock';

export const DETOX_PLAN_ROUTE='/detox-plan';
export const PN_PLAN_ROUTE='/pn-plan';
export const ALCOHOL_PLAN_ROUTE='/alcohol-plan';

export const ADD_DETOX_ROUTE='/add-detox-plan';
export const DETOX_DISPENSE_ROUTE='/detox-dispense';
export const DETOX_DISPENSE_HISTORY_ROUTE='/detox-dispense-history';
export const DETOX_SUSPENDED_ROUTE='/detox-suspended';
export const DETOX_DETAIL_ROUTE='/detox-detail';
export const DETOX_SUSPEND_ROUTE='/detox-suspend';

export const ADD_PN_ROUTE='/add-pn-plan';
export const PN_DISPENSE_ROUTE='/pn-dispense';
export const PN_HISTORY_ROUTE='/pn-history';
export const PN_SUSPENDED_ROUTE='/pn-suspended';

export const ADD_ALCOHOL_ROUTE='/add-alcohol';
export const ALCOHOL_DISPENSE_ROUTE='/alcohol-dispense';
export const ALCOHOL_HISTORY_ROUTE='/alcohol-history';
export const ALCOHOL_SUSPENDED_ROUTE='/alcohol-suspended';
import React, { Component, Fragment } from 'react';
// import Select from "react-select";
import { Tabs, Tab, Table, FormGroup, Label, Input, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Alcohol.scss';
import * as webConst from '../../../utils/WebConstant';
import * as routes from '../../../utils/RouteConstant';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import backArrow from '../../../images/back-arrow.svg';
import ReactDatatable from '@mkikets/react-datatable';
import { getAlcoholMedicinePlanRequest, setAlcoholActiveTab, suspendAlcoholMedicineRequest } from '../../../containers/Medicines/Alcohol/AlcoholPlanContainer/action';
import { ALCOHOL_PLAN_SUCCESS, ALCOHOL_PLAN_FAIL, ALCOHOL_PLAN_SUSPEND_SUCCESS, ALCOHOL_PLAN_SUSPEND_FAIL } from '../../../containers/Medicines/Alcohol/AlcoholPlanContainer/Constants';
import moment from "moment";
import AddAlcohol from '../../Medicine/Alcohol/AddAlcohol';
import AlcoholDispense from './AlcoholDispense/AlcoholDispense';
import AlcoholDispenseHistory from './AlcoholDispenseHistory/AlcoholDispenseHistory';
import SuspendedAlcohol from './SuspendedAlcohol/SuspendedAlcohol';
import SuspendedPN from './SuspendedAlcohol/SuspendedAlcohol';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { Cube } from 'react-preloaders2';

class Alcohol extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isAlcoholLoading: true,
			clientId: "",
			clientName: "",
			clientCPR: "",
			clientPhoto: "",
			activeAlcoholTotalRecord: 0,
			activeAlcoholRecords: [],
			activePnColumns: this.activeDatatable(),
			alcoholActiveTab: "active-alcohol"
		};
	}

	componentDidMount() {
		let _this = this;
		let client = webConst.getClient();
		let clientId = client && typeof client !== 'undefined' && typeof client.client_id !== 'undefined' ? client.client_id : 0;
		if (clientId > 0) {
			let clientName = client && typeof client !== 'undefined' && typeof client.client_name !== 'undefined' ? client.client_name : 0;
			let cpr = client && typeof client !== 'undefined' && typeof client.client_socialid !== 'undefined' ? client.client_socialid : 0;
			let photo = client && typeof client !== 'undefined' && typeof client.photo !== 'undefined' ? client.photo : 0;
			this.setState({ clientId: clientId, clientName: clientName, clientCPR: cpr, clientPhoto: photo, isAlcoholLoading: true });
			this.getActiveAlcohol();
			this.activeTab("active-alcohol");
		}
		else {
			webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.select-client-message" />);
			this.props.history.push(routes.HOME_ROUTE);
		}
	}

	componentDidUpdate(prevProps) {
		const { alcoholSuccess, alcoholStatus, alcoholType, alcoholActiveTab } = this.props;

		if (this.state.alcoholActiveTab !== alcoholActiveTab && alcoholActiveTab !== "") {
			this.setState({ alcoholActiveTab: alcoholActiveTab });
		}
		if ((prevProps.alcoholActiveTab !== "" && prevProps.alcoholActiveTab !== "active-alcohol" && alcoholActiveTab === "active-alcohol")) {
			this.setState({ isAlcoholLoading: true });
			this.getActiveAlcohol();
			this.activeTab("active-alcohol");
		}
		
		if (alcoholStatus === 200) {
			if (alcoholType === ALCOHOL_PLAN_SUCCESS) {
				if (prevProps.alcoholSuccess !== alcoholSuccess) {
					this.setState({
						isAlcoholLoading: false,
					});

					if (alcoholSuccess.records && alcoholSuccess.records.length) {
						this.setState({ activeAlcoholRecords: alcoholSuccess.records, activeAlcoholTotalRecord: alcoholSuccess.total_records });
					}
				}
			}
			else if (alcoholType === ALCOHOL_PLAN_SUSPEND_SUCCESS) {
				if (prevProps.alcoholSuccess !== alcoholSuccess) {
					this.setState({
						isAlcoholLoading: false,
					});
					webConst.toaster(webConst.TOAST_SUCCESS, <Translate id="medicine.suspend-message" />);
				}
			}
		}

		if (alcoholStatus === 400) {
			if (this.state.isAlcoholLoading) {
				this.setState({
					isAlcoholLoading: false,
				});
			}

			if (alcoholType === ALCOHOL_PLAN_FAIL && this.state.activeAlcoholRecords.length > 0) {
				this.setState({ activeAlcoholRecords: [], activeAlcoholTotalRecord: 0 });
			}

			if (alcoholType === ALCOHOL_PLAN_SUSPEND_SUCCESS) {
				webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.something-wrong" />);
			}

		}
	}

	getActiveAlcoholData = (params) => {
		this.props.getAlcoholMedicinePlanRequest(params);
	}

	getActiveAlcohol = () => {
		let params = new URLSearchParams();
		params.append('clinic_id', webConst.getClinicId());
		params.append('client_id', webConst.getClientId());
		params.append('user_id', webConst.getUserId());
		this.props.getAlcoholMedicinePlanRequest(params);
	}

	tableChangeHandler = (data) => {
		const params = new URLSearchParams();
		params.append('clinic_id', webConst.getClinicId());
		params.append('client_id', webConst.getClientId());
		params.append('user_id', webConst.getUserId());
		Object.keys(data).map((key) => {
			if (key === "sort_order") {
				if (data[key]) {
					params.append("sort_order", data[key].order !== "false" ? data[key].order : "");
					params.append("sort_column", data[key].column);
				}
			} else {
				params.append(key, data[key]);
			}
		});

		this.getActiveAlcoholData(params);
	}

	activeDatatable = function () {
		let _this = this;
		let columns = [
			{
				key: "medicine_name",
				text: <Translate id="medicine.medicine" />,
				align: "left",
				className: "text-capitalize",
				sortable: true,
				cell: record => {
					return (
						<Fragment>
							{record.medicine_name}
						</Fragment>
					);
				}
			},
			{
				key: "total_dosage",
				text: <Translate id="medicine.total-dosage" />,
				align: "left",
				className: "text-capitalize validi-mg-text",
				sortable: true,
				cell: record => {
					return (
						<Fragment>
							{record.total_amount}  {record.unit ? webConst.ucfirst(record.unit.replace(/[^a-zA-Z\/]+/g, '')) : ""}
						</Fragment>
					);
				}
			},
			{
				key: "start_date",
				text: <Translate id="medicine.start-date" />,
				align: "left",
				className: "text-capitalize validi-date-text",
				sortable: true,
				cell: record => {
					return (
						<Fragment>
							{record.start_date}
						</Fragment>
					);
				}
			},
			{
				key: "total_given_dosage",
				text: <Translate id="medicine.today-give-status" />,
				align: "left",
				className: "text-capitalize validi-mg-text",
				sortable: true,
				cell: record => {
					return (
						<Fragment>
							{record.total_given_dosage > 0 ? record.total_given_dosage : 0}  {record.unit ? webConst.ucfirst(record.unit.replace(/[^a-zA-Z\/]+/g, '')) : ""}
						</Fragment>
					);
				}
			},
			{
				key: "created_by",
				text: <Translate id="medicine.created-by" />,
				align: "left",
				className: "text-capitalize",
				sortable: true,
				cell: record => {
					return (
						<Fragment>
							{record.created_by}
						</Fragment>
					);
				}
			},
			{
				key: "remarks",
				text: <Translate id="medicine.note" />,
				align: "left",
				className: "text-capitalize validi-date-text",
				sortable: true,
				cell: record => {
					return (
						<Fragment>
							{record.remarks}
						</Fragment>
					);
				}
			},
			{
				key: "detox-action",
				text: "Action",
				className: "action validi-mg-text",
				width: 100,
				align: "left",
				sortable: false,
				cell: record => {
					return (
						<Fragment>
							<div className="validi-mg-text cursor-pointer">
								<span onClick={() => { _this.dispenseMedicine(record) }}>Give</span>&nbsp;|&nbsp;
								<span onClick={() => { _this.suspendMedicine(record) }}>Suspend</span>
							</div>
						</Fragment>
					);
				}
			}
		];
		return columns;
	}

	activeTab = (id) => {
		this.props.setAlcoholActiveTab(id);
	}

	dispenseMedicine = (record) => {
		this.props.setAlcoholActiveTab("alcohol-dispense");
		this.props.history.push({
			pathname: routes.ALCOHOL_DISPENSE_ROUTE + "/" + record.id,
			state: record
		});
		// this.history.push(routes.ALCOHOL_DISPENSE_ROUTE,[{"pn-id":record.id}]);
	}

	suspendMedicine = (record) => {
		let alcoholId = record.id;
		let medicineId = record.medicine_id;
		let clientId = record.client_id;
		let _this = this;
		let options = {
			closeOnEscape: true,
			closeOnClickOutside: true,
			customUI: ({ onClose }) => {
				return (
					<Card className="pt-3 p-2">
						<Card.Body>
							<Card.Text className='pb-2'>
								<strong>{"Do you want to suspend this alcohol medicine plan?"}</strong>
							</Card.Text>
							<div className="d-flex flex-row bd-highlight mb-3">
								<div className="p-2 bd-highlight">
									<button className='validi-theme-btn align-baseline'
										onClick={() => {
											if (alcoholId) {
												const params = new URLSearchParams();
												params.append('alcohol_id', alcoholId);
												params.append('medicine_id', medicineId);
												params.append('client_id', clientId);
												_this.props.suspendAlcoholMedicineRequest(params);
												onClose();
												_this.setState({ activeAlcoholRecords: [] });
												const rows = _this.state.activeAlcoholRecords.filter(item => item.id !== alcoholId)
												_this.setState({ activeAlcoholRecords: rows });
											}
										}}
									> Ja</button>
								</div>
								<div className="p-2 bd-highlight"><button className='validi-theme-btn align-middle'
									onClick={() => {
										onClose();
									}}>Ingen</button></div>
							</div>
						</Card.Body>
					</Card>
				);
			},
			overlayClassName: "overlay-custom"
		};
		confirmAlert(options);
	}

	render() {
		return (
			<div className="validi-main-layout validi-pn-plan-layout-main">
				<div>
					<div className="container">
						<div className="validi-back-btn-wrapper">
							<Link to={routes.MEDICINE_PLAN_ROUTE}>
								<i>
									<img src={backArrow} />
								</i>
								<Translate id="medicine.back-to-medicine-plan" />
							</Link>
						</div>
						<h1 className="main-title"><Translate id="medicine.alcohol-plan" /></h1>
						<div className="validi-medicine-wrapper">
							<div className="validi-inner-header-bar">
								<div className="validi-avatar-details-wrepper">
									<div className="validi-avatar-img">
										{this.state.clientPhoto ? <img src={this.state.clientPhoto} alt="" /> : ""}
									</div>
									<div className="validi-avatar-details">
										<h5 className="validi-user-main-title">{this.state.clientName ? this.state.clientName : ""}</h5>
										<p className="validi-paragraph"><Translate id="client-goal.cpr-number" /> : {this.state.clientCPR ? this.state.clientCPR : ""}</p>
									</div>
								</div>
								<div className="validi-select-input">

								</div>
							</div>
							<div className="validi-tabbar-wrapper">
								<Tabs id="pn-tabs" defaultActiveKey={this.state.alcoholActiveTab} activeKey={this.state.alcoholActiveTab} onSelect={(id) => { this.activeTab(id) }}>
									<Tab eventKey="active-alcohol" title={<Translate id="medicine.alcohol-plan" />}>
										<div className="">
											<div className="validi-tabbar-inner-header">
												<div className="">
													<h4 className="validi-subtitle"><Translate id="medicine.alcohol-plan" /></h4>
												</div>
											</div>
											<div className="validi-table-data-wrapper">
												<div className='table-responsive-md'>
													<ReactDatatable
														key="alcohol"
														className="table"
														dynamic={true}
														config={webConst.dataTableConfig("id")}
														records={this.state.activeAlcoholRecords}
														columns={this.state.activePnColumns}
														total_record={this.state.activeAlcoholTotalRecord ? this.state.activeAlcoholTotalRecord : 0}
														onChange={data => { this.tableChangeHandler(data) }}
														loading={this.state.isAlcoholLoading}
													/>
												</div>
											</div>
										</div>
									</Tab>
									<Tab eventKey="add-alcohol" title={<Translate id="medicine.add-alcohol-plan" />}>
										<AddAlcohol />
									</Tab>
									<Tab eventKey="alcohol-dispense-history" title="Alcohol Dispense histories">
										<AlcoholDispenseHistory />
									</Tab>
									<Tab eventKey="alcohol-suspended" title="Suspended Alcohol plan">
										<SuspendedAlcohol />
									</Tab>
								</Tabs>
							</div>
						</div>
						<Cube customLoading={this.state.isAlcoholLoading} color={'#66BB6A'} background="rgba(255,255,255,0.6)" />
					</div>
				</div>
			</div>
		)
	}
}

Alcohol.defaultProps = {
	clientId: '',
	alcoholActiveTab: "active-alcohol",
	alcoholType: "",
	alcoholSuccess: "",
	alcoholFail: "",
};

Alcohol.propTypes = {
	clientId: PropTypes.any,
	alcoholActiveTab: PropTypes.any,
	alcoholType: PropTypes.any,
	alcoholSuccess: PropTypes.any,
	alcoholFail: PropTypes.any,
};

function mapStateToProps(state) {
	return {
		clientId: state.appLayout.clientId ? state.appLayout.clientId : "",
		alcoholActiveTab: state.alcoholPlan.alcoholActiveTab,
		alcoholSuccess: state.alcoholPlan.success,
		alcoholType: state.alcoholPlan.type,
		alcoholStatus: state.alcoholPlan.status
	};
}

const mapDispatchToProps = {
	getAlcoholMedicinePlanRequest,
	setAlcoholActiveTab,
	suspendAlcoholMedicineRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(Alcohol);

import { CLIENT_SUCCESS, CLIENT_FAIL,CLIENT_TOOLTIP_SUCCESS,CLIENT_TOOLTIP_FAIL } from './Constants';
import axios from 'axios';
import { withHeader } from '../../service/ApiConfig';
import * as webConst from '../../utils/WebConstant';
const axiosDefaults = require('axios/lib/defaults');
axiosDefaults.baseURL = withHeader.baseURL;
axiosDefaults.headers = withHeader.headers;
axiosDefaults.timeout = withHeader.timeOut;

export const getClientListRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.CLIENT_LIST}`,value)
        .then((response) => {
            if(response.data.responseCode===200)
            {
                dispatch(clientSuccessResult(response.data.responseBody,response.status));
            }
            else{
                dispatch(clientFailResult(response.data.responseMessage,response.data.responseCode));
            }
        })
        .catch((error) => {
            dispatch(clientFailResult(error.data,error.status));
        });
    }
};

export const getClientTooltipRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.GET_CLIENT_TOOLTIP}`,value)
        .then((response) => {
            if(response.data.responseCode===200)
            {
                dispatch(clientTooltipSuccessResult(response.data.responseBody,response.status));
            }
            else{
                dispatch(clientTooltipFailResult(response.data.responseMessage,response.data.responseCode));
            }
        })
        .catch((error) => {
            dispatch(clientTooltipFailResult(error.data,error.status));
        });
    }
};

/*
    API RESULT
 */


export const clientSuccessResult = (success,status) => ({
    type: CLIENT_SUCCESS,
    success,
    status
});

export const clientFailResult = (error,status) => ({
    type: CLIENT_FAIL,
    error,
    status
});

export const clientTooltipSuccessResult = (success,status) => ({
    type: CLIENT_TOOLTIP_SUCCESS,
    success,
    status
});

export const clientTooltipFailResult = (error,status) => ({
    type: CLIENT_TOOLTIP_FAIL,
    error,
    status
});

import React, { Component } from 'react';
import './Forget.scss';
import { connect } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
class Forget extends Component {

	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div className="validi-login-layout-main validi-forget-layout">
				<div className="validi-login-form-wrapper">
					<div className="validi-logo-block">
						<img src="/images/logo.png" alt="" />
						<h3 className="main-title">App Validi</h3>
					</div>
					<div className="validi-forget-details">
						<h4>Forgot Your Password?</h4>
						<p>Enter the email address associated with your account.</p>
					</div>
					<Form>
						<Form.Group className="form-group" controlId="formBasicPassword">
						<Form.Label>Email Address</Form.Label>
						<Form.Control
							type="text"
							name="email"
							placeholder="Email Address"
						/>
						</Form.Group>
						<button type="submit" className="validi-theme-btn">Send</button>
					</Form>
				</div>
			</div>
		);
	}
}

export default Forget;

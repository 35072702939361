import { GET_MEDICINE_PLAN_DETAIL_SUCCESS, GET_MEDICINE_PLAN_DETAIL_FAIL, MEDICINE_TYPE_SUCCESS, MEDICINE_TYPE_FAIL, MEDICINE_STRENGTH_SUCCESS, MEDICINE_STRENGTH_FAIL } from './Constants';
import axios from 'axios';
import { withHeader } from '../../../service/ApiConfig';
import * as webConst from '../../../utils/WebConstant';
const axiosDefaults = require('axios/lib/defaults');
axiosDefaults.baseURL = withHeader.baseURL;
axiosDefaults.headers = withHeader.headers;
axiosDefaults.timeout = withHeader.timeOut;

export const getMedicinePlanDetailRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.GET_MEDICINE_PLAN_DETAIL}`, value)
            .then((response) => {
                if (response.data.responseCode === 200) {
                    dispatch(medicineSuccessResult(response.data.responseBody, response.status));
                }
                else {
                    dispatch(medicineFailResult(response.data.responseMessage, response.data.responseCode));
                }
            })
            .catch((error) => {
                dispatch(medicineFailResult(error.data, error.status));
            });
    }
};

export const getMedicineTypeRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.GET_MEDICINE_TYPE}`, value)
            .then((response) => {
                if (response.data.responseCode === 200) {
                    dispatch(medicineTypeSuccessResult(response.data.responseBody, response.status));
                }
                else {
                    dispatch(medicineTypeFailResult(response.data.responseMessage, response.data.responseCode));
                }
            })
            .catch((error) => {
                dispatch(medicineTypeFailResult(error.data, error.status));
            });
    }
};

export const getMedicineStrengthRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.GET_MEDICINE_STRENGTH}`, value)
            .then((response) => {
                if (response.data.responseCode === 200) {
                    dispatch(medicineStrengthSuccessResult(response.data.responseBody, response.status));
                }
                else {
                    dispatch(medicineStrengthFailResult(response.data.responseMessage, response.data.responseCode));
                }
            })
            .catch((error) => {
                dispatch(medicineStrengthFailResult(error.data, error.status));
            });
    }
};

/*
    API RESULT
 */


export const medicineSuccessResult = (success, status) => ({
    type: GET_MEDICINE_PLAN_DETAIL_SUCCESS,
    success,
    status
});

export const medicineFailResult = (error, status) => ({
    type: GET_MEDICINE_PLAN_DETAIL_FAIL,
    error,
    status
});


export const medicineTypeSuccessResult = (success, status) => ({
    type: MEDICINE_TYPE_SUCCESS,
    success,
    status
});

export const medicineTypeFailResult = (error, status) => ({
    type: MEDICINE_TYPE_FAIL,
    error,
    status
});

export const medicineStrengthSuccessResult = (success, status) => ({
    type: MEDICINE_STRENGTH_SUCCESS,
    success,
    status
});

export const medicineStrengthFailResult = (error, status) => ({
    type: MEDICINE_STRENGTH_FAIL,
    error,
    status
});
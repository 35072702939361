import { TOPIC_SUCCESS, TOPIC_FAIL } from './Constants';
import axios from 'axios';
import { withHeader } from '../../service/ApiConfig';
import * as webConst from '../../utils/WebConstant';
const axiosDefaults = require('axios/lib/defaults');
axiosDefaults.baseURL = withHeader.baseURL;
axiosDefaults.headers = withHeader.headers;
axiosDefaults.timeout = withHeader.timeOut;

export const saveTopicRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.ADD_TOPIC}`, value)
            .then((response) => {
                if (response.data.responseCode === 200) {
                    dispatch(topicSaveSuccessResult(response.data.responseBody, response.status));
                }
                else {
                    dispatch(topicSaveFailResult(response.data.responseMessage, response.data.responseCode));
                }
            })
            .catch((error) => {
                dispatch(topicSaveFailResult(error.data, error.status));
            });
    }
};


/*
    API RESULT
 */

export const topicSaveSuccessResult = (success, status) => ({
    type: TOPIC_SUCCESS,
    success,
    status
});

export const topicSaveFailResult = (error, status) => ({
    type: TOPIC_FAIL,
    error,
    status
});



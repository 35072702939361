import { 
        PN_PLAN_SUCCESS,
        PN_PLAN_FAIL,
        PN_ACTIVE_TAB,
        PN_DISPENSE_DETAIL_SUCCESS,
        PN_DISPENSE_DETAIL_FAIL,
        PN_DISPENSE_SUCCESS,
        PN_DISPENSE_FAIL,
        PN_PLAN_SUSPEND_SUCCESS,
        PN_PLAN_SUSPEND_FAIL,
        ADD_PN_PLAN_SUCCESS,
        ADD_PN_PLAN_FAIL,
        PN_SUSPENDED_PLAN_SUCCESS,
        PN_SUSPENDED_PLAN_FAIL,
        PN_DISPENSE_HISTORY_SUCCESS,
        PN_DISPENSE_HISTORY_FAIL
    } from './Constants';
import axios from 'axios';
import { withHeader } from '../../../../service/ApiConfig';
import * as webConst from '../../../../utils/WebConstant';
const axiosDefaults = require('axios/lib/defaults');
axiosDefaults.baseURL = withHeader.baseURL;
axiosDefaults.headers = withHeader.headers;
axiosDefaults.timeout = withHeader.timeOut;

export const getPnMedicinePlanRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.GET_PN_PLAN}`, value)
            .then((response) => {
                if (response.data.responseCode === 200) {
                    dispatch(pnPlanSuccessResult(response.data.responseBody, response.status));
                }
                else {
                    dispatch(pnPlanFailResult(response.data.responseMessage, response.data.responseCode));
                }
            })
            .catch((error) => {
                dispatch(pnPlanFailResult(error.data, error.status));
            });
    }
};


export const getPnDispenseDetailRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.GET_PN_DISPENSE_DETAIL_PLAN}`, value)
            .then((response) => {
                if (response.data.responseCode === 200) {
                    dispatch(pnDispenseDetailSuccessResult(response.data.responseBody, response.status));
                }
                else {
                    dispatch(pnDispenseDetailFailResult(response.data.responseMessage, response.data.responseCode));
                }
            })
            .catch((error) => {
                dispatch(pnDispenseDetailFailResult(error.data, error.status));
            });
    }
};

export const saveDispenseDosageRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.PN_DISPENSE_DOSAGE}`, value)
            .then((response) => {
                if (response.data.responseCode === 200) {
                    dispatch(pnDispenseSuccessResult(response.data.responseBody, response.status));
                }
                else {
                    dispatch(pnDispenseFailResult(response.data.responseMessage, response.data.responseCode));
                }
            })
            .catch((error) => {
                dispatch(pnDispenseFailResult(error.data, error.status));
            });
    }
};

export const suspendPnMedicineRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.PN_SUSPEND}`, value)
            .then((response) => {
                if (response.data.responseCode === 200) {
                    dispatch(suspendPnMedicineSuccessResult(response.data.responseBody, response.status));
                }
                else {
                    dispatch(suspendPnMedicineFailResult(response.data.responseMessage, response.data.responseCode));
                }
            })
            .catch((error) => {
                dispatch(suspendPnMedicineFailResult(error.data, error.status));
            });
    }
};

export const addPnMedicinePlanRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.ADD_PN_PLAN}`, value)
            .then((response) => {
                if (response.data.responseCode === 200) {
                    dispatch(addPnPlanSuccessResult(response.data.responseBody, response.status));
                }
                else {
                    dispatch(addPnPlanFailResult(response.data.responseMessage, response.data.responseCode));
                }
            })
            .catch((error) => {
                dispatch(addPnPlanFailResult(error.data, error.status));
            });
    }
};

export const getSuspendedPnMedicinePlanHistoryRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.PN_SUSPENDED_PLAN_HISTORY}`, value)
            .then((response) => {
                if (response.data.responseCode === 200) {
                    dispatch(suspendedPnPlanSuccessResult(response.data.responseBody, response.status));
                }
                else {
                    dispatch(suspendedPnPlanFailResult(response.data.responseMessage, response.data.responseCode));
                }
            })
            .catch((error) => {
                dispatch(suspendedPnPlanFailResult(error.data, error.status));
            });
    }
};


export const getPnDispenseHistoryRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.PN_DISPENSE_HISTORY}`, value)
            .then((response) => {
                if (response.data.responseCode === 200) {
                    dispatch(pnDispenseHistorySuccessResult(response.data.responseBody, response.status));
                }
                else {
                    dispatch(pnDispenseHistoryFailResult(response.data.responseMessage, response.data.responseCode));
                }
            })
            .catch((error) => {
                dispatch(pnDispenseHistoryFailResult(error.data, error.status));
            });
    }
};

/*
    API RESULT
 */

export const pnPlanSuccessResult = (success, status) => ({
    type: PN_PLAN_SUCCESS,
    success,
    status
});

export const pnPlanFailResult = (error, status) => ({
    type: PN_PLAN_FAIL,
    error,
    status
});

export const setPnActiveTab = (pnType) => ({
    type: PN_ACTIVE_TAB,
    pnType
});

export const pnDispenseDetailSuccessResult = (success, status) => ({
    type: PN_DISPENSE_DETAIL_SUCCESS,
    success,
    status
});

export const pnDispenseDetailFailResult = (error, status) => ({
    type: PN_DISPENSE_DETAIL_FAIL,
    error,
    status
});

export const pnDispenseSuccessResult = (success, status) => ({
    type: PN_DISPENSE_SUCCESS,
    success,
    status
});

export const pnDispenseFailResult = (error, status) => ({
    type: PN_DISPENSE_FAIL,
    error,
    status
});

export const suspendPnMedicineSuccessResult = (success, status) => ({
    type: PN_PLAN_SUSPEND_SUCCESS,
    success,
    status
});

export const suspendPnMedicineFailResult = (error, status) => ({
    type: PN_PLAN_SUSPEND_FAIL,
    error,
    status
});

export const addPnPlanSuccessResult = (success, status) => ({
    type: ADD_PN_PLAN_SUCCESS,
    success,
    status
});

export const addPnPlanFailResult = (error, status) => ({
    type: ADD_PN_PLAN_FAIL,
    error,
    status
});

export const suspendedPnPlanSuccessResult = (success, status) => ({
    type: PN_SUSPENDED_PLAN_SUCCESS,
    success,
    status
});

export const suspendedPnPlanFailResult = (error, status) => ({
    type: PN_SUSPENDED_PLAN_FAIL,
    error,
    status
});

export const pnDispenseHistorySuccessResult = (success, status) => ({
    type: PN_DISPENSE_HISTORY_SUCCESS,
    success,
    status
});

export const pnDispenseHistoryFailResult = (error, status) => ({
    type: PN_DISPENSE_HISTORY_FAIL,
    error,
    status
});
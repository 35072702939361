export const DETOX_PLAN_SUCCESS = 'DETOX_PLAN_SUCCESS';
export const DETOX_PLAN_FAIL = 'DETOX_PLAN_FAIL';

export const DETOX_PLAN_DETAIL_SUCCESS = 'DETOX_PLAN_DETAIL_SUCCESS';
export const DETOX_PLAN_DETAIL_FAIL = 'DETOX_PLAN_DETAIL_FAIL';

export const UPDATE_DETOX_DOSAGE_SUCCESS = 'UPDATE_DETOX_DOSAGE_SUCCESS';
export const UPDATE_DETOX_DOSAGE_DETAIL_FAIL = 'UPDATE_DETOX_DOSAGE_DETAIL_FAIL';

export const GET_DISPENSE_DOSAGE_SUCCESS = 'GET_DISPENSE_DOSAGE_SUCCESS';
export const GET_DISPENSE_DOSAGE_FAIL = 'GET_DISPENSE_DOSAGE_FAIL';

export const DISPENSE_DOSAGE_STATUS_SUCCESS = 'DISPENSE_DOSAGE_STATUS_SUCCESS';
export const DISPENSE_DOSAGE_STATUS_FAIL = 'DISPENSE_DOSAGE_STATUS_FAIL';

export const DETOX_DISPENSE_HISTORY_SUCCESS = 'DETOX_DISPENSE_HISTORY_SUCCESS';
export const DETOX_DISPENSE_HISTORY_FAIL = 'DETOX_DISPENSE_HISTORY_FAIL';

export const DETOX_SUSPENDED_HISTORY_SUCCESS = 'DETOX_SUSPENDED_HISTORY_SUCCESS';
export const DETOX_SUSPENDED_HISTORY_FAIL = 'DETOX_SUSPENDED_HISTORY_FAIL';

export const DETOX_SUSPEND_SUCCESS = 'DETOX_SUSPEND_SUCCESS';
export const DETOX_SUSPEND_FAIL = 'DETOX_SUSPEND_FAIL';

export const DETOX_ACTIVE_TAB = 'DETOX_ACTIVE_TAB';
export const DETOX_ID='DETOX_ID';

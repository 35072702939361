/**
 * Create the store with dynamic reducers
 */
import React from 'react';
import { createStore, applyMiddleware } from 'redux';
import * as webConst from '../utils/WebConstant';
import thunk from 'redux-thunk';
import createReducer from './reducers';
import { Translate } from 'react-localize-redux';
// import { Redirect } from "react-router-dom";
// import logger from 'redux-logger';

const loggingMiddleware = (store) => (next) => (action) => {
    let lastLoginTime = webConst.getLoginTime();
    let currentTime = new Date().valueOf();
    if (lastLoginTime < currentTime) {
        if (action.status === 401) {
            webConst.removeUserToken();
            webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.login-message" />);
            next(action);
            // <Redirect to={"login"}/>
            //window.location.href = 'login';
        }
        next(action);
    }
    else if (typeof action.error != 'undefined' && typeof action.error != 'undefined') {
        if (action.type === 'LOGIN_FAIL' && action.status === 401) {
            webConst.removeUserToken();
            webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.login-message" />);
        }
        else if (action.status === 401) {
            webConst.removeUserToken();
            webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.login-message" />);
           // this.props.location.push('/login');
        }
        next(action);
    }
    else
        next(action);
}


export default function configureStore() {
    const store = createStore(createReducer,
        applyMiddleware(thunk, loggingMiddleware)
        // applyMiddleware(thunk, logger,loggingMiddleware)
    );
    return store;
}

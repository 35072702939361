import React, { Component, Fragment } from 'react';
// import Select from "react-select";
import { Tabs, Tab, Table, FormGroup, Label, Input, Popover, OverlayTrigger } from 'react-bootstrap';
import './DetoxDispenseHistory.scss';
import { Link } from 'react-router-dom';
import * as webConst from '../../../../utils/WebConstant';
import * as routes from '../../../../utils/RouteConstant';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { detoxDispenseHistoryRequest, setDetoxActiveTab } from '../../../../containers/Medicines/Detoxes/DetoxPlanContainer/action';
import moment from "moment";
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import { DETOX_DISPENSE_HISTORY_SUCCESS } from '../../../../containers/Medicines/Detoxes/DetoxPlanContainer/Constants';
import backArrow from '../../../../images/back-arrow.svg';
import ReactDatatable from '@mkikets/react-datatable';
import { propTypes } from 'react-bootstrap/esm/Image';

class DetoxDispenseHistory extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isDetoxLoading: true,
			clientId: '',
			clientName: "",
			clientCPR: "",
			clientPhoto: "",
			detoxTotalRecord: 0,
			detoxRecords: [],
			detoxColumns: this.detoxDatatable(),
			detoxActiveTab: ""
		};
	}

	componentDidMount() {
		let _this = this;
		let client = webConst.getClient();
		let clientId = client && typeof client !== 'undefined' && typeof client.client_id !== 'undefined' ? client.client_id : 0;
		if (clientId > 0) {
			let clientName = client && typeof client !== 'undefined' && typeof client.client_name !== 'undefined' ? client.client_name : 0;
			let cpr = client && typeof client !== 'undefined' && typeof client.client_socialid !== 'undefined' ? client.client_socialid : 0;
			let photo = client && typeof client !== 'undefined' && typeof client.photo !== 'undefined' ? client.photo : 0;
			this.setState({ clientId: clientId, clientName: clientName, clientCPR: cpr, clientPhoto: photo });
		}
		else {
			webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.select-client-message" />);
			this.props.history.push(routes.HOME_ROUTE);
		}
	}

	componentDidUpdate(prevProps) {
		const { detoxSuccess, detoxStatus, detoxType, detoxActiveTab } = this.props;
		if (this.state.detoxActiveTab != detoxActiveTab) {
			this.setState({ detoxActiveTab: detoxActiveTab });
			if (detoxActiveTab === "detox-dispense-history") {
				let params = new URLSearchParams();
				params.append('clinic_id', webConst.getClinicId());
				params.append('client_id', webConst.getClientId());
				params.append('user_id', webConst.getUserId());
				this.getDetoxDispenseHistory(params);
				this.setState({ detoxActiveTab: detoxActiveTab, isDetoxLoading: true });
			}
		}



		if (detoxStatus === 200) {
			if (detoxType == DETOX_DISPENSE_HISTORY_SUCCESS) {
				if (prevProps.detoxSuccess !== detoxSuccess) {
					this.setState({
						isDetoxLoading: false,
					});

					if (detoxSuccess.records && detoxSuccess.records.length) {
						this.setState({ detoxRecords: detoxSuccess.records, detoxTotalRecord: detoxSuccess.total_records });
					}
				}
			}
		}

		if (detoxStatus === 400) {
			if (this.state.isDetoxLoading) {
				this.setState({
					isDetoxLoading: false,
				});
			}
			if(this.state.detoxRecords.length>0){
				this.setState({ detoxRecords: [], detoxTotalRecord: 0 });
			}
		}
	}

	getDetoxDispenseHistory = (params = "") => {
		this.props.detoxDispenseHistoryRequest(params);
	}

	tableChangeHandler = (data) => {
		const params = new URLSearchParams();
		params.append('clinic_id', webConst.getClinicId());
		params.append('client_id', webConst.getClientId());
		params.append('user_id', webConst.getUserId());
		Object.keys(data).map((key) => {
			if (key === "sort_order") {
				if (data[key]) {
					params.append("sort_order", data[key].order !== "false" ? data[key].order : "");
					params.append("sort_column", data[key].column);
				}
			} else {
				params.append(key, data[key]);
			}
		});

		this.getDetoxDispenseHistory(params);
		this.setState({ isDetoxLoading: true });
	}

	detoxDatatable = function () {
		let columns = [
			{
				key: "dosage_date",
				text: "Dosage Date",
				align: "left",
				className: "validi-date-text",
				sortable: true,
				cell: record => {
					return (
						<Fragment key={"dosage_date-" + record.dosage_id}>
							{moment(record.dosage_date).format("DD-MM-YYYY")}
						</Fragment>
					);
				}
			},
			{
				key: "dipensed_time",
				text: "Dispense date",
				align: "left",
				className: "validi-date-text",
				sortable: true,
				cell: record => {
					return (
						<Fragment key={"dipensed_time-" + record.dosage_id}>
							{moment(record.dipensed_time).format("DD-MM-YYYY HH:mm")}
						</Fragment>
					);
				}
			},
			{
				key: "slot_time",
				text: "Time",
				align: "left",
				className: "validi-date-text",
				sortable: true,
				cell: record => {
					return (
						<Fragment key={"slot_time-" + record.dosage_id}>
							{moment(record.slot_time, "H:m:s").format("HH:mm")}
						</Fragment>
					);
				}
			},
			{
				key: "medicine",
				text: "Medicine",
				align: "left",
				className: "text-capitalize",
				sortable: true
			},
			{
				key: "dosage_amount",
				text: "Dosage Amount",
				align: "left",
				className: "validi-mg-text",
				sortable: true,
				cell: record => {
					return (
						<Fragment key={"dosage_amount-" + record.dosage_id}>
							{parseFloat(record.dosage_amount).toFixed(1)} {record.unit !== "" ? record.unit.replace(/[^a-zA-Z\/]+/g, '') : "mg"}
						</Fragment>
					);
				}
			},
			{
				key: "display_name",
				text: "Dispense By",
				align: "left",
				className: "text-capitalize validi-date-text",
				sortable: true
			}
		];
		return columns;
	}

	render() {
		return (
			<ReactDatatable
				className="table"
				dynamic={true}
				config={webConst.dataTableConfig("dosage_id")}
				records={this.state.detoxRecords}
				columns={this.state.detoxColumns}
				total_record={this.state.detoxTotalRecord ? this.state.detoxTotalRecord : 0}
				onChange={data => { this.tableChangeHandler(data) }}
				loading={this.state.isDetoxLoading}
			/>
		)
	}
}

DetoxDispenseHistory.defaultProps = {
	clientId: '',
	detoxSuccess: "",
	detoxFail: "",
	detoxStatus: "",
	detoxType: "",
	detoxActiveTab: ""
};

DetoxDispenseHistory.propTypes = {
	clientId: PropTypes.any,
	detoxSuccess: PropTypes.any,
	detoxFail: PropTypes.any,
	detoxStatus: PropTypes.any,
	detoxType: PropTypes.any,
	detoxActiveTab: PropTypes.any
};

function mapStateToProps(state) {
	return {
		detoxSuccess: state.detoxPlan.success,
		detoxFail: state.detoxPlan.fail,
		detoxStatus: state.detoxPlan.status,
		detoxType: state.detoxPlan.type,
		// detoxId: state.detoxPlan.detoxId,
		clientId: state.appLayout.clientId ? state.appLayout.clientId : "",
		detoxActiveTab: state.detoxPlan.detoxActiveTab
	};
}

const mapDispatchToProps = {
	detoxDispenseHistoryRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(DetoxDispenseHistory);

// Simple React Native specific changes

// Live Server Url
// const baseURL = "https://https://findherberg.dk/api/";
// local  environment
import * as webConst from '../utils/WebConstant';

const WEB_SERVICE_URL = webConst.WEB_SERVICE_URL;

const token = webConst.getUserToken();


export const withoutHeader = {
    baseURL: WEB_SERVICE_URL,
    headers:{
        common: {
            Accept: 'application/json'
        }
    },
    timeOut: 30000
};

export const withHeader = {
    baseURL: WEB_SERVICE_URL,
    headers:{
        common: {
            Accept: 'application/json',
            Authorization: token
        }
    },
    timeOut: 30000
};